/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
// import { name, random } from 'faker';
import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { TextField, CircularProgress, ListItemText, ListItem, ListItemAvatar, Avatar, ListItemSecondaryAction, IconButton, Tooltip } from '@material-ui/core';
// import { IAutoCompleteItem } from '../../typings/autoComplete';
import { RenderInputParams } from '@material-ui/lab';
import { useDebouncedCallback } from 'use-debounce/lib';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { InfiniteAutocomplete } from '../../components/InfiniteAutocomplete';
import { client } from '../..';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { generateColor } from '../matter/ColorGenerator';
import { getMatterTitlePrefix } from '../page/TimeslipMatterPage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        // minWidth: '200px',
    },
    textField: {
        // width: '100%',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
    renderWrapper: {
        padding: '15px 0'
    },
    customRenderWrapper: {
        display: 'flex',
        margin: '0 -10px',
        width: '100%',
        '& > div': {
            padding: '0 10px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
        },
        '& .icon-wrapper': {
            display: 'flex',
            alignItems: 'center',
        },
        '& .other': {
            flex: 1,
            display: 'flex',
            flexFlow: 'column',
            '& .primary': {
                fontSize: '1rem',
                lineHeight: 1.5,
                letterSpacing: '0.00938em',
            },
            '& .secondary': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.875rem',
                lineHeight: 1.43,
                letterSpacing: '0.01071em',
            }
        }
    },
    customList: {
    display: 'flex',
    flexFlow: 'column',
    fontSize: '16px',
    '& .id': {
        color: '#cc1212',
        marginBottom: '5px',
    },
    '& .name': {
        marginBottom: '5px',
    },
    '& .category': {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '12px',
    }
    },
    starIcon: {
        color: '#cc1212',
    },
    listItem: {
        width: '100%',
    },
    listBox: {
        '& .MuiListItem-container': {
            width: '100%'
        }
    }
  })
);

export interface MatterLookUpItem extends IAutoCompleteItem, Matter {

}

interface MatterLookUpAutoCompleteSelectorProps {
    multiple?: boolean;
    label?: string;
    name: string;
    // tslint:disable-next-line: no-any
    value?: MatterLookUpItem | any; // value only available in single select
    onSelection?: (value: MatterLookUpItem | MatterLookUpItem[], name: string) => void;
    required?: boolean;
    id?: string;
    sourceSystem: string;
    disabled?: boolean;
    disablePortal?: boolean;
    onBlur?: React.FocusEventHandler<HTMLDivElement>;
    helperText?: string;
    error?: boolean;
    descriptionLimit?: number;
    tabIndex?: number;
    // tslint:disable-next-line: no-any
    ref?: any;
    autoFocus?: boolean;
    statusFilter: string;
    isExcludeCompleted?: boolean;
}

interface MatterLookUpAutoCompleteSelectorState {
    hasNextPage: boolean;
    isNextPageLoading: boolean;
    offset: number;
    // tslint:disable-next-line: no-any
    items: MatterLookUpItem[];
    filter: string;
    open: boolean;
}

// tslint:disable-next-line:no-any
let observable: any = null;

export const MatterLookUpAutoCompleteSelector: React.FC<MatterLookUpAutoCompleteSelectorProps> = props => {

    const classes = useStyles();
    const loadBlockLimit = 20;

    const [delayedonInputChange] = useDebouncedCallback(
        // function
        (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {

            let isClear = false;
            let offset = 0;
            let filter = '';

            if (reason === 'input') {
                filter = value;
                isClear = true;

                fetchData(filter, offset, isClear);
            }
        },
        // delay in ms
        500
    );
    
    const [state, setState] = useState<MatterLookUpAutoCompleteSelectorState>({
        hasNextPage: true,
        isNextPageLoading: false,
        offset: 0,
        items: [],
        filter: '',
        open: false
    });

    const fetchData = (filter: string, offset: number, isClear: boolean) => {

        if (observable) {
            // tslint:disable-next-line: no-console
            // console.log('observable unsubscribe', observable);
            observable.unsubscribe();
        }
        
        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: true,
            };
        });

        const matterLookupParams: MatterLookupQueryParams = {
            filter: filter,
            first: loadBlockLimit,
            offset: offset,
            statusFilter: props.statusFilter,
            typeFilter: undefined,
            isLimitedMatterSearch: true,
            isExcludeCompleted: props.isExcludeCompleted
        };

        observable =
            retrieveMatterLookupData(
                matterLookupParams,
                true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
                // tslint:disable-next-line: no-console
                (data) => onDataRetrieved(data, filter, offset, isClear),
                // tslint:disable-next-line:no-any
                function (reason: any): void {
                    showNotification(null, reason, 'error');

                    setState((prevState) => {
                        return {
                            ...prevState,
                            isNextPageLoading: false,
                        };
                    });
                }
            );
    };

    const onDataRetrieved = (data: MatterLookupData, filter: string, offset: number, isClear: boolean) => {

        const matterData = data.matterLookupList.matters;
        const rowCountWithoutFilters = data.matterLookupList.recordCount; 

        // tslint:disable-next-line: no-any
        let itemsList: MatterLookUpItem[] = [];
        let items = state.items;
        
        if (!isClear) {
            itemsList = [...state.items];
        } else {
            items = [];
        }

        if (items && items.length === 0) {
            itemsList = matterData.map( (source: Matter) => (
                {
                    ...source,
                    label: source.fileNumber + ' (' + source.title + ')',
                    value: source.fileNumber
                }));
        } else {
            for (const matter of matterData) {
                // tslint:disable-next-line: no-any
                const isExists = items.filter((item: any) => item.value === matter.fileNumber).length;

                // const isExists = items.filter((item: IndividualNameSummary) => 
                //     item.guidID.toString().toLowerCase().includes(nameSummary.guidID.toLowerCase())).length;

                if (isExists === 0) {
                    itemsList.push({
                        ...matter,
                        label: matter.fileNumber + ' (' + matter.title + ')',
                        value: matter.fileNumber,
                    });
                }
            }
        }

        setState((prevState) => {
            
            return {
                ...prevState,
                filter: filter,
                isNextPageLoading: false,
                items: itemsList,
                offset: offset + loadBlockLimit,
                hasNextPage: matterData.length !== 0 && (offset + loadBlockLimit) < rowCountWithoutFilters,
            };
        });

    };

    // tslint:disable-next-line: no-any
    const loadNextPage = (...args: any) => {
        if (state.hasNextPage && !state.isNextPageLoading) {
            fetchData(state.filter, state.offset, false);
        }
    };

    // const onInputChange = (event: React.ChangeEvent<{}>, value: string, reason: 'input' | 'reset' | 'clear') => {
    //     let isNextPageLoading = false;
    //     let hasNextPage = false;
    //     let isClear = false;
    //     let offset = 0;
    //     let filter = '';

    //     if (reason === 'input') {
    //         filter = value;
    //         isClear = true;
    //         isNextPageLoading = true;
    //         hasNextPage = true;

    //         fetchData(filter, offset, isNextPageLoading, hasNextPage, isClear);
    //     }        
    // };

    const getInputProps = (params: RenderInputParams, loading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    const onClose = (event: React.ChangeEvent<{}>) => {

        setState((prevState) => {
            return {
                ...prevState,
                isNextPageLoading: false,
                hasNextPage: true,
                filter: '',
                offset: 0,
                items: [],
                open: false
            };
        });
    };

    const onOpen = (event: React.ChangeEvent<{}>) => {
        setState((prevState) => {
            return {
                ...prevState,
                open: true
            };
        });
        fetchData(state.filter, 0, true);
    };

    // tslint:disable-next-line: no-any
    const onSelection = (selection: any, name: string) => {
        if (props.onSelection) {
            setState((prevState) => {
                return {
                    ...prevState,
                    open: false
                };
            });
            props.onSelection(selection, name);
        }
    };

    const createAbbreviation = (value: string | null) => {
                
        if (value) {
            let values = value.split(' ');

            if (values.length === 1) {
                return value.substr(0, 2);
            } else {
                
                let abbr = '';

                for (let index = 0; index < 2; index++) {
                    abbr += values[index].substr(0, 1);
                }

                return abbr;
            }

        }
        return;
    };

    const listComponent = (matter: Matter, matterTitlePrefix: string) => {
        
        return (
            <div className={classes.customList}>
                <span className="id">{matter.fileNumber}</span>
                <span className="name">{matter.client}</span>
                <span className="category">{shortingTitle(`${matterTitlePrefix} | ${matter.title}`)}</span>
            </div>
        );
    };

    const LightTooltip = withStyles((theme: Theme) => ({
        tooltip: {
          backgroundColor: theme.palette.common.white,
          color: 'rgba(0, 0, 0, 0.87)',
          boxShadow: theme.shadows[1],
          fontSize: 11,
        },
        arrow: {
            '&:before': {
              border: '1px solid #E6E8ED'
            },
            color: theme.palette.common.white
          },
      }))(Tooltip);

    const shortingTitle = (title: string) => {

        const limit = props.descriptionLimit ? props.descriptionLimit : 50;

        if (title === undefined || title === null) {
            return title;
        }

        if (title && title.length < limit) {
            return title;
        }

        return (                
            <LightTooltip title={title} aria-label="client" arrow={true} placement="right">
                <span>{`${title.substring(0, limit)}...`}</span>
            </LightTooltip>           
        );
    };

    return (
        <div className={`${classes.root}`}>
            <InfiniteAutocomplete
                key={props.id}
                itemSize={130}
                loading={state.isNextPageLoading}
                name={props.name}
                disabled={props.disabled}
                disablePortal={props.disablePortal}
                disableCloseOnSelect={false}                
                isMultiple={props.multiple}
                value={props.value}
                hasNextPage={state.hasNextPage}
                isNextPageLoading={state.isNextPageLoading}
                items={state.items}
                // inputValue={props.value}
                options={state.items}
                loadNextPage={loadNextPage}                
                className={classes.textField}
                disableListWrap={true}
                disableOpenOnFocus={true} // this will prevent the list from opening on focus when clicking the dialog close button
                onSelection={onSelection}
                onClose={onClose}
                onOpen={onOpen}
                getOptionLabel={option => typeof option === 'string' ? option : option.label}
                onInputChange={delayedonInputChange}
                onBlur={props.onBlur}
                disabledFilterOptions={true}
                height={500}
                classes={{
                    listbox: classes.listBox
                }}
                tabIndex={props.tabIndex}
                ref={props.ref}
                open={state.open}
                // reverseRender={true}
                // tslint:disable-next-line: no-any
                renderInput={(params: any) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={props.label}
                        fullWidth={true}
                        margin="none" 
                        required={props.required}
                        InputProps={getInputProps(params, state.isNextPageLoading)}
                        error={props.error}      
                        helperText={props.helperText}
                        autoFocus={props.autoFocus}  
                        onClick={onOpen}
                    />
                )}
                renderOption={(option, { selected }) => {

                    const matterTitlePrefix = getMatterTitlePrefix(props.sourceSystem, option);
                    
                    return (
                        <>
                            <ListItem
                                // onClick={this.onSelectItem(matter)}
                                className={classes.listItem}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        style={{
                                            backgroundColor: generateColor(matterTitlePrefix),
                                        }}
                                    >
                                        {createAbbreviation(matterTitlePrefix)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={listComponent(option, matterTitlePrefix)}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        aria-label="isVIP"
                                        // onClick={toggleVIP(matter, idx)}
                                    >
                                        {
                                            option.isVIP ?
                                                <StarIcon
                                                    className={classes.starIcon}
                                                /> :
                                                <StarBorderIcon
                                                    className={classes.starIcon}
                                                />
                                        }
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            {/* <Divider component="li" /> */}
                        </>
                    );
                }}
            />
        </div>
    );
};

export function retrieveMatterLookupData(
    // tslint:disable-next-line:no-any
    // abortController: any,
    query: MatterLookupQueryParams,
    refreshData: boolean,
    onSuccess: (data: MatterLookupData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void
): ZenObservable.Subscription {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    var querySubscription =  
        client
            .watchQuery({
                query: MATTER_LOOKUP_QUERY,
                variables: {
                    filter: query.filter,
                    first: query.first,
                    offset: query.offset,
                    typeFilter: query.typeFilter,
                    statusFilter: query.statusFilter,
                    isLimitedMatterSearch: query.isLimitedMatterSearch,
                    isExcludeCompleted: query.isExcludeCompleted
                },
                fetchPolicy: fetchPolicy
            })
            .subscribe({
                next: ({data}) => {
                    onSuccess(data);
                },
                // tslint:disable-next-line:no-any
                error: ((reason: any) => {
                    onError(reason);
                })
            });

    return querySubscription;
}

export interface MatterLookupQueryParams {
    offset?: number; 
    first?: number;
    filter?: string;
    typeFilter?: string;
    statusFilter?: string;
    isLimitedMatterSearch?: Boolean;
    isExcludeCompleted?: Boolean;
}

export interface MatterLookupData {
    matterLookupList: MatterLookupList;
}

export interface MatterLookupList {
    recordCount: number;
    matters:     Matter[];
}

export interface Matter {
    id:                 number;
    fileNumber:         string;
    matterCategory:     string;
    matterType:         string;
    matterTypeCategory: string;
    stage:              string | null;
    title:              string;
    description:        string;
    client:             string;
    status:             string;
    inProgress:         boolean;
    syncSystemID:       string | null;
    state:              string;
    family:             string | null;
    property:           string |null;
    propertyLabel:      string |null;
    lastUpdate:         Date | null;
    isVIP:              boolean;
}

const MATTER_LOOKUP_QUERY = gql`
query matterLookup (
    $offset: Int, 
    $first: Int, 
    $filter: String, 
    $typeFilter: FilterOptionsType, 
    $statusFilter: FilterOptionsStatus,
    $isLimitedMatterSearch: Boolean,
    $isExcludeCompleted: Boolean,
){
    matterLookupList(
        typeFilter: $typeFilter, 
        statusFilter: $statusFilter, 
        first: $first, 
        offset: $offset, 
        filter: $filter,
        isLimitedMatterSearch: $isLimitedMatterSearch,
      isExcludeCompleted: $isExcludeCompleted
    ) {
        recordCount
        matters {
            id
            fileNumber
            matterCategory
            matterType
            matterTypeCategory
            stage
            title
            description
            client
            status
            inProgress
            syncSystemID
            state
            family
            property
            propertyLabel
            lastUpdate
            isVIP
        }
    }
}
`;