import { FetchPolicy } from 'apollo-client';
import { client } from '../..';
import gql from 'graphql-tag';

export interface MatterLookUpData {
    loading?: boolean;
    networkStatus?: number;
    matterLookupList: MatterLookupList;
}

interface MatterLookupList {
    recordCount: number;
    matters: Matters[];
}

export interface Matters {
    id: number | null;
    fileNumber: string | null;
    matterCategory: string | null;
    matterType: string | null;
    matterTypeCategory: string | null;
    stage: string | null;
    title: string | null;
    description: string | null;
    client: string | null;
    status: string | null;
    inProgress: boolean | null;
    syncSystemID: string | null;
    state: string | null;
    family: string | null;
    property: string | null;
    propertyLabel: string | null;
    lastUpdate: string | null;
    isVIP: boolean | null;
}

export interface MatterLookUpQueryParams {
    offset: number;
    first: number;
    filter?: string | null;
    typeFilter: 'ALL' | 'RECENT' | 'FAVOURITE' | 'All' | 'Recent' | 'Favourite' | null;
    statusFilter?: 'ALL' | 'CURRENT' | 'COMPLETE' | 'All' | 'Current' | 'Complete' | null;
    isExcludeCompleted: boolean ;
    feeEarnerId?: string;
}

export function retrieveMatterLookUpData(query: MatterLookUpQueryParams,
                                         refreshData: boolean,
                                         // tslint:disable-next-line: no-any
                                         onSuccess: (data: MatterLookUpData) => void,
                                        // tslint:disable-next-line:no-any
                                         onError: (reason: any) => void)
                                        : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: MATTER_LOOKUP,
        variables: {
            offset: query.offset,
            first: query.first,
            filter: query.filter,
            typeFilter: query.typeFilter,
            statusFilter: query.statusFilter,
            isExcludeCompleted : query.isExcludeCompleted,
            feeEarnerId : query.feeEarnerId
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any) => { onError(reason); }
    );
}

const MATTER_LOOKUP = gql`query matterLookup($offset: Int, $first: Int, $filter: String, $typeFilter: FilterOptionsType, $statusFilter: FilterOptionsStatus, $isExcludeCompleted: Boolean, $feeEarnerId: String) {
    matterLookupList(typeFilter: $typeFilter, statusFilter: $statusFilter, first: $first, offset: $offset, filter: $filter, isExcludeCompleted: $isExcludeCompleted, feeEarnerId: $feeEarnerId) {
      recordCount
      matters {
        id
        fileNumber
        matterCategory
        matterType
        matterTypeCategory
        stage
        title
        description
        client
        status
        inProgress
        syncSystemID
        state
        family
        property
        propertyLabel
        lastUpdate
        isVIP
      }
    }
  }
  `;