import * as React from 'react';
import { 
    withStyles, 
    WithStyles, 
    createStyles, 
    Theme,
} from '@material-ui/core';
// import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import SaveIcon from '@material-ui/icons/Save';

export const styles = (theme: Theme) => createStyles({
    appBar: {
        position: 'relative',
        '& > div': {
            padding: 0,
        }
    },
    menuButton: {
        marginRight: '16px',
      },
    title: {
        flexGrow: 1,
        fontSize: '21px',
        fontWeight: 100,
        color: '#333333',
        lineHeight: '32px',
        margin: 0,
        fontFamily: 'Segoe UI',
        textAlign: 'center',
    },
});

interface TimeslipHeaderProps extends WithStyles<typeof styles> {
    cancelOrBackOnchange?: () => void;
    actionType?: 'back' | 'cancel' | undefined;
    onSave?: () => void;
    forTimer: boolean;
}

interface TimeslipHeaderStates {

}

class TimeslipHeader extends React.Component<TimeslipHeaderProps, TimeslipHeaderStates> {
    constructor(props: TimeslipHeaderProps) {
        super(props);
        this.getIcon = this.getIcon.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    render() {
        const { classes, actionType } = this.props;
        return (
            <div className={classes.appBar}>
                <Toolbar>
                    {
                        actionType === undefined || actionType === null ? undefined :
                        this.props.forTimer ? undefined : 
                        (
                            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu">
                                {this.getIcon(this.props.actionType)}
                            </IconButton>
                        )
                    }
                    
                    <Typography className={classes.title}>
                        {this.props.forTimer ? 'Timer' : 'Timeslip'}
                    </Typography>
                    {/* <IconButton color="inherit" onClick={this.handleSave} aria-label="Save">
                        <SaveIcon />
                    </IconButton> */}
                </Toolbar>
            </div>
        ); 
    }

    private getIcon(icon?: string) {
        switch (icon) {
            case 'back': {
                return <ChevronLeftIcon />;
            }
            case 'cancel': {
                return <CancelIcon />;
            }
            default: {
                return undefined;
            }
        }
    }

    private handleSave() {
        if (this.props.onSave) {
            this.props.onSave();
        }
    }
}

// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(TimeslipHeader as any) as any;