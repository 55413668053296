import * as React from 'react';
import {
    withStyles,
    WithStyles,
    createStyles,
    Theme,
    List,
    CircularProgress,
    ListItem,
    ListItemText,
    Divider,
    ListItemSecondaryAction,
    Button,
    TextField
} from '@material-ui/core';
// import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import RecentList from './RecentList';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
import gql from 'graphql-tag';
import { client } from '../..';
// import { RvInput } from '../../components/Input';

export const styles = (theme: Theme) => createStyles({
    appBar: {
        position: 'relative',
        '& > div': {
            padding: 0,
        }
    },
    title: {
        flex: 1,
        textAlign: 'center',
        flexGrow: 1,
        fontSize: '21px',
        fontWeight: 100,
        color: '#333333',
        lineHeight: '32px',
        margin: 0,
        fontFamily: 'Segoe UI'
    },
    dialog: {
        '& > div': {
            boxShadow: 'none',
        }
    },
    content: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column wrap'
    },
    tabContainer: {
        margin: '15px 30px',
    },
    tab: {
        flexGrow: 1,
        maxWidth: 'none',
        flexBasis: 0,
        flexShrink: 1,
    },
    spaceCreator: {
        width: '48px',
        height: '48px',
    },
    progress: {
        margin: '16px',
        textAlign: 'center'
    },
    searchContainer: {
        padding: '15px 0',
        // backgroundColor: '#f5f5f5'
    },
    listContent: {
        flex: 1,
        overflowY: 'auto',
    },
    textField: {
        width: '100%',
    },
    chargeRateInputWrapper: {
        display: 'flex',
        padding: '20px 0',
        margin: '0 -10px',
        '& .field-wrapper': {
            flex: 1,
        },
        '& > div': {
            padding: '0 10px'
        }
    }
});

interface DialogProps extends WithStyles<typeof styles> {
    onClose: () => void;
    // tslint:disable-next-line: no-any
    onData: (data: any) => void;
    sourceSystem: string;
}

interface DialogStates {
    // tslint:disable-next-line:no-any
    chargeRateLookupList: any[];
    listHeight?: number;
    rate: number;
}

class TimeslipChargeCodePage extends React.Component<DialogProps, DialogStates> {
    constructor(props: DialogProps) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.onSelectItem = this.onSelectItem.bind(this);
        this.getPrivate = this.getPrivate.bind(this);
        this.fetchChargeCodes = this.fetchChargeCodes.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handlePress = this.handlePress.bind(this);
        this.state = {
            chargeRateLookupList: [],
            rate: 0
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: DialogProps, nextContext: DialogStates): void {
        // code here...
    }

    componentDidMount() {
        const listElement = jQuery<HTMLElement>('.tabContent');

        // tslint:disable-next-line: no-console
        console.log('height', listElement.height());

        let height = listElement.height() !== undefined ? listElement.height() : 200;

        this.setState({
            listHeight: height
        });
        var isPE = this.props.sourceSystem === 'PracticeEvolved';
        this.fetchChargeCodes(isPE);
    }

    render() {
        const { classes } = this.props;
        var chargeCodeTitle = this.props.sourceSystem === 'Coyote' ? 'Task Code' : 'Charge Code';

        var chargeRateInput = <div/>;
        if (this.props.sourceSystem === 'PracticeEvolved') {
            chargeRateInput =
            (
                <div className={classes.chargeRateInputWrapper}>
                    <div className="field-wrapper">
                        <TextField
                            type="number"
                            label="Custom Rate"
                            className={classes.textField}
                            value={this.state.rate}
                            onChange={this.handleTextChange}
                        />
                    </div>
                    <div>
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            // className={classes.button} 
                            onClick={this.handlePress} 
                        >
                            Save
                        </Button>
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className={classes.appBar} color="default">
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography className={classes.title}>
                            {chargeCodeTitle}
                    </Typography>
                    </Toolbar>
                </div>
                <div className={classes.content}>
                    {chargeRateInput}
                    <div className={classes.listContent}>
                        {this.timesheetActivityData()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
    timesheetActivityData() {
        const { classes } = this.props;
        const chargeCodes = this.state.chargeRateLookupList;
        const item = this.getPrivate(chargeCodes, classes);

        return (
            <React.Fragment>
                {item}           
            </React.Fragment>
        );
    }

    private handleClose() {
        if (this.props.onClose) {
            return this.props.onClose();
        }
    }

    private handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {

        var rate = parseInt(event.target.value, 10);
        this.setState({rate: rate});
    }

    private handlePress() {
        if (this.props.onData) {
            this.props.onData({ rate: this.state.rate, description: 'Custom Rate'});
        }
    }

    // tslint:disable-next-line: no-any
    private onSelectItem = (chargeCode: any) => () => {
        if (this.props.onData) {
            this.props.onData(chargeCode);
        }
    }

    private fetchChargeCodes(isPE: boolean) {

        if (isPE) {
            client.query({
                query: PeChargeCodes,
                fetchPolicy: 'network-only'  
            }).then((results: {
                data: {
                    pEChargeRates: {
                        // tslint:disable-next-line:no-any
                        pEChargeRate: any
                    }
                }
            }) => {
                if (results.data.pEChargeRates) {
                    this.setState({chargeRateLookupList: results.data.pEChargeRates.pEChargeRate});
                }
    
            // tslint:disable-next-line:no-any
            }).catch((reason: any) => {
                // tslint:disable-next-line:no-console
                console.log(reason);
            });
        } else {
            client.query({
                query: ChargeCodes,
                fetchPolicy: 'network-only'  
            }).then((results: {
                data: {
                    chargeCodes: {
                        // tslint:disable-next-line:no-any
                        chargeCode: any
                    }
                }
            }) => {
                if (results.data.chargeCodes) {
                    this.setState({chargeRateLookupList: results.data.chargeCodes.chargeCode});
                }
    
            // tslint:disable-next-line:no-any
            }).catch((reason: any) => {
                // tslint:disable-next-line:no-console
                console.log(reason);
            });
        }

    }

    // tslint:disable-next-line:no-any
    private getPrivate(activities: any, classes: any) {

        if (this.props.sourceSystem === 'PracticeEvolved') {
            var tempActivities = new Array;
            // tslint:disable-next-line:forin
                
            // tslint:disable-next-line:no-any
            activities.map((activity: any, idx: number) =>
             (tempActivities.push ({ id: activity.id, description: activity.stringValue, rate: activity.moneyValue})));
            
            activities = tempActivities;
        }
        if (activities) {
            return (
                <List className="list" style={{ height: this.state.listHeight }}>
                    {
                        // tslint:disable-next-line: no-any
                        activities.map( (activity: any, idx: number) => 
                            (
                                <React.Fragment key={activity.id}>
                                    <ListItem
                                        onClick={this.onSelectItem(activity)}
                                    >
                                        <ListItemText
                                            primary={activity.description}
                                        />
                                        <ListItemSecondaryAction>
                                            <ListItemText
                                                onClick={this.onSelectItem(activity)}
                                                primary={activity.rate}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider component="li" />
                                </React.Fragment>
                            )
                        )
                    }
                </List>
            );
        } else {
            return (
                <div className={classes.progress}>
                    <CircularProgress />
                </div>
            );
        }
    }
}
const ChargeCodes = gql`query ChargeCodes {
    chargeCodes {
      chargeCode {
        description
        rate
        id
      }
    }
  }`;

const PeChargeCodes = gql`query PEChargeCodes {
    pEChargeRates {
        pEChargeRate {
            moneyValue
            stringValue
            id
      }
    }
  }`;
// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(TimeslipChargeCodePage as any) as any;