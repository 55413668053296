import { Card, CardContent, CircularProgress, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import Timer from 'react-compound-timer';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import classNames from 'classnames';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DialogBox from '../Dashboard/Component/DialogBox';

interface TimerCardProps {
    id?: string;
    title: string | null;
    description?: string;
    initialTime?: number;
    isRunning: boolean;
    isTimeDisabled?: boolean;
    isLoading?: boolean;
    inError?: boolean;
    errorMessage?: string;
    fileNumber?: string | null;
    client?: string | null;
    matterCategory?: string | null;
    matterType?: string | null;
    onClick?: () => void;
    // tslint:disable-next-line: no-any
    // getTime?: (time: number, ) => void;
    onDrilldown?: (time: number) => void;
    // tslint:disable-next-line: no-any
    onDelete?: () => void;
    /** Function that will be called on timer start */
    onStart?: (time: number) => void;
    /** Function that will be called on timer resume */
    onResume?: (time: number) => void;
    /** Function that will be called on timer pause */
    onPause?: (time: number) => void;
    /** Function that will be called on timer stop */
    onStop?: (time: number) => void;
    /** Function that will be called on timer reaching 24 hours */
    onInvalidate?: (time: number) => void;
}

interface TimerCardState {
    isInvalid: boolean;
}

export const TimerCard: React.FC<TimerCardProps> = (props) => {
    const classes = useStyles();
    // tslint:disable-next-line: no-any
    const timerRef = React.useRef<any>();

    React.useEffect(() => {
        if (!props.isRunning) {
            timerRef.current.pause();
        } else {
            timerRef.current.start();
        }
        // tslint:disable-next-line: align
    }, [props.isRunning]);

    const formatTime = (value: number) => {
        if (value < 10) {
            return `0${value}`;
        }
        return `${value}`;
    };

    const onDrilldown = (time: number) => {
        if (props.onDrilldown) {
            return props.onDrilldown(time * 60);
        }
    };

    const [state, setState] = React.useState<TimerCardState>({
        isInvalid: false,
    });

    const [showConfirm, setShowConfirm] = React.useState<boolean>(false);

    const onDelete = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setShowConfirm(true);
    };

    const onDeleteConfirm = (agree: boolean) => {
        if (agree && props.onDelete) {
            props.onDelete();
        }
        setShowConfirm(false);
    };

    const getTimeDisplay = () => {
        if (props.initialTime && props.initialTime > 0) {
            let timeWorkedInSeconds = props.initialTime / (1000 * 60);
            let seconds = Math.ceil((timeWorkedInSeconds ?? 0) % 60);
            let minutes = Math.floor(((timeWorkedInSeconds ?? 0) / 60) % 60);
            let hours = Math.floor((timeWorkedInSeconds ?? 0) / 3600);
            let minutesString = '' + minutes;
            let secondsString = '' + seconds;
    
            if (seconds < 1) {
                secondsString = '00';
            } else if (seconds < 10) {
                secondsString = '0' + seconds;
            }
            
            if (minutes < 1) {
                minutesString = '00';
            } else if (minutes < 10) {
                minutesString = '0' + minutes;
            }
    
            let durationString = hours + ':' + minutesString + ':' + secondsString;
            return durationString;
        } else {
            return '00:00';
        }       
    };

    return (
        <>
            {showConfirm && (
                <DialogBox
                    title={props.title}
                    // tslint:disable-next-line:max-line-length
                    content={'Are you sure you want to delete?'}
                    show={showConfirm}
                    isAgree={onDeleteConfirm}
                    disAgreeLabel={'No'}
                    agreeLabel={'Yes'}
                    disablePortal={true}
                />
            )}
            <div key={props.initialTime}>
            <Timer
                key={props.id}
                ref={timerRef}
                initialTime={((props.initialTime ?? 0) / 60)}
                startImmediately={props.isRunning}
                // tslint:disable-next-line: no-console
                // onStart={props.onStart}
                // tslint:disable-next-line: no-console
                // onStop={props.onStop}
            >
                {/* tslint:disable-next-line: no-any */}
                {({ start, stop, getTime, pause, resume }: any) => {
                    return (
                        <Card className={classes.cardRoot}>
                            <div
                                className={classNames(
                                    classes.cardControl,
                                    props.inError ? classes.cardControlError : props.isRunning ? classes.cardControlPlay : classes.cardControlStop,
                                )}
                                onClick={props.onClick}
                            >
                                {props.inError ? (
                                    <IconButton aria-label="play" disabled={true} className={classes.iconButton}>
                                        <ErrorOutlineIcon className={classes.playCircle} />
                                        {props.isLoading && <CircularProgress size={42} className={classes.fabProgress} />}
                                    </IconButton>
                                ) : props.isRunning ? (
                                    <IconButton
                                        aria-label="play"
                                        onClick={() => {
                                            stop();
                                            if (props.onStop) {
                                                props.onStop(getTime());
                                            }
                                        }}
                                        disabled={props.isLoading}
                                        className={classes.iconButton}
                                    >
                                        <PauseCircleOutlineIcon className={classes.playCircle} />
                                        {props.isLoading && <CircularProgress size={42} className={classes.fabProgress} />}
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        aria-label="play"
                                        onClick={() => {
                                            start();
                                            if (props.onStart) {
                                                props.onStart(getTime());
                                            }
                                        }}
                                        disabled={props.isLoading}
                                        className={classes.iconButton}
                                    >
                                        <PlayCircleOutlineIcon className={classes.playCircle} />
                                        {props.isLoading && <CircularProgress size={42} className={classes.fabProgress} />}
                                    </IconButton>
                                )}
                            </div>
                            <div className={classes.cardDetails} onClick={props.onClick}>
                                <CardContent
                                    className={classes.cardContent}
                                    onClick={() => {
                                        // pause();
                                        onDrilldown(getTime());
                                    }}
                                >
                                    <div className="details">
                                        {/* File Number */}                                        
                                        {props.title && props.fileNumber === undefined && (
                                            <Typography component="h5" variant="h5" className="title">
                                                {props.title}
                                            </Typography>
                                        )}
                                        {props.fileNumber && (
                                            <Typography variant="subtitle1" className="id">
                                                {props.fileNumber}
                                            </Typography>
                                        )}
                                        {props.client && (
                                            <Typography component="h5" variant="h5" className="title">
                                                {props.client}
                                            </Typography>
                                        )}
                                         {props.matterCategory && (
                                            <Typography variant="subtitle1" color="textSecondary" className="description">
                                                {props.matterCategory} {props.title ? ' | ' + props.title : ''}
                                            </Typography>
                                        )}
                                        {props.description && (
                                            <Typography variant="subtitle1" color="textSecondary" className="description">
                                                {props.description}
                                            </Typography>
                                        )}
                                        {props.errorMessage && (
                                            <Typography variant="subtitle1" color="textSecondary" className="error">
                                                {props.errorMessage}
                                            </Typography>
                                        )}
                                    </div>
                                    {!props.isTimeDisabled && !props.inError && (
                                        <div className={props.isRunning ? 'timeRunning' : 'time'}>
                                            <Typography variant="subtitle1">
                                                <Timer.Days
                                                    formatValue={(value) => {
                                                        if (value > 0 && !state.isInvalid) {
                                                            stop();
                                                            if (props.onInvalidate) {
                                                                props.onInvalidate(getTime());
                                                            }
                                                            setState({ isInvalid: true });
                                                        }
                                                        return '';
                                                    }}
                                                />
                                                {/* The value will be 0 if the timer is not running. */}
                                                {props.isRunning 
                                                    ? (
                                                        <>
                                                            <Timer.Hours formatValue={(value) => formatTime(value)} />
                                                            :
                                                            <Timer.Minutes formatValue={(value) => formatTime(value)} />
                                                        </>
                                                    ) 
                                                    : (
                                                        <>
                                                            {getTimeDisplay()}
                                                        </>
                                                    )
                                                }                                               
                                                {props.isRunning && (
                                                    <>
                                                        :
                                                        <Timer.Seconds formatValue={(value) => formatTime(value)} />
                                                    </>
                                                )}
                                            </Typography>
                                        </div>
                                    )}
                                    {props.inError && (
                                        <div className={'timeError'}>
                                            <Typography variant="subtitle1">24:00</Typography>
                                        </div>
                                    )}
                                    {props.onDelete && (
                                        <IconButton aria-label="delete" size="small" className={classes.deleteButton} onClick={onDelete}>
                                            <DeleteOutlinedIcon fontSize="small" color="error" />
                                        </IconButton>
                                    )}
                                </CardContent>
                            </div>
                        </Card>
                    );
                }}
            </Timer>
            </div>
        </>
    );
};
