import React from 'react';
import { Grid } from 'semantic-ui-react';
import glamorous from 'glamorous';
import ReactDOM from 'react-dom';

// tslint:disable-next-line:no-any
const DataCardSection = glamorous.div<{ theme?: any }>((props) => ({
    // height: window.innerHeight - 440,   // this should be calculated dynamically
    // width: window.innerWidth - 163,
    width: '100%',
    textAlign: 'left',
    border: '#077bba',
    overflowY: 'auto',
    // position: 'absolute',
    // top: '255px',
    // margin: '29px 30px 6px 32px',
    [`& .${`table`}`]: {
        border: 'none !important',
    },
    [`& .${`grid-custom-column`}`]: {
        [`& .${`item-In-Progress`}`]: {
            position: 'absolute',
            width: '100%',
            left: 0,
            bottom: '10px',
            [`& .${`stringDisplay`}`]: {
                [`& .${`LabelData`}`]: {
                    width: '100% !important',
                    textAlign: 'center'
                },
                [`& .${`ValueData`}`]: {
                    display: 'none !important'
                }
            }
        }
    }
}));

const GridView = glamorous(Grid)({

});

const GridContainer = glamorous.div({
    padding: '0 1rem'
});

// tslint:disable-next-line:no-any
// const Divider = glamorous.div<{ theme?: any }>((props) => ({
//     content: ' ',
//     width: 'inherit',
//     height: '3px',
//     borderTop: '1px solid ' + props.theme.NeutralColors.LightBlue1,
//     marginBottom: '30px',
//     zIndex: 1,
//     background: props.theme.NeutralColors.BackgroundWhite
// }));

export interface InfiniteScrollProps {
    // tslint:disable-next-line:no-any    
    mapFunc: (child: any, key: number) => any;
    // tslint:disable-next-line:no-any    
    childData: any;
    blockSize: number;
    // tslint:disable-next-line: no-any
    loadData: () => any;
    height: number;
}

export interface InfiniteScrollState {
    childrenVisible: number;
    windowHeight: number;
}

export class InfiniteScrollWrapper extends React.Component<InfiniteScrollProps, InfiniteScrollState> {
    private reachedBottom: boolean;

    // tslint:disable-next-line:no-any
    constructor(props: InfiniteScrollProps, state: InfiniteScrollState) {
        super(props, state);
        this.loadData = this.loadData.bind(this);
        this.loadNextBlock = this.loadNextBlock.bind(this);
        this.listenScrollEvent = this.listenScrollEvent.bind(this);
        this.convertChildrenToElements = this.convertChildrenToElements.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.state = {
            childrenVisible: this.props.blockSize,
            windowHeight: 0
        };
    }

    UNSAFE_componentWillReceiveProps() {
            this.loadNextBlock();
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        ReactDOM.findDOMNode(this)?.addEventListener('scroll', this.listenScrollEvent);
        this.loadNextBlock();
    }

    componentWillUnmount() {
        ReactDOM.findDOMNode(this)?.removeEventListener('scroll', this.listenScrollEvent.bind(this));
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {

        let width = window.innerWidth - 184;
        let height = window.innerHeight - 500;

        let ratio = width / height;

        let updatedHeight = Math.round(width / ratio);

        this.setState({ windowHeight: updatedHeight });
        this.forceUpdate();
    }

    // tslint:disable-next-line:no-any    
    listenScrollEvent(event: any) {
        if (this.reachedBottom !== this.isBottom()) {
            this.reachedBottom = this.isBottom();
            if (this.reachedBottom) {
                this.loadData();
                // this.loadNextBlock();
            }
        }
    }

    isBottom() {
        var table = document.getElementById('table');
        if (table != null) {
            return table.getBoundingClientRect().bottom - 300 <= window.innerHeight;
        }
        return false;
    }

    render() {
        return (
            <DataCardSection style={{ height: this.props.height }} className="datacard-section">
                {/* <Divider className="divider" /> */}
                <GridContainer id="table">
                    <GridView className="gridview">
                        <Grid.Row columns={4}>
                            {this.convertChildrenToElements()}
                        </Grid.Row>
                    </GridView>
                </GridContainer>
            </DataCardSection>
        );
    }

    private convertChildrenToElements() {
        if (!this.props.childData) {
            return null;
        }
        var elements = new Array<Element>();
        var count = 0;
        for (let child of this.props.childData) {
            if (count >= this.state.childrenVisible) {
                break;
            }
            var result = this.props.mapFunc(child, count);
            if (result) {
                elements.push(result);
                count++;
            }
        }
        return elements;
    }

    private loadData() {
        if (this.props.loadData) {
            this.props.loadData();
        }
    }

    private loadNextBlock() {
        this.setState({ childrenVisible: this.state.childrenVisible + this.props.blockSize });
    }
}