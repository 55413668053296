/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-community/all-modules/dist/styles/ag-theme-material.css';
import { TimerContext } from '../contexts/TimerContext';
import { Panel, PanelType } from 'office-ui-fabric-react';
import { useStyles } from './styles';
import { Typography } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import { useMutation, useQuery, useSubscription } from 'react-apollo';
import { showNotification } from '../App';
import { RvLoader } from '../components/Loader';
import TimeslipPage from '../timeslip/TimeslipPage';
import { TimerCard }  from './TimerCard';
import { v4 } from 'uuid';
import {
    CreateTimerMutationParams,
    CreateTimerMutation,
    DeleteTimerMutationParams,
    DeleteTimerMutation,
    PauseTimerMutationParams,
    PauseTimerMutation,
    StartTimerMutationParams,
    StartTimerMutation,
    SubmitTimerMutationParams,
    SubmitTimerMutation,
    TimerNotificationSubscriptionData,
    TimerSummaryData,
    TimerSummaryElement,
    TimerSummaryParams,
    TimerSubscriptionNotification,
    UpdateTimerMutationParams,
    UpdateTimerMutation,
    TimerSummaryQuery,
    // fetchTimerSummary,
} from './TimesheetTimerRepository';
import { useState } from 'react';
import { Systems, useTimeRecording } from '../hooks/useTimeRecording';
// import { useCurrentUser } from '../hooks/useCurrentUser';
// import 'moment-timezone';

interface TimesheetTimerDrawerProps {
    onClose?: () => void;
    open: boolean;
}

interface TimesheetTimerDrawerState {
    // timers: TimerSummaryElement[];
    isTimeslipOpen: boolean;
    isCreateLoading: boolean;
    isDeleting: boolean;
    // tslint:disable-next-line: no-any
    selectedTimer: TimerSummaryElement | null | any;
    isNewTimer: boolean;
    timeRecordSystem: Systems | null;
}

const TimesheetTimerDrawer: React.FC<TimesheetTimerDrawerProps> = (props) => {
    const classes = useStyles();

    // tslint:disable-next-line: no-any
    const { setTimerTotal, setInitialTimerTotal } = React.useContext<any>(TimerContext);

    const timerNotification = useSubscription<TimerNotificationSubscriptionData>(TimerSubscriptionNotification);    

    // tslint:disable-next-line: no-any
    const [createTimerMutation, createTimerMutationObj] = useMutation<any, CreateTimerMutationParams>(CreateTimerMutation);

    // tslint:disable-next-line: no-any
    const [updateTimerMutation, updateTimerMutationObj] = useMutation<any, UpdateTimerMutationParams>(UpdateTimerMutation);

    // tslint:disable-next-line: no-any
    const [deleteTimerMutation, deleteTimerMutationObj] = useMutation<any, DeleteTimerMutationParams>(DeleteTimerMutation);

    // tslint:disable-next-line: no-any
    const [startTimerMutation, startTimerMutationObj] = useMutation<any, StartTimerMutationParams>(StartTimerMutation);

    // tslint:disable-next-line: no-any
    const [submitTimerMutation, submitTimerMutationObj] = useMutation<any, SubmitTimerMutationParams>(SubmitTimerMutation);

    // tslint:disable-next-line: no-any
    const [pauseTimerMutation, pauseTimerMutationObj] = useMutation<any, PauseTimerMutationParams>(PauseTimerMutation);

    const timeRecording = useTimeRecording();

    // const [isLoading] = useState<Boolean>(false);
    
    const [state, setState] = useState<TimesheetTimerDrawerState>({
        isTimeslipOpen: false,
        // timers: [],
        isCreateLoading: false,
        selectedTimer: null,
        isNewTimer: false,
        timeRecordSystem: null,
        isDeleting: false
    });

    const [timers, setTimers] = useState<TimerSummaryElement[]>([]);

    const timerSummaryQuery = useQuery<TimerSummaryData, TimerSummaryParams>(TimerSummaryQuery, {
        variables: {
            filter: '',
            first: 1000,
            offset: 0,
        },
        fetchPolicy: 'network-only',
    });

    // get the difference between system timezone and local timezone
    // const getSecondsDifferenceBetweenLocalAndDefaultTimezone = () => {
    //     const sydneyOffsetInSeconds = moment().tz('Australia/Sydney').utcOffset();
    //     const localOffsetInSeconds = moment().utcOffset();

    //     const SecondsDifference = sydneyOffsetInSeconds - localOffsetInSeconds;

    //     return SecondsDifference;
    // };

    React.useEffect(() => {
        if (!timeRecording.loading && timeRecording.data && !timeRecording.error) {
            const { systems } = timeRecording.data.settings;

            setState((prevState) => {
                return {
                    ...prevState,
                    timeRecordSystem: systems,
                };
            });
        }
    // tslint:disable-next-line: align
    }, [timeRecording.data, timeRecording.loading, timeRecording.error]);
    
    React.useMemo(() => {
        if (!timerSummaryQuery.loading && timerSummaryQuery.data && !timerSummaryQuery.error) {

            const { timerSummaries } = timerSummaryQuery.data.timerSummary;

            const tempTimers = _.map(timerSummaries, (item) => {
                if (item.isRunning && item.lastStarted) {
                    const lastStarted = moment(item.lastStarted);

                    const currentDate = moment(); // .add('hours', 2); // temporary set to add 2 hours, need to be fixed

                    // const SecondsDiff = getSecondsDifferenceBetweenLocalAndDefaultTimezone();

                    // if (SecondsDiff > 0) {
                    //     currentDate.add(SecondsDiff, 'Seconds');
                    // } else if (SecondsDiff < 0) {
                    //     currentDate.subtract(Math.abs(SecondsDiff), 'Seconds');
                    // }

                    const timeDuration = moment.duration(currentDate.diff(lastStarted));

                    // Add the calcuated time duration with the existing timeWorkedSeconds. When starting the timer, we don't calculate the timeWorkedSeconds.
                    const totalWorkedSeconds = (item.timeWorkedSeconds ? item.timeWorkedSeconds : 0) + timeDuration.asSeconds();

                    // stateItem.timeWorkedSeconds = totalWorkedSeconds;
                    item.timeWorkedSecondsTemp = totalWorkedSeconds;

                    if (item.timeWorkedSecondsTemp && item.timeWorkedSecondsTemp >= 24 * 60 * 60) {
                        item.timeWorkedSecondsTemp = 24 * 60 * 60;
                        item.inError = true;
                        item.errorMessage = 'Timer must be less than 24 hours';
                        item.isRunning = false;
                    }
                } else {
                    item.timeWorkedSecondsTemp = item.timeWorkedSeconds;
                }
                return item;
            });

            setTimers(tempTimers);

            setInitialTimerTotal(tempTimers.length);

        }
    },            [timerSummaryQuery.loading, timerSummaryQuery.data, timerSummaryQuery.error]);

    React.useEffect(() => {
        if (!timerNotification.error && !timerNotification.loading && timerNotification.data) {
            // tslint:disable-next-line: no-console
            if (timerNotification.data.timerNotification.status) {
                // tslint:disable-next-line: no-console

                const message = timerNotification.data.timerNotification.message.toLowerCase();

                if (message.includes('created successfully')) {

                    setTimerTotal(1, 'add');
                    
                    let tempTimers = timers;
                    tempTimers[tempTimers.length - 1].timerGuid = timerNotification.data.timerNotification.timerGuid;

                    setTimers(tempTimers);
                    setState((prevState) => {
                        return {
                            ...prevState,
                            isCreateLoading: false,
                        };
                    });
                } 

                if (message.includes('deleted successfully') || message.includes('submitted successfully')) {
                    setTimerTotal(1, 'minus');
                    setState((prevState) => {
                        return {
                            ...prevState,
                            isDeleting: false,
                        };
                    });
                } 
                
                if (!message.includes('paused successfully') 
                    && !message.includes('started successfully')) {
                        // tslint:disable-next-line: no-console
                        // Refresh the data again
                        // Don't refresh on start/ pause.
                        timerSummaryQuery.refetch();
                }
            } else {
                showNotification('Failed', timerNotification.data.timerNotification.message, 'error');
            }
        } else if (timerNotification.error) {
            showNotification('Failed', timerNotification.error.message, 'error');
        }
        // tslint:disable-next-line: align
    }, [timerNotification.data, timerNotification.loading, timerNotification.error]);

    React.useEffect(() => {
        if (!createTimerMutationObj.loading && !createTimerMutationObj.error && createTimerMutationObj.data) {
            // showNotification(null, 'Create timer request submitted', 'info');
            // increment total timer in the DrawerContext;
            // setTimerTotal(1, 'add');
        } else if (createTimerMutationObj.error) {
            showNotification('Create timer request failed', createTimerMutationObj.error.message, 'error');
        }
        // tslint:disable-next-line
    }, [createTimerMutationObj.loading, createTimerMutationObj.data, createTimerMutationObj.error]);

    React.useEffect(() => {
        if (!updateTimerMutationObj.loading && !updateTimerMutationObj.error && updateTimerMutationObj.data) {
            // showNotification(null, 'update timer request submitted', 'info');
        } else if (updateTimerMutationObj.error) {
            showNotification('update timer request failed', updateTimerMutationObj.error.message, 'error');
        }
        // tslint:disable-next-line: align
    }, [updateTimerMutationObj.loading, updateTimerMutationObj.data, updateTimerMutationObj.error]);

    React.useEffect(() => {
        if (!deleteTimerMutationObj.loading && !deleteTimerMutationObj.error && deleteTimerMutationObj.data) {
            // showNotification(null, 'Delete timer request submitted', 'info');
            // decrement total timer in the DrawerContext;
            // setTimerTotal(1, 'minus');
        } else if (deleteTimerMutationObj.error) {
            showNotification('Delete timer request failed', deleteTimerMutationObj.error.message, 'error');
        }
        // tslint:disable-next-line: align
    }, [deleteTimerMutationObj.loading, deleteTimerMutationObj.data, deleteTimerMutationObj.error]);

    React.useEffect(() => {
        if (!startTimerMutationObj.loading && !startTimerMutationObj.error && startTimerMutationObj.data) {
            // showNotification(null, 'Start timer request submitted', 'info');
        } else if (startTimerMutationObj.error) {
            showNotification('Start timer request failed', startTimerMutationObj.error.message, 'error');
        }
        // tslint:disable-next-line: align
    }, [startTimerMutationObj.loading, startTimerMutationObj.data, startTimerMutationObj.error]);

    React.useEffect(() => {
        if (!pauseTimerMutationObj.loading && !pauseTimerMutationObj.error && pauseTimerMutationObj.data) {
            // showNotification(null, 'Pause timer request submitted', 'info');
        } else if (pauseTimerMutationObj.error) {
            showNotification('Pause timer request failed', pauseTimerMutationObj.error.message, 'error');
        }
        // tslint:disable-next-line: align
    }, [pauseTimerMutationObj.loading, pauseTimerMutationObj.data, pauseTimerMutationObj.error]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            // Code to be executed after the specified delay
            timerSummaryQuery.refetch();

            if (!submitTimerMutationObj.loading && !submitTimerMutationObj.error && submitTimerMutationObj.data) {
                // showNotification(null, 'submit timer request submitted', 'info');            
            } else if (submitTimerMutationObj.error) {
                showNotification('submit timer request failed', submitTimerMutationObj.error.message, 'error');
            }
        },                       1000); // Delay of 1000 milliseconds (1 seconds)

        return () => {
            clearTimeout(timer);
        };
        // tslint:disable-next-line: align
    }, [submitTimerMutationObj.loading, submitTimerMutationObj.data, submitTimerMutationObj.error]);

    const createNewTimer = () => {
        if (!state.isCreateLoading) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isCreateLoading: true,
                };
            });

            var guid = v4();
            // let tempDefaultTimer = prevState.defaultTimer;

            const tempTimers = _.map(timers, (item) => {
                if (item.isRunning) {

                    const currentDate = new Date();
                    const timeWorkedSecondsRunning = moment.duration(moment(currentDate).diff(item.lastStarted)).asSeconds();
                    item.isRunning = false;
                    var currentSecs = item.timeWorkedSeconds ?? 0;
                    item.timeWorkedSeconds =  timeWorkedSecondsRunning + currentSecs;
                    item.timeWorkedSecondsTemp =  timeWorkedSecondsRunning + currentSecs;
             
                    pauseTimerMutation({
                        variables: {
                            pauseDateTime: currentDate,
                            timerGuid: item.timerGuid,
                        },
                    });
                }

                return item;
            });

            tempTimers.push({
                isRunning: true,
                activityDescription: null,
                activityId: null,
                chargeRate: null,
                chargeRateId: null,
                billingNote: null,
                client: null,
                fileNumber: null,
                matterCategory: null,
                matterId: null,
                matterType: null,
                timesheetNote: null,
                timeWorkedSeconds: 0,
                timeWorkedSecondsTemp: 0,
                title: null,
                timerGuid: guid,
                lastStarted: new Date(),
                inError: false,
                errorMessage: null,
                units: 0
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isCreateLoading: false,
                };
            });

            setTimers(tempTimers);            

            // setState((prevState) => {               
            //     return {
            //         ...prevState,
            //         timers: tempTimers,
            //     };
            // });

            createTimerMutation({
                variables: {
                    createdDate: new Date(),
                    timeWorkedSeconds: 0,
                    matterId: null,
                    activityId: null,
                    chargeRateId: null,
                    chargeRate: null,
                    timesheetNote: null,
                    billingNote: null,
                    timeUnits: null,
                    isRunning: true,
                    timerGuid: guid
                },
            });
        }
    };

    const onPlayTimer = (timerGuid: string | null, currentTime: number) => {
        const tempTimers = _.map(timers, (item) => {
            if (item.isRunning) {
                const currentDate = new Date();
                const timeWorkedSecondsRunning = Math.ceil(moment.duration(moment(currentDate).diff(item.lastStarted)).asSeconds());
                item.isRunning = false;
                var currentSecs = item.timeWorkedSeconds ?? 0;
                item.timeWorkedSeconds =  timeWorkedSecondsRunning + currentSecs;
                item.timeWorkedSecondsTemp =  timeWorkedSecondsRunning + currentSecs;
                pauseTimerMutation({
                    variables: {
                        pauseDateTime: new Date(),
                        timerGuid: item.timerGuid,
                    },
                });
            }

            return item;
        });

        const index = tempTimers.findIndex((time: TimerSummaryElement) => time.timerGuid === timerGuid);

        if (index >= 0) {
            tempTimers[index].isRunning = true;
            tempTimers[index].lastStarted = moment().toDate();
            tempTimers[index].timeWorkedSeconds = currentTime;
            tempTimers[index].timeWorkedSecondsTemp = currentTime;
            // tempTimers[index].timeWorkedSeconds = currentTime / 1000 / 60;
        }

        startTimerMutation({
            variables: {
                startDateTime: new Date(),
                timerGuid: timerGuid ? timerGuid : null,
            },
        });

        // timers = map timers
        // update specific timer
        // 

        setTimers(tempTimers);
    };

    const onStopTimer = (timerGuid: string | null, currentTime: number) => {
        let tempTimers = timers;
        const index = timers.findIndex((time: TimerSummaryElement) => time.timerGuid === timerGuid);

        if (index >= 0) {
            tempTimers[index].isRunning = false;
            tempTimers[index].timeWorkedSeconds = currentTime;
            tempTimers[index].timeWorkedSecondsTemp = currentTime;
        }

        pauseTimerMutation({
            variables: {
                pauseDateTime: new Date(),
                timerGuid: timerGuid,
            },
        });

        setTimers(tempTimers);
    };

    const onInvalidateTimer = (timerGuid: string | null) => {
        let tempTimers = timers;

        const index = tempTimers.findIndex((time: TimerSummaryElement) => time.timerGuid === timerGuid);

        if (index >= 0) {
            var isRunning = tempTimers[index].isRunning;
            tempTimers[index].timeWorkedSeconds = tempTimers[index].timeWorkedSecondsTemp;
            if (isRunning) {
                pauseTimerMutation({
                    variables: {
                        pauseDateTime: new Date(),
                        timerGuid: timerGuid,
                    },
                });
            }

            tempTimers[index].isRunning = false;
            // tempTimers[index].timeWorkedSeconds = currentTime / 1000 / 60;

            tempTimers[index].timeWorkedSeconds = 24 * 60;
            tempTimers[index].inError = true;
            tempTimers[index].errorMessage = 'Timer must be less than 24 hours';
        }

        setTimers(tempTimers);
    };

    const onPlay = (timerGuid: string | null) => (currentTime: number) => {
        onPlayTimer(timerGuid, Math.floor(currentTime / 1000));
    };

    const onStop = (timerGuid: string | null) => (currentTime: number) => {

        onStopTimer(timerGuid, Math.floor(currentTime / 1000));
    };

    const onInvalidate = (timerGuid: string | null) => (currentTime: number) => {
        onInvalidateTimer(timerGuid);
    };

    const onToggleTimer = (selectedTimer?: TimerSummaryElement) => {
        if (selectedTimer) {
            if (selectedTimer.isRunning) {
                onStopTimer(selectedTimer.timerGuid, selectedTimer.timeWorkedSecondsTemp ?? 0);
            } else {
                onPlayTimer(selectedTimer.timerGuid, selectedTimer.timeWorkedSecondsTemp ?? 0);
            }
        }
    };

    const onSubmitTimerAsDraft = (selectedTimer?: TimerSummaryElement) => {
        if (selectedTimer) {

            submitTimerMutation({
                variables: {
                    timerGuid: selectedTimer.timerGuid,
                    isSubmitAndSync: false,
                    timeWorkedSeconds: selectedTimer.timeWorkedSeconds ? Math.ceil(selectedTimer.timeWorkedSeconds) : selectedTimer.timeWorkedSeconds,
                    matterId: selectedTimer.matterId,
                    activityId: selectedTimer.activityId,
                    chargeRateId: selectedTimer.chargeRateId,
                    chargeRate: selectedTimer.chargeRate,
                    timesheetNote: selectedTimer.timesheetNote,
                    billingNote: selectedTimer.billingNote,
                    timeUnits: selectedTimer.units
                },
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isTimeslipOpen: false,
                };
            });
        }
    };

    const onSubmitTimer = (selectedTimer?: TimerSummaryElement) => {
        if (selectedTimer) {

            submitTimerMutation({
                variables: {
                    timerGuid: selectedTimer.timerGuid,
                    isSubmitAndSync: true,
                    timeWorkedSeconds: selectedTimer.timeWorkedSeconds ? Math.ceil(selectedTimer.timeWorkedSeconds) : selectedTimer.timeWorkedSeconds,
                    matterId: selectedTimer.matterId,
                    activityId: selectedTimer.activityId,
                    chargeRateId: selectedTimer.chargeRateId,
                    chargeRate: selectedTimer.chargeRate,
                    timesheetNote: selectedTimer.timesheetNote,
                    billingNote: selectedTimer.billingNote,
                    timeUnits: selectedTimer.units
                },
            });

            setState((prevState) => {
                return {
                    ...prevState,
                    isTimeslipOpen: false,
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const onDelete = (timerGuid: string | null) => () => {
        if (timerGuid) {

            let tempTimers = timers;

            const index = tempTimers.findIndex((time: TimerSummaryElement) => time.timerGuid === timerGuid);

            tempTimers.splice(index, 1);

            setTimers(tempTimers);

            setState((prevState) => {
                return {
                    ...prevState,
                    isDeleting: true,
                };
            });

            deleteTimerMutation({
                variables: {
                    timerGuid: timerGuid,
                },
            });
        }
    };

    const onDrilldown = (timerGuid: string | null, isCreate: boolean) => (currentTime: number) => {
        if (isCreate) {
            createNewTimer();
        } else if (!isCreate && timerGuid) {
            const tempTimers = timers;
            const index = tempTimers.findIndex((time: TimerSummaryElement) => time.timerGuid === timerGuid);

            // if (index >= 0 && tempTimers[index] && tempTimers[index].isRunning === true) {

            //     const currentDate = moment(); // .add('hours', 2); // temporary set to add 2 hours, need to be fixed

            //     // const SecondsDiff = getSecondsDifferenceBetweenLocalAndDefaultTimezone();

            //     // if (SecondsDiff > 0) {
            //     //     currentDate.add(SecondsDiff, 'Seconds');
            //     // } else if (SecondsDiff < 0) {
            //     //     currentDate.subtract(Math.abs(SecondsDiff), 'Seconds');
            //     // }

            //     const timeWorkedSecondsRunning = moment.duration(moment(currentDate).diff(tempTimers[index].lastStarted)).asSeconds();

            //     // Add the calcuated time duration with the existing timeWorkedSeconds. When starting the timer, we don't calculate the timeWorkedSeconds.
            //     const existingTimeWorked = tempTimers[index].timeWorkedSecondsTemp ? tempTimers[index].timeWorkedSecondsTemp : 0;
            //     const totalWorkedSeconds = (existingTimeWorked ? existingTimeWorked : 0) + timeWorkedSecondsRunning;

            //     // tempTimers[index].timeWorkedSeconds = totalWorkedSeconds;
            //     tempTimers[index].timeWorkedSecondsTemp = totalWorkedSeconds;
            // }
            if (index >= 0 && tempTimers[index] && tempTimers[index].isRunning === true) {
                tempTimers[index].timeWorkedSecondsTemp = Math.floor(currentTime / 1000 / 60);
                tempTimers[index].timeWorkedSeconds = Math.floor(currentTime / 1000 / 60);
            }

            setTimers(tempTimers);

            setState((prevState) => {
                // let tempTimers = prevState.timers;
                return {
                    ...prevState,
                    isTimeslipOpen: true,                   
                    selectedTimer: tempTimers[index],
                    isNewTimer: tempTimers[index].timerGuid === null,
                };
            });
        }
    };

    const onCloseTimeslip = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isTimeslipOpen: false,
            };
        });
    };

    const onUpdateAction = (timeslip: TimerSummaryElement, timerGuid?: string) => {
        if (timerGuid && timerGuid.length > 0) {
            let tempTimers = timers;

            const index = tempTimers.findIndex((time: TimerSummaryElement) => time.timerGuid === timerGuid);

            if (index >= 0) {
                tempTimers[index] = {
                    ...tempTimers[index],
                    ...timeslip,
                };
                tempTimers[index].activityDescription = timeslip.activityDescription;
                tempTimers[index].activityId = timeslip.activityId;
                tempTimers[index].chargeRate = timeslip.chargeRate;
                tempTimers[index].client = timeslip.client;
                tempTimers[index].fileNumber = timeslip.fileNumber;
                tempTimers[index].isRunning = false;
                tempTimers[index].matterCategory = timeslip.matterCategory;
                tempTimers[index].matterId = timeslip.matterId;
                tempTimers[index].matterType = timeslip.matterType;
                tempTimers[index].timesheetNote = timeslip.timesheetNote;
                tempTimers[index].title = timeslip.title;
                tempTimers[index].isRunning = timeslip.isRunning;

                if (timeslip.timeWorkedSeconds !== null && timeslip.timeWorkedSeconds !== 0) {
                    tempTimers[index].timeWorkedSeconds = timeslip.timeWorkedSecondsTemp;
                    tempTimers[index].timeWorkedSecondsTemp = timeslip.timeWorkedSecondsTemp;
                }
   
                tempTimers[index].billingNote = timeslip.billingNote;

            }

            updateTimerMutation({
                variables: {
                    timerGuid: timerGuid,
                    endDate: null,
                    timeWorkedSeconds: timeslip.timeWorkedSeconds ? Math.floor(timeslip.timeWorkedSeconds) : timeslip.timeWorkedSeconds,
                    matterId: timeslip.matterId,
                    activityId: timeslip.activityId,
                    chargeRateId: timeslip.chargeRateId,
                    chargeRate: timeslip.chargeRate,
                    timesheetNote: timeslip.timesheetNote,
                    billingNote: timeslip.billingNote,
                    timeUnits: timeslip.units,
                },
            });

            setTimers(tempTimers);

            setState((prevState) => {
                return {
                    ...prevState,
                    selectedTimerGuid: null,
                    isTimeslipOpen: false,
                };
            });
        }
    };

    const onCreateAction = (timeslip: TimerSummaryElement, timerGuid?: string) => {
        let tempTimers = timers;

        const index = tempTimers.findIndex((time: TimerSummaryElement) => time.timerGuid === timerGuid);

        if (index >= 0) {
            tempTimers[index] = {
                ...tempTimers[index],
                ...timeslip,
            };
            tempTimers[index].activityDescription = timeslip.activityDescription;
            tempTimers[index].activityId = timeslip.activityId;
            tempTimers[index].chargeRate = timeslip.chargeRate;
            tempTimers[index].client = timeslip.client;
            tempTimers[index].fileNumber = timeslip.fileNumber;
            tempTimers[index].isRunning = false;
            tempTimers[index].matterCategory = timeslip.matterCategory;
            tempTimers[index].matterId = timeslip.matterId;
            tempTimers[index].matterType = timeslip.matterType;
            tempTimers[index].timesheetNote = timeslip.timesheetNote;
            tempTimers[index].title = timeslip.title;
            tempTimers[index].isRunning = timeslip.isRunning;
            tempTimers[index].timeWorkedSeconds = timeslip.timeWorkedSeconds;
            tempTimers[index].timeWorkedSecondsTemp = timeslip.timeWorkedSeconds;
            tempTimers[index].billingNote = timeslip.billingNote;

        }

        createTimerMutation({
            variables: {
                isRunning: true,
                timeWorkedSeconds: timeslip.timeWorkedSeconds,
                matterId: timeslip.matterId,
                activityId: timeslip.activityId,
                chargeRateId: timeslip.chargeRateId,
                chargeRate: timeslip.chargeRate,
                timesheetNote: timeslip.timesheetNote,
                billingNote: timeslip.billingNote,
                timeUnits: timeslip.units,
                createdDate: new Date(),
                timerGuid: timerGuid ? timerGuid : null
            },
        });

        setTimers(tempTimers);

        setState((prevState) => {            
            return {
                ...prevState,
                // timers: tempTimers,
                selectedTimerGuid: null,
                isTimeslipOpen: false,
            };
        });
    };

    const onUpdateTimerAction = (timeslip: TimerSummaryElement, timerGuid?: string, newTimer?: boolean) => {
        if (newTimer) {
            onCreateAction(timeslip, timerGuid);
        } else {
            onUpdateAction(timeslip, timerGuid);
        }
    };

    const groupTimerByDate = () => {
        if (timers.length > 0) {
            let timerByDate = {};
            timers.forEach((timer: TimerSummaryElement) => {
                const _date = moment(timer.lastStarted).format('YYYY-MM-DD');
                if (timerByDate[_date]) {
                    timerByDate[_date].push(timer);
                } else {
                    timerByDate[_date] = [timer];
                }
            });

            return timerByDate;
        }

        return {};
    };

    const getTimersByDate = () => {
        const timerByDate = groupTimerByDate();

        if (Object.keys(timerByDate).length > 0) {
            const dateGroups = Object.keys(timerByDate).sort((a: string, b: string) => {
                return b.localeCompare(a);
            });

            return dateGroups.map((group: string) => {
                const timersList = timerByDate[group];

                const isCurrentDate = moment(group).isSame(new Date(), 'day');

                return (
                    <div key={group}>
                        <div className={classes.heading}>
                            <Typography variant="h6" component="h6">
                                {isCurrentDate ? 'Today' : moment(group).format('MMM DD, YYYY')}
                            </Typography>
                        </div>
                        {timersList.map((data: TimerSummaryElement, index: number) => {
                            return (
                                <TimerCard
                                    id={`${data.timerGuid}-${index}`}
                                    key={`${data.timerGuid}-${index}`}
                                    fileNumber={data.fileNumber ? data.fileNumber : undefined}
                                    title={data.title ? data.title : 'No Matter Selected'}
                                    client={data.client}
                                    matterCategory={data.matterCategory}
                                    matterType={data.matterType}
                                    description={data.activityDescription ? data.activityDescription : data.billingNote ? data.billingNote : undefined}
                                    // initialTime={data.timeWorkedSeconds ? (data.timeWorkedSeconds * 60000) : 0}
                                    initialTime={data.timeWorkedSecondsTemp ? (data.timeWorkedSecondsTemp * 60000) : 0}
                                    isRunning={data.inError ? false : data.isRunning}
                                    onStart={onPlay(data.timerGuid)}
                                    onStop={onStop(data.timerGuid)}
                                    onDrilldown={onDrilldown(data.timerGuid, false)}
                                    onDelete={onDelete(data.timerGuid)}
                                    onInvalidate={onInvalidate(data.timerGuid)}
                                    inError={data.inError ?? false}
                                    errorMessage={data.errorMessage ? data.errorMessage : undefined}
                                />
                            );
                        })}
                    </div>
                );
            });
        }

        return <div />;
    };

    const onDimissPanel = () => {
        if (!state.isTimeslipOpen && props.onClose) {
            props.onClose();
        }
    };

    return (
        <>
            <Panel
                headerText="Timers"
                isOpen={props.open}
                onDismiss={onDimissPanel}
                onLightDismissClick={onDimissPanel}
                customWidth="500px"
                type={PanelType.custom}
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel="Close"
                isBlocking={true}
                className={`${classes.panel} ${state.isTimeslipOpen ? classes.hidePanelButton : undefined}`}
            >
                {state.isTimeslipOpen && (
                    <div className={classes.timeslipPageWrapper} style={{ display: state.isTimeslipOpen ? 'flex' : 'none' }}>
                        <TimeslipPage
                            isFromTimer={true}
                            updateTimerAction={onUpdateTimerAction}
                            newTimer={state.isNewTimer}
                            onClose={onCloseTimeslip}
                            selectedTimer={state.selectedTimer ? state.selectedTimer : undefined}
                            timeWorkedSeconds={state.selectedTimer ? state.selectedTimer.timeWorkedSecondsTemp : 0}
                            onToggleTimer={onToggleTimer}
                            onSubmitTimer={onSubmitTimer}
                            onSubmitTimerAsDraft={onSubmitTimerAsDraft}
                            timeRecordSystem={state.timeRecordSystem}
                        />
                    </div>
                )}
                <div className={classes.timerWrapper} style={{ display: state.isTimeslipOpen ? 'none' : 'flex' }}>
                    <>
                        {
                            state.isDeleting
                            ? (
                                <div className={classes.loadingWrapper}>
                                    <RvLoader />
                                </div>
                            ) 
                            :
                            <TimerCard
                                isRunning={false}
                                title="Create New Timer"
                                onStart={createNewTimer}
                                isTimeDisabled={true}
                                isLoading={state.isCreateLoading}
                                onDrilldown={onDrilldown(null, true)}
                            />
                        }
                    </>
                    <>
                        {
                            timerSummaryQuery.loading 
                            ? (
                                <div className={classes.loadingWrapper}>
                                    <RvLoader />
                                </div>
                            ) 
                            :
                            timers.length === 0 
                            ? (
                                <Typography variant="subtitle1" color="textSecondary" className="description">
                                    No Pending Timers
                                </Typography>
                            )
                            : getTimersByDate()
                        }
                    </>
                </div>
            </Panel>
        </>
    );
};

export default React.memo(TimesheetTimerDrawer);