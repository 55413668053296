import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        timerWrapper: {
            paddingTop: 20,
            flex: 1,
            display: 'flex',
            flexFlow: 'column'
        },
        heading: {
            backgroundColor: '#3a262614',
            padding: '10px 15px',
        },

        // timer card area
        cardRoot: {
            display: 'flex',
            margin: '15px 0',
            minHeight: '116px',
        },
        cardControl: {
            width: '80px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#a0a1a5',
            color: '#fff',
            '& .MuiIconButton-root': {
                color: '#fff',
            },
        },
        cardControlPlay: {
            backgroundColor: '#84c584',
        },
        cardControlStop: {
            backgroundColor: '#a0a1a5',
        },
        cardControlError: {
            backgroundColor: '#D8524B',
        },
        playCircle: {
            fontSize: '50px',
        },
        cardContent: {
            position: 'relative',
            display: 'flex',
            flex: 1,
            '& .details': {
                flex: 1,
                justifyContent: 'center',
                display: 'flex',
                flexFlow: 'column',
                '& .id': {
                    color: '#cc1212',
                    fontSize: '16px',
                    marginBottom: '5px',
                },
                '& .title': {
                    fontSize: '16px',
                    marginBottom: '5px',
                },
                '& .description': {
                    fontSize: '16px',
                    marginBottom: '5px',
                },
                '& .error': {
                    fontSize: '16px',
                    marginBottom: '5px',
                    color: '#cc1212'
                }
            },
            '& .time': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .timeError': {
                display: 'flex',
                alignItems: 'center',
                color: '#cc1212',
                justifyContent: 'center',
            },
            '& .timeRunning': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        cardDetails: {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            cursor: 'pointer'
        },
        loadingWrapper: {
            padding: '30px 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
        },
        deleteButton: {
            position: 'absolute',
            top: '10px',
            right: '10px',
        },
        iconButton: {
            position: 'relative',
        },
        fabProgress: {
            color: blue[500],
            position: 'absolute',
            zIndex: 1,
        },
        hidePanelButton: {
            '& .ms-Panel-navigation': {
                '& .ms-Panel-closeButton': {
                    display: 'none'
                }
            }
        },
        panel: {
            '& .ms-Panel-scrollableContent': {
                display: 'flex',
                flexFlow: 'column',
                flex: 1,
            },
            '& .ms-Panel-content': {
                display: 'flex',
                flexFlow: 'column',
                flex: 1,
            }
        },
        timeslipPageWrapper: {
            flex: 1,
            display: 'flex',
            justifyContent: 'center'
        }
    }),
);
