import * as React from 'react';
import {
    withStyles,
    WithStyles,
    createStyles,
    Theme,
    Avatar,
    ListItemAvatar,
    Grid,
    Paper,
    ButtonGroup,
    TextField,
    // InputAdornment,
    Input,
    Typography,
} from '@material-ui/core';
import TimeslipHeader from './TimeslipHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import TimeslipMatterDialog from './TimeslipMatterDialog';
// import TimeslipTimeDialog from './TimeslipTimeDialog';
// import TimeslipStartsDialog from './TimeslipStartsDialog';
// import TimeslipActivityDialog from './TimeslipActivityDialog';
// import TimeslipNotesDialog from './TimeslipNotesDialog';
import { parse } from 'url';
import { client } from '..';
import gql from 'graphql-tag';
import { showNotification } from '../App';
import TimeslipTimePage from './page/TimeslipTimePage';
import classNames from 'classnames';
import TimeslipMatterPage, { getMatterTitlePrefix } from './page/TimeslipMatterPage';
import TimeslipStartPage from './page/TimeslipStartPage';
import TimeslipActivityPage from './page/TimeslipActivityPage';
import TimeslipNotesPage from './page/TimeslipNotesPage';
import TimeslipChargeCodePage from './page/TimeslipChargeCodePage';
import { Query } from 'react-apollo';
import { RvLoader } from '../components/Loader';
import { fetchTypedUserSettings, TypedTenantUserOptions } from '../userSettings/UserSettingsRepository';
import { Button } from 'semantic-ui-react';
import { MatterFinancialsQueryParams, retrieveMatterFinancials, TimeslipSummaries } from '../timesheet/TimesheetRepository';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { generateColor } from './matter/ColorGenerator';
import { CurrencyDisplayStyle, FormattedCurrency } from '../components/DataDisplay/FormattedCurrency';
import { MatterTerminology } from '../MatterTerminology';
import { mainTheme } from '../Theme';
import { Matter, MatterData } from '../matterDetails/financials/FinancialsInfo';
import moment from 'moment';
import glamorous from 'glamorous';
import { MatterLookUpAutoCompleteSelector, MatterLookUpItem } from './selectors/MatterLookUpAutoCompleteSelector';
import { ActivityAutoCompleteSelector, ActivityItem } from './selectors/ActivityAutoCompleteSelector';
import { TimerSummaryElement } from '../timesheetTimer/TimesheetTimerRepository';
import DialogBox from '../Dashboard/Component/DialogBox';
import { formatNumber } from '../helpers/ChartHelper';
import { Systems } from '../hooks/useTimeRecording';
import { UserFeature, UserFeatureEnum } from '../types/UserFeature';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            flexGrow: 1,
            display: 'flex',
            flexFlow: 'column wrap',
        },
        inline: {
            display: 'inline',
        },
        timeContent: {
            textAlign: 'center',
            '& > span': {
                fontSize: '24px',
            },
        },
        mainWrapper: {
            flex: 1,
            '& .MuiTypography-colorTextSecondary': {
                fontSize: '14px',
            },
        },
        timeWrapper: {
            display: 'flex',
            flexFlow: 'column',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            backgroundColor: '#fff',
            // display: 'none',
        },
        hide: {
            display: 'none',
        },
        buttonContainer: {
            width: '100%',
            paddingTop: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            '& .buttonWrapper': {
                minWidth: '87px',
            },
        },
        customList: {
            display: 'flex',
            flexFlow: 'column',
            fontSize: '16px',
            '& .id': {
                color: '#cc1212',
                marginBottom: '5px',
            },
            '& .name': {
                marginBottom: '5px',
            },
            '& .category': {
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '12px',
            },
        },
        primaryList: {
            display: 'flex',
            justifyContent: 'space-between',
            // paddingBottom: '10px',
            '& .col-1': {
                fontWeight: 500,
            },
            '& .col-2': {
                // fontWeight: 600,
                width: '200px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'right',
            },
        },
        secondaryList: {
            // paddingTop: '10px',
            display: 'flex',
            flexFlow: 'column wrap',
            fontSize: '16px',
            fontWeight: 600,
            '& .col-1': {
                color: '#000',
            },
            '& .col-2': {},
        },
        cardWrapper: {
            paddingTop: 10,
            display: 'flex',
            flex: 1,
        },
        paper: {
            display: 'flex',
            flexFlow: 'column wrap',
            // minHeight: '85px',
            background: '#fff',
            width: '100%',
            boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.3)',
            // borderRadius: '5px',
            // marginBottom: '15px',
            padding: '5px',
            fontFamily: 'Nunito',
            textAlign: 'center',
            height: '100%',
            [`& .${`label`}`]: {
                fontWeight: '400',
                marginBottom: '5px',
                color: mainTheme.BrandColors.TemplatePrimary,
            },

            [`& .${`value`}`]: {
                textAlign: 'center',
                fontWeight: '400',
                fontSize: '21px',
            },
            [`& .${`value-string`}`]: {
                textAlign: 'center',
                fontWeight: '400',
                fontSize: '21px',
            },
        },
        unitFieldClass: {
            fontSize: 24,
            textAlign: 'center',
        },
        unitField: {
            maxWidth: '70px',
        },
        primaryListItemTex: {
            paddingRight: '20px',
        },
        inputDuration: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '150px',
            margin: '0 auto',
            '& > .MuiTypography-root': {
                marginLeft: '10px',
            },
        },
        inputRoot: {
            textAlign: 'center',
            fontSize: 24,
        },
        timerButtonWrapper: {
            display: 'flex',
            alignItems: 'center',
            flexFlow: 'column wrap',
            '& .buttonWrapper': {
                marginBottom: '5px',
                minWidth: '120px',
            },
        },
        seconds: {
            fontSize: '16px',
            paddingLeft: '5px',
            paddingBottom: '5px',
        },
        timeLoader: {
            fontSize: '14px',
            marginLeft: '5px',
            minWidth: '24px',
        },
        dialogAdittionalTitle: {
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '10px',
        },
        dialogAdditionalClient: {
            fontSize: '24px',
            fontWeight: 600,
        },
        dialogAdditionalTitle: {
            fontSize: '18px',
        },
        thresholdText: {
            padding: '20px 0',
            fontSize: '18px',
            // fontWeight: 600,
            textAlign: 'center',
            color: 'red'
        },
        warning: {
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: 1.6,
            letterSpacing: '0.0075em',
            padding: '16px 0px',
        },
    });

// tslint:disable-next-line: no-any
const ButtonGroupWrapper = glamorous(ButtonGroup)<{ theme?: any }>((props) => ({
    '& > .MuiButtonGroup-grouped': {
        marginBottom: 5,
    },
}));

interface TimeslipPageProps extends WithStyles<typeof styles> {
    onClose?: () => void;
    updateTimerAction: (timeslip: TimerSummaryElement, timerGuid?: string, shouldCreate?: boolean) => void;
    updateAction?: (timeslip: TimeslipSummaries, timerGuid?: string, shouldCreate?: boolean) => void;
    newTimer?: boolean;
    mergeAction: () => void;
    currDate?: Date;
    timeslips?: TimeslipSummaries[];
    isFromTimer?: boolean;
    timeWorkedMinutes?: number;
    selectedTimer?: TimerSummaryElement;
    onToggleTimer?: (selectedTimer?: TimerSummaryElement) => void;
    onSubmitTimer?: (selectedTimer?: TimerSummaryElement) => void;
    onSubmitTimerAsDraft?: (selectedTimer?: TimerSummaryElement) => void;
    feeEarnerId?: string;
    timeRecordSystem: Systems | null;
}

interface TimeslipPageStates {
    hasChangedTime: boolean;
    favMattersFirst?: boolean;
    favActivityFirst?: boolean;
    allowDraft?: boolean;
    matterDialogOpen: boolean;
    timeDialogOpen: boolean;
    startsDialogOpen: boolean;
    activityDialogOpen: boolean;
    notesDialogOpen: boolean;
    chargeCodeDialogOpen: boolean;
    settings: TypedTenantUserOptions;
    noteHasBeenCustomised: boolean;
    isFromOutlook: boolean;
    shouldSubmit: boolean;
    inInprotechNotes: boolean;
    // tslint:disable-next-line: no-any
    timeslipsToRemove?: any;
    timeslip: {
        id?: number;
        // tslint:disable-next-line: no-any
        matter: any;
        // tslint:disable-next-line: no-any
        activity: any;
        // tslint:disable-next-line: no-any
        time: {
            // tslint:disable-next-line: no-any
            duration: any;
            minutes: number;
            hours: number;
            seconds: number;
        };
        units: number;
        // tslint:disable-next-line: no-any
        chargeCode: string;
        chargeCodeId: number;
        chargeRate: number;
        date: Date;
        note: string;
        inprotechNote: string;
    };
    isFinancialsLoading: boolean;
    matter?: Matter;
    inputFocus: number;
    // isMatterSelected: boolean;
    selectedTimer?: TimerSummaryElement;
    feeEarnerId?: string;
    showConfirm: boolean;
    hasChangedField: boolean;
    showConfirmBillingThreshold: boolean;
    billingConfirmThresholdText: string;
}

class TimeslipPage extends React.Component<TimeslipPageProps, TimeslipPageStates> {
    private sourceSystem: string;
    private timeCaptureMode: string;
    private currencySymbol: string;
    private localCurrency: string;
    // tslint:disable-next-line: no-any
    private interval: any;
    private threshold: number;

    constructor(props: TimeslipPageProps) {
        super(props);

        this.handleToggle = this.handleToggle.bind(this);
        this.onTimeData = this.onTimeData.bind(this);
        this.onActivityData = this.onActivityData.bind(this);
        this.onNotesData = this.onNotesData.bind(this);
        this.onMatterData = this.onMatterData.bind(this);
        this.onStartData = this.onStartData.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCloseTime = this.onCloseTime.bind(this);
        this.onCloseMatter = this.onCloseMatter.bind(this);
        this.onCloseStart = this.onCloseStart.bind(this);
        this.onCloseActivity = this.onCloseActivity.bind(this);
        this.onCloseNotes = this.onCloseNotes.bind(this);
        this.onChargeCodeData = this.onChargeCodeData.bind(this);
        this.onCloseChargeCode = this.onCloseChargeCode.bind(this);
        this.setDuration = this.setDuration.bind(this);
        this.setMatter = this.setMatter.bind(this);
        this.getActionButton = this.getActionButton.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.closePanel = this.closePanel.bind(this);
        this.handleCreateAndClose = this.handleCreateAndClose.bind(this);
        this.handleCreateAndNew = this.handleCreateAndNew.bind(this);
        this.handleCreateAndCopy = this.handleCreateAndCopy.bind(this);
        this.handleCreateAndPost = this.handleCreateAndPost.bind(this);
        this.primaryList = this.primaryList.bind(this);
        this.secondaryList = this.secondaryList.bind(this);
        this.getUnitsFromMinutes = this.getUnitsFromMinutes.bind(this);
        this.getMinutesFromUnits = this.getMinutesFromUnits.bind(this);
        this.matterListUI = this.matterListUI.bind(this);
        this.limitText = this.limitText.bind(this);
        this.onLongText = this.onLongText.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.onMatterSelected = this.onMatterSelected.bind(this);
        this.onMatterFinancialsRetrieved = this.onMatterFinancialsRetrieved.bind(this);
        this.onChangeUnit = this.onChangeUnit.bind(this);
        this.formatNumberBy0 = this.formatNumberBy0.bind(this);
        this.onChangeDuration = this.onChangeDuration.bind(this);
        this.onMatterLookupChange = this.onMatterLookupChange.bind(this);
        this.onActivityChange = this.onActivityChange.bind(this);
        this.onSaveToTimer = this.onSaveToTimer.bind(this);
        this.onToggleTimer = this.onToggleTimer.bind(this);
        this.onSubmitTimer = this.onSubmitTimer.bind(this);
        this.onSubmitTimerAsDraft = this.onSubmitTimerAsDraft.bind(this);
        this.updateRunningTime = this.updateRunningTime.bind(this);
        this.closePanelFromTimer = this.closePanelFromTimer.bind(this);
        this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
        this.onShowThresholdConfirm = this.onShowThresholdConfirm.bind(this);
        this.isMatterAndActivtyEmpty = this.isMatterAndActivtyEmpty.bind(this);

        this.state = {
            hasChangedField: false,
            hasChangedTime: false,
            matterDialogOpen: false,
            timeDialogOpen: false,
            startsDialogOpen: false,
            activityDialogOpen: false,
            notesDialogOpen: false,
            chargeCodeDialogOpen: false,
            noteHasBeenCustomised: false,
            inInprotechNotes: false,
            settings: new TypedTenantUserOptions(),
            isFromOutlook: false,
            shouldSubmit: true,
            timeslip: {
                matter: {},
                activity: {},
                chargeCode: '',
                chargeCodeId: 0,
                chargeRate: 0,
                time: {
                    duration: '',
                    hours: this.props.isFromTimer && this.props.timeWorkedMinutes ? this.props.timeWorkedMinutes / 60 : 0,
                    minutes: this.props.isFromTimer && this.props.timeWorkedMinutes ? this.props.timeWorkedMinutes : 0,
                    seconds: this.props.isFromTimer && this.props.timeWorkedMinutes ? Math.floor(this.props.timeWorkedMinutes) * 60 : 0,
                },
                units: 0,
                date: props.currDate ? props.currDate : new Date(),
                note: '',
                inprotechNote: '',
            },
            isFinancialsLoading: true,
            inputFocus: 0,
            // isMatterSelected: false,
            selectedTimer: this.props.isFromTimer ? this.props.selectedTimer : undefined,
            feeEarnerId: this.props.feeEarnerId,
            showConfirm: false,
            showConfirmBillingThreshold: false,
            billingConfirmThresholdText: '',
        };
        this.sourceSystem = '';
        this.threshold = this.props.timeRecordSystem?.timeRecordingSettings?.config?.thresholdLevel ?? 0;
    }

    componentDidMount() {
        // eslint-disable-next-line no-restricted-globals
        const url = parse(location.href, true);
        const parsed = url.query;

        if (parsed.accessToken) {
            const token = parsed.accessToken as string;
            // tslint:disable-next-line:no-console
            if (token) {
                sessionStorage.setItem('accessToken', token);
            }
        }

        fetchTypedUserSettings(
            false,
            // tslint:disable-next-line:no-console
            (data: TypedTenantUserOptions) => {
                this.setState({
                    settings: data,
                    shouldSubmit: parsed.duration ? parsed.defaultToDraft !== 'True' : !data.defaultToDraft,
                    allowDraft: parsed.duration ? parsed.allowDraft === 'True' : true,
                });
                this.setMatter(this.state.settings.defaultMatter);
                if (parsed.duration) {
                    if (this.state.settings.emailDefaultActivity) {
                        let activityObject = JSON.parse(this.state.settings.emailDefaultActivity);
                        if (activityObject && activityObject.StringValue) {
                            this.setActivity(activityObject.StringValue);
                        }
                    }
                    return;
                }

                if (!this.props.timeslips && !this.props.selectedTimer) {
                    if (this.state.settings.defaultActivity) {
                        let activityObject = JSON.parse(this.state.settings.defaultActivity);
                        if (activityObject && activityObject.StringValue) {
                            // const activity = {
                            //   stringValue: activityObject.StringValue
                            // };
                            this.setActivity(activityObject.StringValue);
                        }
                    }

                    this.setDuration(this.state.settings.defaultDurationMinutes);
                }

                if (this.props.selectedTimer) {
                    this.setDuration((this.props.selectedTimer.timeWorkedSecondsTemp ??  0) / 60);
                }
            },
            (reason) => {
                showNotification('Failed to Fetch Layout Configuration', reason, 'error');
            },
        );

        // tslint:disable-next-line: no-any
        var timeslip: any;
        var timeslipsToDelete = [];

        if (!this.props.timeslips) {
            timeslip = {
                chargeRate: 0,
                matter: {},
                activity: {},
                chargeCode: '',
                chargeCodeId: 0,

                time: {
                    duration: parsed.duration ? parsed.duration : '00:00',
                    minutes: parsed.duration ? this.getMinutesFromDurationString(parsed.duration as string) : 0,
                    hours: parsed.duration ? this.getHoursFromDurationString(parsed.duration as string) : 0,
                },
                units: this.getUnitsFromMinutes(parsed.duration ? this.getHoursFromDurationString(parsed.duration as string) : 0),
                date: parsed.date ? new Date('' + parsed.date) : this.props.currDate ? this.props.currDate : this.state.timeslip.date,
                note: parsed.notes ? '' + parsed.notes : '',
                inprotechNote: '',
            };
        } else {
            var template = this.props.timeslips[0];
            // var totalTime = 0;
            var newActivity = {
                stringValue: template.activityDescription,
                id: template.id,
                label: template.activityDescription,
                value: template.activityDescription,
            };
            var matter = {};
            if (template.matterID) {
                matter = {
                    id: template.matterID,
                    client: template.client,
                    fileNumber: template.fileNumber,
                    title: template.title,
                    matterCategory: template.matterCategory,
                    matterType: template.matterType,
                    label: template.fileNumber + ' (' + template.title + ')',
                    value: template.fileNumber,
                };
            }

            var totalMinutes = 0;
            var notes = '';
            var inprotechNotes = '';
            var distinctNotes = new Set();
            var distinctInprotechNotes = new Set();

            for (var item of this.props.timeslips) {
                if (item.id !== template.id) {
                    timeslipsToDelete.push(item.id);
                }
                if (item.timeWorkedSeconds) {
                    totalMinutes += item.timeWorkedSeconds / 60;
                }
                if (item.note) {
                    distinctNotes.add(item.note);
                }
                if (item.timesheetNote) {
                    distinctInprotechNotes.add(item.timesheetNote);
                }
            }

            var notesArray = Array.from(distinctNotes);
            notes = notesArray.join(', ');

            var inprotechNotesArray = Array.from(distinctInprotechNotes);
            inprotechNotes = inprotechNotesArray.join(', ');
            var minutes = totalMinutes % 60;
            var hours = Math.floor(totalMinutes / 60);
            var seconds = 0;

            var minutesString = '' + minutes;
            if (minutes < 10) {
                minutesString = '0' + minutes;
            }
            var durationString = hours + ':' + minutesString;
            timeslip = {
                id: template.id,
                chargeRate: 0,
                matter: matter,
                activity: newActivity,
                chargeCode: '',
                chargeCodeId: 0,
                time: {
                    duration: durationString,
                    minutes: minutes,
                    hours: hours,
                    seconds: seconds,
                },
                units: this.getUnitsFromMinutes(totalMinutes),
                date: template.itemDate ? new Date('' + template.itemDate) : this.props.currDate ? this.props.currDate : new Date(),
                note: notes,
                inprotechNote: inprotechNotes,
            };
        }

        if (this.props.selectedTimer) {
            var timer = this.props.selectedTimer;

            var activity = {
                stringValue: timer.activityDescription,
                id: timer.activityId,
                label: timer.activityDescription,
                value: timer.activityDescription,
            };

            if (timer.matterId) {
                matter = {
                    id: timer.matterId,
                    client: timer.client,
                    fileNumber: timer.fileNumber,
                    title: timer.title,
                    matterCategory: timer.matterCategory,
                    matterType: timer.matterType,
                    label: timer.fileNumber + ' (' + timer.title + ')',
                    value: timer.fileNumber,
                };
            } else {
                matter = {};
            }

            minutes = Math.floor(((timer.timeWorkedSeconds ?? 0) / 60) % 60);
            hours = Math.floor((timer.timeWorkedSeconds ?? 0) / (60 * 60));
            seconds = (timer.timeWorkedSeconds ?? 0) % (60 * 60);
            minutesString = '' + minutes;

            if (minutes < 10) {
                minutesString = '0' + minutes;
            }

            durationString = hours + ':' + minutesString;
            var billingNote = timer.billingNote;

            timeslip = {
                id: 1,
                chargeRate: 0,
                matter: matter,
                activity: activity,
                chargeCode: '',
                chargeCodeId: 0,
                time: {
                    duration: durationString,
                    minutes: minutes,
                    hours: hours,
                    seconds: seconds,
                },
                units: this.getUnitsFromMinutes(timer.timeWorkedSeconds ?? 0),
                date: timer.lastStarted ? new Date('' + timer.lastStarted) : this.props.currDate ? this.props.currDate : new Date(),
                note: billingNote,
                inprotechNote: timer.timesheetNote ?? '',
            };
        }

        this.setState({
            timeslip: timeslip,
            timeslipsToRemove: timeslipsToDelete,
            favActivityFirst: parsed.duration ? parsed.favActFirst === 'True' : undefined,
            favMattersFirst: parsed.duration ? parsed.favMatterFirst === 'True' : undefined,
            isFromOutlook: parsed.duration ? true : false,
            noteHasBeenCustomised: parsed.notes ? true : false,
            inInprotechNotes: false,
        });

        if (this.props.isFromTimer && this.state.selectedTimer && this.state.selectedTimer.isRunning) {
            this.interval = setInterval(this.updateRunningTime, 1000);
        }

        if (timeslip && timeslip.matter && Object.keys(timeslip.matter).length > 0) {
            this.onMatterSelected(timeslip.matter.id);
        }
    }

    componentWillUnmount() {
        if (this.props.isFromTimer && this.state.selectedTimer && this.state.selectedTimer.isRunning) {
            clearInterval(this.interval);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: TimeslipPageProps, nextContext: TimeslipPageStates): void {
        if (nextProps.isFromTimer && nextProps.selectedTimer && nextProps.selectedTimer.isRunning) {
            clearInterval(this.interval);
            this.interval = setInterval(this.updateRunningTime, 1000);
        }

        if (nextProps.currDate !== this.props.currDate) {
            var timeslip = this.state.timeslip;
            timeslip.date = nextProps.currDate ? nextProps.currDate : new Date();
            this.setState({
                timeslip: timeslip,
            });
        }

        if (this.props.timeRecordSystem !== nextProps.timeRecordSystem) {
            this.threshold = nextProps.timeRecordSystem?.timeRecordingSettings?.config?.thresholdLevel ?? 0;
        }

        if (this.props.isFromTimer) {
            // set changes to the selected timer
            this.setState({
                selectedTimer: nextProps.selectedTimer,
            });
        }
    }

    getMinutesFromDurationString(durationString: string): number {
        // tslint:disable-next-line:radix
        return parseInt(durationString.split(':')[1]);
    }

    getHoursFromDurationString(durationString: string): number {
        // tslint:disable-next-line:radix
        return parseInt(durationString.split(':')[0]);
    }

    getUnitsFromMinutes(minutes: number): number {
        if (this.state.settings !== undefined) {
            var minutesPerUnit = 60 / this.state.settings.unitsPerHour;
            if (minutes === 0) {
                minutes = 1;
            }
            return Math.ceil(minutes / minutesPerUnit);
        }
        // tslint:disable-next-line:radix
        return 0;
    }

    getMinutesFromUnits(units: number): number {
        if (this.state.settings !== undefined) {
            var minutesPerUnit = 60 / this.state.settings.unitsPerHour;
            return units * minutesPerUnit;
        }
        // tslint:disable-next-line:radix
        return 0;
    }

    render() {
        const { classes } = this.props;

        let financials = this.state.matter ? this.state.matter.financials : undefined;
        const displayZero = 0;

        var estimatedFeesColour: string | undefined = undefined;
        var billedTimeAndFees = 0;
        var unbilledTimeAndFees = 0;
        var estimatedFees = 0;

        if (financials) {
            billedTimeAndFees = financials.billedTimeAndFees ? financials.billedTimeAndFees : 0;
            unbilledTimeAndFees = financials.unbilledTimeAndFees ? financials.unbilledTimeAndFees : 0;
            estimatedFees = financials.estimatedCosts ? financials.estimatedCosts : 0;
        }

        estimatedFeesColour = billedTimeAndFees + unbilledTimeAndFees > estimatedFees ? 'red' : undefined;

        return (
            <>
                {this.state.showConfirm && (
                    <DialogBox
                        title="Timer"
                        // tslint:disable-next-line:max-line-length
                        content={'Are you sure you want to close?'}
                        show={this.state.showConfirm}
                        isAgree={this.onDeleteConfirm}
                        agreeLabel={'Yes'}
                        disablePortal={true}
                    />
                )}
                {this.state.showConfirmBillingThreshold && UserFeature.HasAccess(UserFeatureEnum.hasFeeEstimateWarning) && (
                    <DialogBox
                        title="Warning"
                        // tslint:disable-next-line:max-line-length
                        show={this.state.showConfirmBillingThreshold}
                        isAgree={this.onShowThresholdConfirm}
                        agreeLabel={'Ok'}
                        disablePortal={true}
                        hideDisagree={true}
                    >
                        <div className={classes.dialogAdittionalTitle}>
                            <div className={classes.dialogAdditionalClient}>{this.state.matter?.client}</div>
                            <div className={classes.dialogAdditionalTitle}>{this.state.matter?.title}</div>
                        </div>
                        {this.getMatterFinancials(classes, estimatedFeesColour, financials, displayZero, this.localCurrency)}
                        {this.state.billingConfirmThresholdText && (
                            <div className={classes.thresholdText}>{this.state.billingConfirmThresholdText}</div>
                        )}
                    </DialogBox>
                )}
                {/*tslint:disable-next-line: no-any */}
                <Query<any> query={TenantData} fetchPolicy="cache-first">
                    {({ loading, error, data }) => {
                        if (loading || error || !data || !data.tenant || !data.tenant.sourceSystem || !data.settings) {
                            return <RvLoader size="large" />;
                        } else {
                            this.sourceSystem = data.tenant.sourceSystem;
                            this.timeCaptureMode = data.settings.systems.timeRecordingDefaults.config.timeCaptureMode;
                            // this.threshold = data.settings.systems.timeRecordingSettings.config.thresholdLevel;
                            this.currencySymbol = data.options.currencySymbol.symbol;

                            const llocalCurrency = loading || error ? null : data.options.localCurrency;
                            this.localCurrency = llocalCurrency ? llocalCurrency.currency : 'AUD';

                            const hasChargeCodes =
                                this.sourceSystem.toLowerCase() === 'infinity' ||
                                this.sourceSystem.toLowerCase() === 'coyote' ||
                                this.sourceSystem.toLowerCase() === 'practiceevolved';

                            const hasPosting = this.sourceSystem.toLowerCase() === 'inprotech';

                            const noteTitle = hasPosting && !this.state.inInprotechNotes ? 'Narrative' : 'Notes';

                            // tslint:disable-next-line:no-console
                            return (
                                <React.Fragment>
                                    <div className={classes.root}>
                                        <div className={classNames(classes.timeWrapper, this.state.timeDialogOpen ? '' : classes.hide)}>
                                            {this.state.timeDialogOpen ? (
                                                <TimeslipTimePage
                                                    onClose={this.onCloseTime}
                                                    onData={this.onTimeData}
                                                    minutesInUnit={this.state.settings.unitsPerHour ? 60 / this.state.settings.unitsPerHour : 10}
                                                    hours={this.state.timeslip.time.hours}
                                                    minutes={this.state.timeslip.time.minutes}
                                                    seconds={this.state.timeslip.time.seconds}
                                                    settings={this.state.settings}
                                                />
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                        <div className={classNames(classes.timeWrapper, this.state.matterDialogOpen ? '' : classes.hide)}>
                                            {this.state.matterDialogOpen ? (
                                                <TimeslipMatterPage
                                                    onClose={this.onCloseMatter}
                                                    onData={this.onMatterData}
                                                    favMattersFirst={this.state.favMattersFirst}
                                                    sourceSystem={this.sourceSystem}
                                                    feeEarnerId={this.props.feeEarnerId}
                                                />
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                        <div className={classNames(classes.timeWrapper, this.state.startsDialogOpen ? '' : classes.hide)}>
                                            {this.state.startsDialogOpen ? (
                                                <TimeslipStartPage onClose={this.onCloseStart} onData={this.onStartData} date={this.state.timeslip.date} />
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                        <div className={classNames(classes.timeWrapper, this.state.activityDialogOpen ? '' : classes.hide)}>
                                            {this.state.activityDialogOpen ? (
                                                <TimeslipActivityPage
                                                    onClose={this.onCloseActivity}
                                                    onData={this.onActivityData}
                                                    favActivityFirst={this.state.favActivityFirst}
                                                    feeEarnerId={this.props.feeEarnerId}
                                                />
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                        <div className={classNames(classes.timeWrapper, this.state.chargeCodeDialogOpen ? '' : classes.hide)}>
                                            {this.state.chargeCodeDialogOpen ? (
                                                <TimeslipChargeCodePage onClose={this.onCloseChargeCode} onData={this.onChargeCodeData} sourceSystem={this.sourceSystem} />
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                        <div className={classNames(classes.timeWrapper, this.state.notesDialogOpen ? '' : classes.hide)}>
                                            {this.state.notesDialogOpen ? (
                                                <TimeslipNotesPage
                                                    onClose={this.onCloseNotes}
                                                    onData={this.onNotesData}
                                                    note={this.state.inInprotechNotes ? this.state.timeslip!.inprotechNote : this.state.timeslip!.note}
                                                    isInprotechNote={this.state.inInprotechNotes}
                                                    title={noteTitle}
                                                />
                                            ) : (
                                                <div />
                                            )}
                                        </div>
                                        <div className={classes.mainWrapper}>
                                            <TimeslipHeader onSave={this.onSubmit} forTimer={this.props.isFromTimer} />
                                            <List className={classes.root}>
                                                <ListItem
                                                // button={true}
                                                // onClick={this.handleToggle('time')}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            this.timeCaptureMode.toLocaleLowerCase() === 'units' && !this.props.isFromTimer ? (
                                                                <div className={classes.inputDuration}>
                                                                    <TextField
                                                                        value={this.state.timeslip.units}
                                                                        type="number"
                                                                        InputProps={{
                                                                            // endAdornment: <InputAdornment position="end">{this.timeCaptureMode}</InputAdornment>,
                                                                            classes: {
                                                                                input: classes.unitFieldClass,
                                                                            },
                                                                        }}
                                                                        onChange={this.onChangeUnit}
                                                                        className={classes.unitField}
                                                                        size="medium"
                                                                        autoFocus={true}
                                                                        tabIndex={1}
                                                                    />
                                                                    <Typography color="textSecondary">{this.timeCaptureMode}</Typography>
                                                                </div>
                                                            ) : (
                                                                <div className={classes.inputDuration}>
                                                                    <Input
                                                                        value={this.formatNumberBy0(this.state.timeslip.time.hours)}
                                                                        onChange={this.onChangeDuration('hour')}
                                                                        classes={{
                                                                            input: classes.inputRoot,
                                                                        }}
                                                                        autoFocus={true}
                                                                        tabIndex={1}
                                                                        style={{
                                                                            color:
                                                                                this.props.isFromTimer && this.state.selectedTimer && this.state.selectedTimer.isRunning
                                                                                    ? 'green'
                                                                                    : 'inherit',
                                                                        }}
                                                                    />
                                                                    <div
                                                                        style={{
                                                                            color:
                                                                                this.props.isFromTimer && this.state.selectedTimer && this.state.selectedTimer.isRunning
                                                                                    ? 'green'
                                                                                    : 'inherit',
                                                                        }}
                                                                    >
                                                                        :
                                                                    </div>
                                                                    <Input
                                                                        value={this.formatNumberBy0(this.state.timeslip.time.minutes)}
                                                                        onChange={this.onChangeDuration('minute')}
                                                                        classes={{
                                                                            input: classes.inputRoot,
                                                                        }}
                                                                        tabIndex={2}
                                                                        style={{
                                                                            color:
                                                                                this.props.isFromTimer && this.state.selectedTimer && this.state.selectedTimer.isRunning
                                                                                    ? 'green'
                                                                                    : 'inherit',
                                                                        }}
                                                                    />
                                                                    {/* enabled to see the seconds in the UI  */}
                                                                    {/* {this.props.isFromTimer && this.state.selectedTimer && this.state.selectedTimer.isRunning && (
                                                                        <div 
                                                                            style={{ color: this.props.isFromTimer && this.state.selectedTimer && 
                                                                                this.state.selectedTimer.isRunning ? 'green' : 'inherit'}}
                                                                            className={classes.seconds}
                                                                        >
                                                                            {this.state.timeslip.time.seconds}
                                                                        </div>
                                                                    )} */}
                                                                    <div className={classes.timeLoader}>
                                                                        {this.props.isFromTimer && this.state.selectedTimer && this.state.selectedTimer.isRunning && (
                                                                            <RvLoader size="small" />
                                                                        )}
                                                                    </div>
                                                                    <Typography color="textSecondary">{''}</Typography>
                                                                </div>
                                                            )
                                                        }
                                                        className={classes.timeContent}
                                                        classes={{
                                                            primary: classes.primaryListItemTex,
                                                        }}
                                                        tabIndex={-1}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={this.handleToggle('time')} tabIndex={-1}>
                                                            <ChevronRightIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>

                                                <Divider component="li" tabIndex={-1} />

                                                <ListItem
                                                    // button={true}
                                                    // onClick={this.handleToggle('matter')}
                                                    tabIndex={-1}
                                                >
                                                    <ListItemText
                                                        primary={
                                                            <MatterLookUpAutoCompleteSelector
                                                                name="matter"
                                                                sourceSystem={this.sourceSystem}
                                                                label="Matter"
                                                                onSelection={this.onMatterLookupChange}
                                                                value={this.state.timeslip.matter}
                                                                disablePortal={true}
                                                                descriptionLimit={40}
                                                                tabIndex={this.timeCaptureMode.toLocaleLowerCase() === 'units' ? 2 : 3}
                                                                statusFilter="CURRENT"
                                                                isExcludeCompleted={true}
                                                            />
                                                        }
                                                        // secondary={
                                                        //   this.state.timeslip.matter.fileNumber
                                                        //     ? this.matterListUI(this.state.timeslip.matter)
                                                        //     : ''
                                                        // }
                                                        classes={{
                                                            primary: classes.primaryListItemTex,
                                                        }}
                                                        tabIndex={-1}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={this.handleToggle('matter')} tabIndex={-1}>
                                                            <ChevronRightIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>

                                                <Divider component="li" tabIndex={-1} />

                                                <ListItem button={true} onClick={this.handleToggle('starts')} tabIndex={-1}>
                                                    <ListItemText
                                                        primary={this.primaryList('Starts', this.state.timeslip!.date.toDateString())}
                                                        classes={{
                                                            primary: classes.primaryListItemTex,
                                                        }}
                                                        tabIndex={-1}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={this.handleToggle('starts')} tabIndex={-1}>
                                                            <ChevronRightIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>

                                                <Divider component="li" tabIndex={-1} />

                                                <ListItem
                                                    // button={true}
                                                    tabIndex={-1}
                                                    // onClick={this.handleToggle('activity')}
                                                >
                                                    <ListItemText
                                                        // primary={this.primaryList(
                                                        //   'Activity',
                                                        //   this.state.timeslip.activity.stringValue
                                                        //     ? this.state.timeslip.activity.stringValue
                                                        //     : ''
                                                        // )}
                                                        primary={
                                                            <ActivityAutoCompleteSelector
                                                                name="activity"
                                                                label="Activity"
                                                                disablePortal={true}
                                                                onSelection={this.onActivityChange}
                                                                value={this.state.timeslip.activity}
                                                                tabIndex={this.timeCaptureMode.toLocaleLowerCase() === 'units' ? 3 : 4}
                                                                feeEarnerId={this.props.feeEarnerId}
                                                            />
                                                        }
                                                        classes={{
                                                            primary: classes.primaryListItemTex,
                                                        }}
                                                        tabIndex={-1}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={this.handleToggle('activity')} tabIndex={-1}>
                                                            <ChevronRightIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>

                                                <Divider component="li" tabIndex={-1} />

                                                {hasChargeCodes ? (
                                                    <ListItem button={true} onClick={this.handleToggle('chargeCode')} key={'chargeCodeListItem'} tabIndex={-1}>
                                                        <ListItemText
                                                            primary={this.primaryList(
                                                                this.sourceSystem.toLowerCase() === 'coyote' ? 'Task Code' : 'Charge Code',
                                                                this.state.timeslip!.chargeCode ? this.state.timeslip!.chargeCode : '',
                                                            )}
                                                            classes={{
                                                                primary: classes.primaryListItemTex,
                                                            }}
                                                        />
                                                        <ListItemSecondaryAction>
                                                            <IconButton onClick={this.handleToggle('chargeCode')} tabIndex={-1}>
                                                                <ChevronRightIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ) : null}

                                                <Divider component="li" tabIndex={-1} />

                                                {this.onLongText(
                                                    <React.Fragment>
                                                        <ListItem
                                                            button={true}
                                                            tabIndex={-1}
                                                            // onClick={this.handleToggle('notes')}
                                                        >
                                                            <ListItemText
                                                                // primary={this.sourceSystem.toLowerCase() === 'inprotech' ? 'Narrative' : 'Notes'}
                                                                primary={
                                                                    <TextField
                                                                        label={this.sourceSystem.toLowerCase() === 'inprotech' ? 'Narrative' : 'Notes'}
                                                                        variant="outlined"
                                                                        fullWidth={true}
                                                                        margin="none"
                                                                        value={
                                                                            this.sourceSystem.toLowerCase() === 'inprotech'
                                                                                ? this.state.timeslip!.inprotechNote
                                                                                : this.state.timeslip!.note
                                                                        }
                                                                        onChange={this.onNoteChange}
                                                                        multiline={true}
                                                                        rows={4}
                                                                        tabIndex={this.timeCaptureMode.toLocaleLowerCase() === 'units' ? 4 : 5}
                                                                    />
                                                                }
                                                                // secondary={this.limitText( this.state.timeslip!.note, 300)}
                                                                classes={{
                                                                    primary: classes.primaryListItemTex,
                                                                }}
                                                                tabIndex={-1}
                                                            />
                                                            <ListItemSecondaryAction>
                                                                <IconButton
                                                                    onClick={this.handleToggle(this.sourceSystem.toLowerCase() === 'inprotech' ? 'inprotechNotes' : 'notes')}
                                                                    tabIndex={-1}
                                                                >
                                                                    <ChevronRightIcon />
                                                                </IconButton>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    </React.Fragment>,
                                                    this.state.timeslip!.note,
                                                    300,
                                                )}
                                            </List>
                                            <div className={classes.buttonContainer}>{this.getActionButton(hasPosting)}</div>
                                            {this.state.matter && this.state.matter.financials && (
                                                <>
                                                    {this.state.billingConfirmThresholdText && UserFeature.HasAccess(UserFeatureEnum.hasFeeEstimateWarning) && (
                                                        <div className={classes.warning}>Warning</div>
                                                    )}
                                                    {this.getMatterFinancials(classes, estimatedFeesColour, financials, displayZero, this.localCurrency)}
                                                    {this.state.billingConfirmThresholdText && UserFeature.HasAccess(UserFeatureEnum.hasFeeEstimateWarning) && (
                                                        <div className={classes.thresholdText}>{this.state.billingConfirmThresholdText}</div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        }
                    }}
                </Query>
            </>
        );
    }

    // tslint:disable-next-line: no-any
    private getMatterFinancials(classes: any, estimatedFeesColour: any, financials: any, displayZero: any, localCurrency: any) {
        return (
            <div className={classes.cardWrapper}>
                <Grid container={true} spacing={1}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                        <Grid container={true} spacing={1}>
                            <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                <Paper className={classes.paper}>
                                    <div className="label">{MatterTerminology.EstimatedCosts}</div>
                                    <div className="value" style={{ color: estimatedFeesColour }}>
                                        <FormattedCurrency
                                            // tslint:disable-next-line:max-line-length
                                            value={financials && financials.estimatedCosts ? financials.estimatedCosts : displayZero}
                                            displayStyle={CurrencyDisplayStyle.Integer}
                                            format={localCurrency}
                                            isLoading={this.state.isFinancialsLoading}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                <Paper className={classes.paper}>
                                    <div className="label">{MatterTerminology.CostAgreementReceived}</div>
                                    <div className="value-string">
                                        {financials && financials.costAgreementReceived ? moment(financials.costAgreementReceived).format('d MMM YYYY') : null}
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                        <Grid container={true} spacing={1}>
                            <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                <Paper className={classes.paper}>
                                    <div className="label">{MatterTerminology.Billed + ' ' + MatterTerminology.BilledTimeAndFees}</div>
                                    <div className="value">
                                        <FormattedCurrency
                                            // tslint:disable-next-line:max-line-length
                                            value={financials && financials.billedTimeAndFees ? financials.billedTimeAndFees : displayZero}
                                            displayStyle={CurrencyDisplayStyle.Integer}
                                            format={localCurrency}
                                            isLoading={this.state.isFinancialsLoading}
                                        />
                                    </div>
                                </Paper>
                            </Grid>

                            <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                <Paper className={classes.paper}>
                                    <div className="label">{MatterTerminology.Billed + ' ' + MatterTerminology.Disbursements}</div>
                                    <div className="value">
                                        <FormattedCurrency
                                            // tslint:disable-next-line:max-line-length
                                            value={financials && financials.billedDisbursements ? financials.billedDisbursements : displayZero}
                                            displayStyle={CurrencyDisplayStyle.Integer}
                                            format={localCurrency}
                                            isLoading={this.state.isFinancialsLoading}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                        <Grid container={true} spacing={1}>
                            <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                <Paper className={classes.paper}>
                                    <div className="label">{MatterTerminology.TotalOwing}</div>
                                    <div className="value">
                                        <FormattedCurrency
                                            // tslint:disable-next-line:max-line-length
                                            value={financials && financials.balanceDue ? financials.balanceDue : displayZero}
                                            displayStyle={CurrencyDisplayStyle.Integer}
                                            format={localCurrency}
                                            isLoading={this.state.isFinancialsLoading}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                <Paper className={classes.paper}>
                                    <div className="label">{MatterTerminology.TrustBalance}</div>
                                    <div className="value">
                                        <FormattedCurrency
                                            // tslint:disable-next-line:max-line-length
                                            value={financials && financials.trustGeneralBalance ? financials.trustGeneralBalance : displayZero}
                                            displayStyle={CurrencyDisplayStyle.Integer}
                                            format={localCurrency}
                                            isLoading={this.state.isFinancialsLoading}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item={true} xs={12} sm={12} md={12} lg={12}>
                        <Grid container={true} spacing={1}>
                            <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                <Paper className={classes.paper}>
                                    <div className="label">{MatterTerminology.Unbilled + ' ' + MatterTerminology.UnbilledTimeAndFees}</div>
                                    <div className="value">
                                        <FormattedCurrency
                                            // tslint:disable-next-line:max-line-length
                                            value={financials && financials.unbilledTimeAndFees ? financials.unbilledTimeAndFees : displayZero}
                                            displayStyle={CurrencyDisplayStyle.Integer}
                                            format={localCurrency}
                                            isLoading={this.state.isFinancialsLoading}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item={true} xs={6} sm={6} md={6} lg={6}>
                                <Paper className={classes.paper}>
                                    <div className="label">{MatterTerminology.Unbilled + ' ' + MatterTerminology.Disbursements}</div>
                                    <div className="value">
                                        <FormattedCurrency
                                            // tslint:disable-next-line:max-line-length
                                            value={financials && financials.unbilledDisbursements ? financials.unbilledDisbursements : displayZero}
                                            displayStyle={CurrencyDisplayStyle.Integer}
                                            format={localCurrency}
                                            isLoading={this.state.isFinancialsLoading}
                                        />
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    private isMatterAndActivtyEmpty(): boolean {
        if (!this.state.timeslip) {
            return true;
        }
        if (!(this.state.timeslip.activity && this.state.timeslip.matter)) {
            return true;
        }
        if (this.state.timeslip.activity.hasOwnProperty('value') && !this.state.timeslip.activity.value) {
            return true;
        }
        if (this.state.timeslip.matter.hasOwnProperty('value') && !this.state.timeslip.matter.value) {
            return true;
        }

        return false;
    }
    private getActionButton(hasPosting: boolean) {
        const { classes } = this.props;

        if (this.props.isFromTimer) {
            return (
                <React.Fragment>
                    <div className={classes.timerButtonWrapper}>
                        <Button type="button" onClick={this.onToggleTimer} className="buttonWrapper" style={{ minWidth: '145px' }}>
                            {this.state.selectedTimer!.isRunning ? 'Pause Timer' : 'Start Timer'}
                        </Button>
                        <Button type="button" onClick={this.onSubmitTimerAsDraft} className="buttonWrapper" style={{ minWidth: '145px' }}>
                            Save as Draft
                        </Button>
                        <Button type="button" onClick={this.onSubmitTimer} className="buttonWrapper" style={{ minWidth: '145px' }} disabled={this.isMatterAndActivtyEmpty()}>
                            Save and Submit
                        </Button>
                    </div>

                    <div className={classes.timerButtonWrapper}>
                        <Button type="button" onClick={this.onSaveToTimer} className="buttonWrapper">
                            Save
                        </Button>
                        <Button type="button" onClick={this.closePanelFromTimer} className="buttonWrapper">
                            Close
                        </Button>
                    </div>
                </React.Fragment>
            );
        } else if (this.state.isFromOutlook) {
            return (
                <React.Fragment>
                    <div>
                        <Button type="submit" onClick={this.handleCreateAndClose} className="buttonWrapper">
                            Save
                        </Button>
                        <Button type="submit" onClick={this.handleCreateAndPost} className="buttonWrapper">
                            {hasPosting ? 'Post' : 'Submit'}
                        </Button>
                    </div>
                    <Button type="button" onClick={this.closePanel} className="buttonWrapper">
                        Cancel
                    </Button>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div>
                        <ButtonGroupWrapper orientation="vertical">
                            <Button type="submit" onClick={this.handleCreateAndClose}>
                                Save and Close
                            </Button>
                            <Button type="submit" onClick={this.handleCreateAndNew}>
                                Save and New
                            </Button>
                        </ButtonGroupWrapper>
                        <ButtonGroupWrapper orientation="vertical">
                            <Button type="submit" onClick={this.handleCreateAndCopy}>
                                Save and Copy
                            </Button>
                            <Button type="submit" onClick={this.handleCreateAndPost}>
                                {hasPosting ? 'Save and Post' : 'Save and Submit'}
                            </Button>
                        </ButtonGroupWrapper>
                    </div>
                    <Button type="button" onClick={this.closePanel}>
                        Cancel
                    </Button>
                </React.Fragment>
            );
        }
    }

    private setMatter(matter: string) {
        // tslint:disable-next-line:no-console
        if (matter) {
            var matterObject = JSON.parse(matter);
            if (matterObject && matterObject.title) {
                const timeslip = this.state.timeslip;
                timeslip.matter = { title: matterObject.title, id: matterObject.id };
                this.setState({ timeslip: timeslip });
            }
        }
    }

    private setActivity(activity: string) {
        // tslint:disable-next-line:no-console
        if (activity) {
            if (activity) {
                const tempData = {
                    label: activity,
                    value: activity,
                    stringValue: activity,
                };
                const timeslip = this.state.timeslip;
                timeslip.activity = tempData;
                this.setState({ timeslip: timeslip });
            }
        }
    }

    private setDuration(totalMinutes: number) {
        var timeString = '';
        var hours = Math.floor(totalMinutes / 60);
        var minutes = Math.floor(totalMinutes - hours * 60);
        var seconds = Math.floor((totalMinutes - hours * 60 - minutes) * 60);

        const timeslip = this.state.timeslip;
        timeslip.units = this.getUnitsFromMinutes(totalMinutes );
        if (this.state.settings.tenantTimeCaptureMode === 'Units' || this.state.settings.timeCaptureMode === 'Units' && !this.state.selectedTimer) {
            timeString = timeslip.units + '';
        } else {
            var leading0 = minutes < 10;
            timeString = hours + ':' + (leading0 ? '0' : '') + minutes;
        }

        timeslip.time = { duration: timeString, minutes: minutes, hours: hours, seconds: seconds };
        timeslip.units = this.getUnitsFromMinutes(hours * 60 + minutes + seconds / 60);

        if (!this.props.isFromTimer) {
            var date = timeslip.date ? timeslip.date : new Date();
            var tempHours = 0;
            var tempMinutes = 0;

            var extraHour = 0;

            tempMinutes = date.getMinutes() - timeslip.time.minutes;
            if (tempMinutes < 0) {
                extraHour = 1;
                tempMinutes = 0;
            }

            tempHours = date.getHours() - timeslip.time.hours - extraHour;
            if (tempHours < 0) {
                tempHours = 0;
            }

            timeslip.date = new Date(date.setHours(tempHours, tempMinutes));
        } else {
            // the minutes worked is already calculated from the timer
            timeslip.date = new Date(timeslip.date.setHours(hours, minutes));
        }

        this.setState({ timeslip: timeslip });
    }

    // tslint:disable-next-line: no-any
    private matterListUI(matter: any) {
        const matterTitlePrefix = getMatterTitlePrefix(this.sourceSystem, matter);
        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar
                        style={{
                            backgroundColor: generateColor(matterTitlePrefix),
                        }}
                    >
                        {this.createAbbreviation(matterTitlePrefix)}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={this.listComponent(matter)} />
            </ListItem>
        );
    }

    // tslint:disable-next-line: no-any
    private listComponent(matter: any): React.ReactNode {
        const { classes } = this.props;
        return (
            <div className={classes.customList}>
                <span className="id">{matter.fileNumber}</span>
                <span className="name">{matter.client}</span>
                <span className="category">{`${matter.title}`}</span>
            </div>
        );
    }

    private createAbbreviation(value: string | null) {
        if (value) {
            let values = value.split(' ');

            if (values.length === 1) {
                return value.substr(0, 2);
            } else {
                let abbr = '';

                for (let index = 0; index < 2; index++) {
                    abbr += values[index].substr(0, 1);
                }

                return abbr;
            }
        }
        return;
    }

    // tslint:disable-next-line: no-any
    private primaryList(title?: string, value?: any): React.ReactNode {
        const { classes } = this.props;
        return (
            <div className={classes.primaryList}>
                {title && <span className="col-1">{title}</span>}
                {value && <span className="col-2">{value}</span>}
            </div>
        );
    }

    // tslint:disable-next-line: no-any
    private secondaryList(
        firstValue?: string,
        // tslint:disable-next-line:no-any
        secondValue?: any,
    ): React.ReactNode {
        const { classes } = this.props;
        return (
            <div className={classes.secondaryList}>
                {firstValue && <span className="col-1">{firstValue}</span>}
                {secondValue && <span className="col-2">{secondValue}</span>}
            </div>
        );
    }

    private handleCreateAndNew() {
        this.onSubmit(false, false, false);
    }

    private handleCreateAndCopy() {
        this.onSubmit(false, true, false);
    }

    private handleCreateAndClose() {
        this.onSubmit(true, false, false);
    }

    private handleCreateAndPost() {
        this.onSubmit(true, false, true);
    }

    private handleUpdate() {
        // if (this.props.onUpdate) {
        //   this.props.onUpdate();
        // }
    }

    private closePanelFromTimer() {
        if (this.state.hasChangedTime || this.state.hasChangedField) {
            this.setState({
                showConfirm: true,
            });
        } else {
            if (this.props.onClose) {
                this.props.onClose();
            }
        }
    }

    private closePanel() {
        if (this.state.isFromOutlook) {
            CloseBrowserControl();
        } else {
            if (this.props.onClose) {
                this.props.onClose();
            }
        }
    }

    // tslint:disable-next-line: no-any
    private onMatterData(data: any) {
        const tempData = {
            ...data,
            label: data.fileNumber + ' (' + data.title + ')',
            value: data.fileNumber,
        };

        const timeslip = this.state.timeslip;
        timeslip.matter = tempData;
        this.setState({
            timeslip: timeslip,
            matterDialogOpen: false,
            // isMatterSelected: tempData ? true : false,
            hasChangedField: true,
        });

        if (tempData.id) {
            this.onMatterSelected(tempData.id);
        }
    }

    // tslint:disable-next-line: no-any
    private onTimeData(data: any) {
        const timeslip = this.state.timeslip;
        timeslip.time.hours = data.duration.hour;
        timeslip.time.minutes = data.duration.minute;
        var totalMinutes = data.duration.minute + 60 * data.duration.hour;
        this.setDuration(totalMinutes);
        this.setState({
            // timeslip: tempTimeslip,
            timeDialogOpen: false,
            hasChangedTime: true,
            hasChangedField: true,
        });
        // this.onToggleTimer();
    }

    // tslint:disable-next-line: no-any
    private onStartData(data: any) {
        const timeslip = this.state.timeslip;
        timeslip.date = data;
        this.setState({
            timeslip: timeslip,
            startsDialogOpen: false,
            hasChangedField: true,
        });
    }

    // tslint:disable-next-line: no-any
    private onActivityData(data: any) {
        // tslint:disable-next-line: no-any
        const tempData = {
            ...data,
            label: data.stringValue,
            value: data.stringValue,
        };

        const timeslip = this.state.timeslip;
        timeslip.activity = tempData;
        if (this.state.settings && this.state.settings.copyActivityAsNote && !this.state.noteHasBeenCustomised) {
            timeslip.note = tempData.stringValue;
        }
        this.setState({
            timeslip: timeslip,
            activityDialogOpen: false,
            hasChangedField: true,
        });
    }

    // tslint:disable-next-line: no-any
    private onChargeCodeData(data: any) {
        const timeslip = this.state.timeslip;
        timeslip.chargeCode = data.description;
        timeslip.chargeCodeId = data.id;
        timeslip.chargeRate = data.rate;

        this.setState({
            timeslip: timeslip,
            chargeCodeDialogOpen: false,
            hasChangedField: true,
        });

        // tslint:disable-next-line:no-console
    }

    // tslint:disable-next-line: no-any
    private onNotesData(data: any, isInprotechNote: boolean) {
        const timeslip = this.state.timeslip;
        if (isInprotechNote) {
            timeslip.inprotechNote = data;
            this.setState({
                timeslip: timeslip,
                notesDialogOpen: false,
                hasChangedField: true,
            });
        } else {
            var customised = false;
            if (timeslip.note !== data) {
                customised = true;
            }
            timeslip.note = data;
            this.setState({
                timeslip: timeslip,
                notesDialogOpen: false,
                noteHasBeenCustomised: customised,
                hasChangedField: true,
            });
        }
    }

    private onSubmit(shouldClose: boolean, shouldCopy: boolean, shouldSubmit: boolean) {
        if (this.validateSubmission(shouldSubmit)) {
            var duration = this.state.timeslip.time.hours * 60 + this.state.timeslip.time.minutes + this.state.timeslip.time.seconds / 60;
            var units = this.getUnitsFromMinutes(duration);
            // only update the duration if it s a non draft submission
            if (this.state.shouldSubmit) {
                duration = this.getMinutesFromUnits(units);
            }

            var endTime = this.state.timeslip.date.getHours() * 3600 + this.state.timeslip.date.getMinutes() * 60;
            var startTime = endTime - duration * 60;

            // if the timeslip is outside of a 24 hour period, realign it
            if (startTime <= 0) {
                var difference = startTime;
                startTime = 0;
                endTime = endTime - difference;
            }
            if (!this.props.timeslips) {
                client
                    .mutate({
                        mutation: SubmitTimeslip,
                        variables: {
                            matterId: this.state.timeslip.matter.id,
                            activityString: this.state.timeslip.activity.stringValue,
                            itemDate: this.state.timeslip.date,
                            timeWorkedMinutes: duration,
                            billingNote: this.state.timeslip.note,
                            timeUnits: units,
                            chargeLevelId: this.state.timeslip.chargeCodeId,
                            chargeRate: this.state.timeslip.chargeRate,
                            startTimeSeconds: startTime,
                            shouldSubmit: shouldSubmit,
                            endTimeSeconds: endTime,
                            timeslipNote: this.state.timeslip.inprotechNote,
                            feeEarnerId: this.state.feeEarnerId,
                        },
                    })
                    .then(
                        (results: {
                            data: {
                                addNewTimeslip: {
                                    error: string;
                                    success: boolean;
                                };
                            };
                        }) => {
                            if (!results.data) {
                                showNotification('Timeslip Submission Failed', 'Unknown Error', 'error');
                            } else {
                                if (results.data.addNewTimeslip.error) {
                                    showNotification('Timeslip Submission Failed', results.data.addNewTimeslip.error, 'error');
                                } else {
                                    showNotification(null, 'Timeslip Submitted', 'info');
                                    if (this.props.updateAction) {
                                        var timeWorkedSeconds = this.state.timeslip.time.hours * 3600 + this.state.timeslip.time.minutes * 60;
                                        var timeslip: TimeslipSummaries = {
                                            id: -1,
                                            matterID: this.state.timeslip.matter.id,
                                            fileNumber: this.state.timeslip.matter.fileNumber,
                                            matterCategory: this.state.timeslip.matter.matterCategory,
                                            matterType: this.state.timeslip.matter.matterType,
                                            title: this.state.timeslip.matter.title,
                                            activityID: null,
                                            activityDescription: this.state.timeslip.activity.stringValue,
                                            chargeLevelID: null,
                                            chargeLevelDescription: null,
                                            client: this.state.timeslip.matter.client,
                                            itemDate: this.state.timeslip.date,
                                            startTimeSeconds: null,
                                            endTimeSeconds: null,
                                            timeWorkedSeconds: timeWorkedSeconds,
                                            amount: null,
                                            chargeRate: null,
                                            units: this.state.timeslip.units,
                                            note: this.state.timeslip.note,
                                            isSynced: null,
                                            syncErrorID: null,
                                            syncErrorDescription: null,
                                            isIncomplete: null,
                                            status: shouldSubmit ? 'Pending' : 'Draft',
                                            duration: null,
                                            startTime: null,
                                            endTime: null,
                                            timesheetNote: this.state.timeslip.inprotechNote,
                                        };
                                        this.props.updateAction(timeslip);
                                    }
                                    if (shouldClose) {
                                        if (this.state.isFromOutlook) {
                                            setTimeout(() => CloseBrowserControl(), 1000);
                                        }
                                        if (this.props.onClose) {
                                            this.props.onClose();
                                        }
                                    } else {
                                        fetchTypedUserSettings(
                                            true,
                                            // tslint:disable-next-line:no-console
                                            (data: TypedTenantUserOptions) => {
                                                this.setState({
                                                    settings: data,
                                                    matter: shouldCopy ? this.state.matter : undefined,
                                                    timeslip: {
                                                        matter: shouldCopy ? this.state.timeslip.matter : {},
                                                        activity: shouldCopy ? this.state.timeslip.activity : {},
                                                        chargeCode: '',
                                                        chargeRate: 0,
                                                        chargeCodeId: 0,
                                                        time: {
                                                            duration: '00:00',
                                                            minutes: 0,
                                                            hours: 0,
                                                            seconds: 0,
                                                        },
                                                        units: 0,
                                                        date: this.state.timeslip.date,
                                                        note: '',
                                                        inprotechNote: '',
                                                    },
                                                });
                                                this.setMatter(this.state.settings.defaultMatter);
                                                this.setDuration(this.state.settings.defaultDurationMinutes);
                                            },
                                            (reason) => {
                                                showNotification('Failed to Fetch Layout Configuration', reason, 'error');
                                            },
                                        );
                                    }

                                    if (this.state.isFromOutlook) {
                                        setTimeout(() => CloseBrowserControl(), 1000);
                                    }
                                }
                            }
                        },
                    );
            } else {
                client
                    .mutate({
                        mutation: UpdateTimeslip,
                        variables: {
                            matterId: this.state.timeslip.matter.id,
                            activityString: this.state.timeslip.activity.stringValue,
                            itemDate: this.state.timeslip.date,
                            timeWorkedMinutes: duration,
                            billingNote: this.state.timeslip.note,
                            timeUnits: units,
                            chargeLevelId: this.state.timeslip.chargeCodeId,
                            chargeRate: this.state.timeslip.chargeRate,
                            startTimeSeconds: startTime,
                            shouldSubmit: shouldSubmit,
                            endTimeSeconds: endTime,
                            timeslipId: this.state.timeslip.id,
                            timeslipNote: this.state.timeslip.inprotechNote,
                            feeEarnerId: this.state.feeEarnerId,
                        },
                    })
                    .then(
                        (results: {
                            data: {
                                updateTimeslip: {
                                    error: string;
                                    success: boolean;
                                };
                            };
                        }) => {
                            if (!results.data) {
                                showNotification('Timeslip Submission Failed', 'Unknown Error', 'error');
                            } else {
                                if (results.data.updateTimeslip.error) {
                                    showNotification('Timeslip Submission Failed', results.data.updateTimeslip.error, 'error');
                                } else {
                                    showNotification(null, 'Timeslip Submitted', 'info');
                                    if (this.props.updateAction) {
                                        var timeWorkedSeconds = this.state.timeslip.time.hours * 3600 + this.state.timeslip.time.minutes * 60;
                                        var timeslip: TimeslipSummaries = {
                                            id: -1,
                                            matterID: this.state.timeslip.matter.id,
                                            fileNumber: this.state.timeslip.matter.fileNumber,
                                            matterCategory: this.state.timeslip.matter.matterCategory,
                                            matterType: this.state.timeslip.matter.matterType,
                                            title: this.state.timeslip.matter.title,
                                            activityID: null,
                                            activityDescription: this.state.timeslip.activity.stringValue,
                                            chargeLevelID: null,
                                            chargeLevelDescription: null,
                                            client: this.state.timeslip.matter.client,
                                            itemDate: this.state.timeslip.date,
                                            startTimeSeconds: null,
                                            endTimeSeconds: null,
                                            timeWorkedSeconds: timeWorkedSeconds,
                                            amount: null,
                                            chargeRate: null,
                                            units: this.state.timeslip.units,
                                            note: this.state.timeslip.note,
                                            isSynced: null,
                                            syncErrorID: null,
                                            syncErrorDescription: null,
                                            isIncomplete: null,
                                            status: shouldSubmit ? 'Pending' : 'Draft',
                                            duration: null,
                                            startTime: null,
                                            endTime: null,
                                            timesheetNote: this.state.timeslip.inprotechNote,
                                        };
                                        this.props.updateAction(timeslip);
                                        if (shouldClose) {
                                            if (this.state.isFromOutlook) {
                                                // tslint:disable-next-line: align
                                                setTimeout(() => CloseBrowserControl(), 1000);
                                            }
                                            if (this.props.onClose) {
                                                this.props.onClose();
                                            }
                                        } else {
                                            fetchTypedUserSettings(
                                                true,
                                                // tslint:disable-next-line:no-console
                                                (data: TypedTenantUserOptions) => {
                                                    this.setState({
                                                        settings: data,
                                                        matter: shouldCopy ? this.state.matter : undefined,
                                                        timeslip: {
                                                            matter: shouldCopy ? this.state.timeslip.matter : {},
                                                            activity: shouldCopy ? this.state.timeslip.activity : {},
                                                            chargeCode: '',
                                                            chargeRate: 0,
                                                            chargeCodeId: 0,
                                                            time: {
                                                                duration: '00:00',
                                                                minutes: 0,
                                                                hours: 0,
                                                                seconds: 0,
                                                            },
                                                            units: 0,
                                                            date: this.state.timeslip.date,
                                                            note: '',
                                                            inprotechNote: '',
                                                        },
                                                    });
                                                    this.setMatter(this.state.settings.defaultMatter);
                                                    this.setDuration(this.state.settings.defaultDurationMinutes);
                                                },
                                                (reason) => {
                                                    showNotification('Failed to Fetch Layout Configuration', reason, 'error');
                                                },
                                            );
                                        }
                                    }
                                    if (this.state.isFromOutlook) {
                                        setTimeout(() => CloseBrowserControl(), 1000);
                                    }
                                }
                            }
                        },
                    );
                for (var timesheetId of this.state.timeslipsToRemove) {
                    client.mutate({
                        mutation: DeleteTimeslip,
                        variables: {
                            timeslipId: timesheetId,
                            feeEarnerId: this.state.feeEarnerId,
                        },
                    });
                }
                if (this.props.mergeAction) {
                    this.props.mergeAction();
                }
            }
        }
    }

    private convertToMinutes(timeString: string): number {
        var minutes = 0;
        var items = timeString.split(':');

        minutes += parseInt(items[0], 10) * 60 + parseInt(items[1], 10);

        return minutes;
    }
    private validateSubmission(shouldSubmit: boolean): boolean {
        if (!this.state.timeslip) {
            showNotification('Timeslip Submission Failed', 'Timeslip must be filled', 'error');
            return false;
        }
        if (!this.state.timeslip.time || this.convertToMinutes(this.state.timeslip.time.duration) === 0) {
            showNotification('Timeslip Submission Failed', 'Cannot have a timeslip for zero minutes', 'error');
            return false;
        }
        if (!this.state.timeslip.matter.id && shouldSubmit) {
            showNotification('Timeslip Submission Failed', 'Please choose a matter', 'error');
            return false;
        }
        if (!this.state.timeslip.activity.stringValue && shouldSubmit) {
            showNotification('Timeslip Submission Failed', 'Please choose an activity', 'error');
            return false;
        }
        return true;
    }

    private onCloseTime() {
        this.setState({
            timeDialogOpen: false,
        });
    }

    private onCloseMatter() {
        this.setState({
            matterDialogOpen: false,
        });
    }

    private onCloseStart() {
        this.setState({
            startsDialogOpen: false,
        });
    }

    private onCloseActivity() {
        this.setState({
            activityDialogOpen: false,
        });
    }

    private onCloseChargeCode() {
        this.setState({
            chargeCodeDialogOpen: false,
        });
    }

    private onCloseNotes() {
        this.setState({
            notesDialogOpen: false,
        });
    }

    private limitText(value: string, limit: number) {
        if (value.length > limit) {
            return value.substring(0, limit) + '...';
        } else {
            return value;
        }
    }

    private onLongText(content: React.ReactNode, value: string, limit: number) {
        const calloutProps = { gapSpace: 0 };

        if (value && value.length > limit) {
            return (
                <TooltipHost
                    content={this.state.timeslip!.note}
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown)
                    id={'notes'}
                    calloutProps={calloutProps}
                >
                    {content}
                </TooltipHost>
            );
        }

        return content;
    }

    private handleToggle = (value: string) => () => {
        switch (value) {
            case 'time': {
                if (this.state.selectedTimer) {
                    var timer = this.state.selectedTimer;
                    timer.isRunning = false;
                    this.setState({
                        timeDialogOpen: true,
                        matterDialogOpen: false,
                        startsDialogOpen: false,
                        activityDialogOpen: false,
                        notesDialogOpen: false,
                        chargeCodeDialogOpen: false,
                        selectedTimer: timer,
                    });
                } else {
                    this.setState({
                        timeDialogOpen: true,
                        matterDialogOpen: false,
                        startsDialogOpen: false,
                        activityDialogOpen: false,
                        notesDialogOpen: false,
                        chargeCodeDialogOpen: false,
                    });
                }
                break;
            }
            case 'matter': {
                this.setState({
                    timeDialogOpen: false,
                    matterDialogOpen: true,
                    startsDialogOpen: false,
                    activityDialogOpen: false,
                    notesDialogOpen: false,
                    chargeCodeDialogOpen: false,
                });
                break;
            }
            case 'starts': {
                this.setState({
                    timeDialogOpen: false,
                    matterDialogOpen: false,
                    startsDialogOpen: true,
                    activityDialogOpen: false,
                    notesDialogOpen: false,
                    chargeCodeDialogOpen: false,
                });
                break;
            }
            case 'activity': {
                this.setState({
                    timeDialogOpen: false,
                    matterDialogOpen: false,
                    startsDialogOpen: false,
                    activityDialogOpen: true,
                    notesDialogOpen: false,
                    chargeCodeDialogOpen: false,
                });
                break;
            }
            case 'notes': {
                this.setState({
                    timeDialogOpen: false,
                    matterDialogOpen: false,
                    startsDialogOpen: false,
                    activityDialogOpen: false,
                    notesDialogOpen: true,
                    chargeCodeDialogOpen: false,
                    inInprotechNotes: false,
                });
                break;
            }
            case 'inprotechNotes': {
                this.setState({
                    timeDialogOpen: false,
                    matterDialogOpen: false,
                    startsDialogOpen: false,
                    activityDialogOpen: false,
                    notesDialogOpen: true,
                    chargeCodeDialogOpen: false,
                    inInprotechNotes: true,
                });
                break;
            }
            case 'chargeCode': {
                this.setState({
                    timeDialogOpen: false,
                    matterDialogOpen: false,
                    startsDialogOpen: false,
                    activityDialogOpen: false,
                    notesDialogOpen: false,
                    chargeCodeDialogOpen: true,
                });
                break;
            }
            default: {
                break;
            }
        }
    }

    private handleCheckBoxChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            shouldSubmit: !event.target.checked,
        });
    }

    private onMatterSelected(matterId: number) {
        let vm = this;
        var matterFinancialsQueryParams: MatterFinancialsQueryParams = {
            matterId: matterId,
        };

        retrieveMatterFinancials(
            matterFinancialsQueryParams,
            false,
            (data) => this.onMatterFinancialsRetrieved(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
                vm.setState({
                    isFinancialsLoading: false,
                });
            },
        );
    }

    private onMatterFinancialsRetrieved(data: MatterData) {
        if (data.matter) {
            if (data.matter.financials && this.threshold !== 0 ) {
                let billed = data.matter.financials?.billedTimeAndFees;
                let unbilled = data.matter.financials?.unbilledTimeAndFees;
                let estimate = data.matter.financials?.estimatedCosts;
                let threshold = this.threshold / 100;
                if (estimate > 0 && (billed + unbilled) > threshold * estimate) {
                    const text = `Time & Fees of ${this.currencySymbol}${formatNumber((billed + unbilled))} has surpassed ${formatNumber(threshold * 100)}% of ${
                        this.currencySymbol
                    }${formatNumber(estimate)} estimated fees`;

                    this.setState({
                        showConfirmBillingThreshold: true,
                        billingConfirmThresholdText: text,
                    });
                } else {
                    this.setState({
                        billingConfirmThresholdText: ''
                    });
                }
            }

            this.setState({
                isFinancialsLoading: false,
                matter: data.matter,
                // matterFinancials: data.matter
            });
        }
    }

    private onChangeUnit(event: React.ChangeEvent<HTMLInputElement>) {
        if (Number.isNaN(+event.target.value)) {
            return;
        }
        var unitsValue = +event.target.value;

        this.setDuration(this.getMinutesFromUnits(unitsValue));
        this.setState({ hasChangedTime: true });
        // this.onToggleTimer();
    }

    private formatNumberBy0(value: number) {
        const newValue = Math.round(value);
        if (newValue < 10) {
            return '0' + newValue;
            // return ('0' + value).slice(-2);
        }

        return newValue;
    }

    private onChangeDuration = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (Number.isNaN(+event.target.value)) {
            return;
        }

        const minHours = 0;
        const maxHours = 23;
        const minMinutes = 0;
        const maxMinutes = 59;

        if (field === 'minute') {
            if (event.target.value === '' || (Number(event.target.value) >= minMinutes && Number(event.target.value) <= maxMinutes)) {
                var minutes = +event.target.value;
                this.setDuration(this.state.timeslip.time.hours * 60 + minutes);
            }
        }

        if (field === 'hour') {
            if (event.target.value === '' || (Number(event.target.value) >= minHours && Number(event.target.value) <= maxHours)) {
                var hours = +event.target.value;
                this.setDuration(hours * 60 + this.state.timeslip.time.minutes);
            }
        }

        // if the duration is manually changed, then we stop the timer.
        const selectedTimer = this.state.selectedTimer;
        if (selectedTimer) {
            selectedTimer.isRunning = false;
        }

        this.setState({
            hasChangedTime: true,
            selectedTimer: selectedTimer,
        });
        // this.onToggleTimer();
        // code here
    }

    private onMatterLookupChange = (value: MatterLookUpItem, name: string) => {
        // check if selector has value
        const timeslip = this.state.timeslip;

        timeslip.matter = value;
        this.setState({
            timeslip: timeslip,
            // isMatterSelected: value ? true : false,
            hasChangedField: true,
        });

        if (value) {
            this.onMatterSelected(value.id);
        }
    }

    private onActivityChange = (value: ActivityItem, name: string) => {
        const timeslip = this.state.timeslip;
        timeslip.activity = value;
        this.setState({
            timeslip: timeslip,
            hasChangedField: true,
        });
    }

    private onNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const timeslip = this.state.timeslip;
        const tempValue = event.target.value;
        if (this.state.inInprotechNotes) {
            timeslip.inprotechNote = tempValue;
            this.setState({
                timeslip: timeslip,
                hasChangedField: true,
            });
        } else {
            var customised = false;
            if (timeslip.note !== tempValue) {
                customised = true;
            }
            timeslip.note = tempValue;
            this.setState({
                timeslip: timeslip,
                noteHasBeenCustomised: customised,
                hasChangedField: true,
            });
        }
    }

    private onSaveToTimer() {
        if (this.props.updateTimerAction) {
            var timeWorkedSeconds = this.state.timeslip.time.hours * 60 * 60 + this.state.timeslip.time.minutes * 60 + this.state.timeslip.time.seconds;
            var isRunning = false;
            var timerGuid = null;
            var lastStarted = null;

            if (this.state.selectedTimer) {
                isRunning = this.state.selectedTimer.isRunning;
                lastStarted = this.state.selectedTimer.lastStarted;
                timerGuid = this.state.selectedTimer?.timerGuid;
            }

            var timer: TimerSummaryElement = {
                activityDescription: this.state.timeslip.activity ? this.state.timeslip.activity.label : null,
                activityId: this.state.timeslip.activity ? this.state.timeslip.activity.id : null,
                chargeRate: this.state.timeslip.chargeRate ?? null,
                chargeRateId: this.state.timeslip.chargeCodeId ?? null,
                billingNote: this.state.timeslip.note ?? null,
                client: this.state.timeslip.matter ? this.state.timeslip.matter.client : null,
                fileNumber: this.state.timeslip.matter ? this.state.timeslip.matter.fileNumber : null,
                matterCategory: this.state.timeslip.matter ? this.state.timeslip.matter.matterCategory : null,
                matterId: this.state.timeslip.matter ? this.state.timeslip.matter.id : null,
                matterType: this.state.timeslip.matter ? this.state.timeslip.matter.matterType : null,
                timesheetNote: this.state.timeslip.inprotechNote ?? null,
                title: this.state.timeslip.matter ? this.state.timeslip.matter.title : null,
                isRunning: this.state.hasChangedTime ? false : isRunning,
                timeWorkedSeconds: this.state.hasChangedTime ? timeWorkedSeconds : null,
                timeWorkedSecondsTemp: timeWorkedSeconds,
                timerGuid: timerGuid,
                lastStarted: lastStarted,
                inError: false,
                errorMessage: null,
                units: this.state.timeslip.units,
            };

            this.props.updateTimerAction(
                timer,
                this.state.selectedTimer && this.state.selectedTimer.timerGuid ? this.state.selectedTimer.timerGuid : undefined,
                this.props.newTimer,
            );
        }
    }

    private onToggleTimer() {
        if (this.props.onToggleTimer) {
            var timeWorkedSeconds = this.state.timeslip.time.hours * 60 * 60 + this.state.timeslip.time.minutes * 60 + this.state.timeslip.time.seconds ;
            var isRunning = false;
            var timerGuid = null;
            var lastStarted = null;

            if (this.state.selectedTimer) {
                isRunning = this.state.selectedTimer.isRunning;
                lastStarted = this.state.selectedTimer.lastStarted;
                timerGuid = this.state.selectedTimer?.timerGuid;
            }

            var timer: TimerSummaryElement = {
                activityDescription: this.state.timeslip.activity.label,
                activityId: this.state.timeslip.activity.id,
                chargeRate: this.state.timeslip.chargeRate,
                chargeRateId: this.state.timeslip.chargeCodeId,
                billingNote: this.state.timeslip.note,
                client: this.state.timeslip.matter.client,
                fileNumber: this.state.timeslip.matter.fileNumber,
                matterCategory: this.state.timeslip.matter.matterCategory,
                matterId: this.state.timeslip.matter.id,
                matterType: this.state.timeslip.matter.matterType,
                timesheetNote: this.state.timeslip.inprotechNote,
                title: this.state.timeslip.matter.title,
                isRunning: this.state.hasChangedTime ? false : isRunning,
                timeWorkedSeconds: this.state.hasChangedTime ? timeWorkedSeconds : null,
                timeWorkedSecondsTemp: timeWorkedSeconds,
                timerGuid: timerGuid,
                lastStarted: lastStarted,
                inError: false,
                errorMessage: null,
                units: this.state.timeslip.units,
            };

            this.props.onToggleTimer(timer);
        }
    }
    private onSubmitTimerAsDraft() {
        if (this.props.onSubmitTimerAsDraft) {
            var timeWorkedSeconds = this.state.timeslip.time.hours * 60 * 60 + this.state.timeslip.time.minutes * 60 + this.state.timeslip.time.seconds;
            var isRunning = false;
            var timerGuid = null;
            var lastStarted = null;

            if (this.state.selectedTimer) {
                isRunning = this.state.selectedTimer.isRunning;
                lastStarted = this.state.selectedTimer.lastStarted;
                timerGuid = this.state.selectedTimer?.timerGuid;
            }

            var timer: TimerSummaryElement = {
                activityDescription: this.state.timeslip.activity.label,
                activityId: this.state.timeslip.activity.id,
                chargeRate: this.state.timeslip.chargeRate,
                chargeRateId: this.state.timeslip.chargeCodeId,
                billingNote: this.state.timeslip.note,
                client: this.state.timeslip.matter.client,
                fileNumber: this.state.timeslip.matter.fileNumber,
                matterCategory: this.state.timeslip.matter.matterCategory,
                matterId: this.state.timeslip.matter.id,
                matterType: this.state.timeslip.matter.matterType,
                timesheetNote: this.state.timeslip.inprotechNote,
                title: this.state.timeslip.matter.title,
                isRunning: this.state.hasChangedTime ? false : isRunning,
                timeWorkedSeconds: timeWorkedSeconds,
                timeWorkedSecondsTemp: timeWorkedSeconds,
                timerGuid: timerGuid,
                lastStarted: lastStarted,
                inError: false,
                errorMessage: null,
                units: this.state.timeslip.units,
            };

            this.props.onSubmitTimerAsDraft(timer);
        }
    }
    private onSubmitTimer() {
        if (this.props.onSubmitTimer && this.validateSubmission(true)) {
            var timeWorkedSeconds = this.state.timeslip.time.hours * 60 * 60 + this.state.timeslip.time.minutes * 60 + this.state.timeslip.time.seconds;

            var isRunning = false;
            var timerGuid = null;
            var lastStarted = null;

            if (this.state.selectedTimer) {
                isRunning = this.state.selectedTimer.isRunning;
                lastStarted = this.state.selectedTimer.lastStarted;
                timerGuid = this.state.selectedTimer?.timerGuid;
            }

            var timer: TimerSummaryElement = {
                activityDescription: this.state.timeslip.activity.label,
                activityId: this.state.timeslip.activity.id,
                chargeRate: this.state.timeslip.chargeRate,
                chargeRateId: this.state.timeslip.chargeCodeId,
                billingNote: this.state.timeslip.note,
                client: this.state.timeslip.matter.client,
                fileNumber: this.state.timeslip.matter.fileNumber,
                matterCategory: this.state.timeslip.matter.matterCategory,
                matterId: this.state.timeslip.matter.id,
                matterType: this.state.timeslip.matter.matterType,
                timesheetNote: this.state.timeslip.inprotechNote,
                title: this.state.timeslip.matter.title,
                isRunning: this.state.hasChangedTime ? false : isRunning,
                timeWorkedSeconds: timeWorkedSeconds,
                timeWorkedSecondsTemp: timeWorkedSeconds,
                timerGuid: timerGuid,
                lastStarted: lastStarted,
                inError: false,
                errorMessage: null,
                units: this.state.timeslip.units,
            };
            this.props.onSubmitTimer(timer);
        }
    }

    private updateRunningTime() {
        if (this.state.selectedTimer?.isRunning === true) {
            const { hours, minutes, seconds } = this.state.timeslip.time;

            let updatedSeconds = seconds + 1;
            let updatedMinutes = minutes;
            let updatedHours = hours;

            if (updatedSeconds === 60) {
                updatedMinutes += 1;
                updatedSeconds = 0;
            }

            if (updatedMinutes === 60) {
                updatedHours += 1;
                updatedMinutes = 0;
            }

            this.setState({
                timeslip: {
                    ...this.state.timeslip,
                    time: {
                        ...this.state.timeslip.time,
                        hours: updatedHours,
                        minutes: updatedMinutes,
                        seconds: updatedSeconds,
                    },
                },
            });
        }
    }

    private onDeleteConfirm(agree: boolean) {
        if (agree && this.props.onClose) {
            this.props.onClose();
        }
        this.setState({
            showConfirm: false,
        });
    }

    private onShowThresholdConfirm(agree: boolean) {
        this.setState({
            showConfirmBillingThreshold: false,
        });
    }
}

const SubmitTimeslip = gql`
    mutation SubmitTimeslip(
        $matterId: Int
        $activityString: String
        $itemDate: DateTime!
        $timeWorkedMinutes: Int!
        $billingNote: String
        $timeUnits: Int!
        $startTimeSeconds: Int!
        $endTimeSeconds: Int!
        $chargeLevelId: Int
        $chargeRate: Decimal
        $shouldSubmit: Boolean!
        $timeslipNote: String
        $feeEarnerId: String
    ) {
        addNewTimeslip(
            matterId: $matterId
            activityString: $activityString
            itemDate: $itemDate
            activityId: -1
            timeWorkedMinutes: $timeWorkedMinutes
            timeUnits: $timeUnits
            billingNote: $billingNote
            startTimeSeconds: $startTimeSeconds
            endTimeSeconds: $endTimeSeconds
            shouldSubmit: $shouldSubmit
            source: "ConnectWeb"
            chargeLevelId: $chargeLevelId
            chargeRate: $chargeRate
            shouldPost: $shouldSubmit
            timeslipNote: $timeslipNote
            feeEarnerId: $feeEarnerId
        ) {
            error
            success
        }
    }
`;

const UpdateTimeslip = gql`
    mutation UpdateTimeslip(
        $matterId: Int
        $activityString: String
        $itemDate: DateTime!
        $timeWorkedMinutes: Int!
        $billingNote: String
        $timeUnits: Int!
        $startTimeSeconds: Int!
        $endTimeSeconds: Int!
        $chargeLevelId: Int
        $chargeRate: Decimal
        $shouldSubmit: Boolean!
        $timeslipId: Int!
        $timeslipNote: String
        $feeEarnerId: String
    ) {
        updateTimeslip(
            matterId: $matterId
            activityString: $activityString
            itemDate: $itemDate
            activityId: -1
            timeWorkedMinutes: $timeWorkedMinutes
            timeUnits: $timeUnits
            billingNote: $billingNote
            startTimeSeconds: $startTimeSeconds
            endTimeSeconds: $endTimeSeconds
            shouldSubmit: $shouldSubmit
            source: "ConnectWeb"
            chargeLevelId: $chargeLevelId
            chargeRate: $chargeRate
            timesheetId: $timeslipId
            shouldPost: $shouldSubmit
            timeslipNote: $timeslipNote
            feeEarnerId: $feeEarnerId
        ) {
            error
            success
        }
    }
`;

const DeleteTimeslip = gql`
    mutation DeleteTimeslip($timeslipId: Int!, $feeEarnerId: String) {
        deleteTimeslip(timesheetId: $timeslipId, feeEarnerId: $feeEarnerId) {
            error
            success
        }
    }
`;

const TenantData = gql`
    query Tenant {
        tenant {
            sourceSystem
            tenantId
        }
        options {
            localCurrency {
                currency
            }
            currencySymbol {
                symbol
            }
        }
        settings {
            id
            systems {
                timeRecordingDefaults {
                    id
                    config {
                        id
                        timeCaptureMode
                    }
                }
                timeRecordingSettings {
                    id
                    config {
                        id
                        thresholdLevel
                    }
                }
            }
        }
    }
`;

// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(
    // tslint:disable-next-line: no-any
    TimeslipPage as any,
    // tslint:disable-next-line: no-any
) as any;
