import * as React from 'react';
import {
    withStyles,
    WithStyles,
    createStyles,
    Theme,
    Paper,
    Tabs,
    Tab,
    Button,
    Input,
    InputLabel,
    FormControl
} from '@material-ui/core';
// import Button from '@material-ui/core/Button';
// import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
// import MaskedInput from 'react-text-mask';
// import * as moment from 'moment';
import 'moment-duration-format';
import { TypedTenantUserOptions } from '../../userSettings/UserSettingsRepository';
// import { TimePicker } from '@material-ui/pickers';
// import TimeKeeper from 'react-timekeeper';

export const styles = (theme: Theme) => createStyles({
    appBar: {
        position: 'relative',
        '& > div': {
            padding: 0,
        }
    },
    title: {
        // marginLeft: theme.spacing(2),
        flex: 1,
        textAlign: 'center',
        flexGrow: 1,
        fontSize: '21px',
        fontWeight: 100,
        color: '#333333',
        lineHeight: '32px',
        margin: 0,
        fontFamily: 'Segoe UI'
    },
    dialog: {
        '& > div': {
            boxShadow: 'none',
        }
    },
    content: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column wrap'
    },
    tab: {
        flexGrow: 1,
        maxWidth: 'none',
        flexBasis: 0,
        flexShrink: 1,
    },
    timeContainer: {
        textAlign: 'center',
        padding: '30px 0px',
        fontSize: '24px',
        '& .minutes': {
            paddingBottom: '10px',
        },
        '& .units': {

        }
    },
    tabContainer: {
        // margin: '0 30px',
        // boxShadow: 'none',
        // border: '1px solid red',
    },
    tabContent: {
        padding: '15px 0',
        flex: 1,
        display: 'flex',
        flexFlow: 'column wrap'
    },
    durationContainer: {
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        flexFlow: 'column wrap',
        maxHeight: '300px',
        '& .inputContainer': {
            display: 'flex',
            maxWidth: '150px',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '& .duration': {
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '36px',
            '& .input': {
                border: 0,
                width: '100%',
                '&:before': {
                    border: 0,
                },
                '& input': {
                    textAlign: 'center',
                    fontSize: '36px',
                    width: '100%',
                }
            },
        },
        '& .buttonContainer': {
            display: 'flex',
            flexFlow: 'row wrap',
            width: '100%',
            justifyContent: 'space-between',
            margin: '0 -10px',
        }
    },
    unitContainer: {
        flex: 1,
        '& .fieldcontainer': {
            display: 'flex',
            width: '100%',
        }
    },
    button: {
        margin: '5px',
    },
    timeButton: {
        margin: '5px 10px'
    },
    formControl: {
        margin: '16px',
        flex: 1,
    }
});

enum TabName {
    duration = 0,
    unit = 1,
}

interface DialogProps extends WithStyles<typeof styles> {
    onClose: () => void;
    // tslint:disable-next-line: no-any
    onData: (data: any) => void;
    minutesInUnit: number;
    hours: number;
    minutes: number;
    seconds: number;
    settings: TypedTenantUserOptions;
}

interface DialogStates {
    // tslint:disable-next-line:no-any
    tab: number;
    unit: number;
    minutesInUnit: number;
    duration: {
        hour: number;
        minute: number;
        second: number;
    };
    selectedDate?: Date;
    shouldShowTabs: boolean;
    noChange: boolean;
}

// interface TextMaskCustomProps {
//     inputRef: (ref: HTMLInputElement | null) => void;
// }

class TimeslipTimePage extends React.Component<DialogProps, DialogStates> {

    constructor(props: DialogProps) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.onClickButtonTime = this.onClickButtonTime.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.changeDate = this.changeDate.bind(this);
        // this.handleDateChange = this.handleDateChange.bind(this);
        this.formatNumberBy0 = this.formatNumberBy0.bind(this);
        this.timeConvertToWords = this.timeConvertToWords.bind(this);
        this.getUnits = this.getUnits.bind(this);
        // this.getTimeConverted = this.getTimeConverted.bind(this);
        // this.buttonTimeComponent = this.buttonTimeComponent.bind(this);'
        var currentTab = 0;
        var shouldShowTabs = true;
        if (this.props.settings) {
            shouldShowTabs = this.props.settings.tenantTimeCaptureMode === 'Both';
            currentTab = this.props.settings.timeCaptureMode === 'Duration' ? 0 : 1;
            currentTab = this.props.settings.tenantTimeCaptureMode === 'Duration' ? 0 : currentTab;
            currentTab = this.props.settings.tenantTimeCaptureMode === 'Units' ? 1 : currentTab;
        }
        this.state = {
            tab: currentTab,
            unit: Math.ceil((this.props.hours * 60 + this.props.minutes + this.props.seconds / 60)  / this.props.minutesInUnit),
            duration: {
                hour: this.props.hours,
                minute: this.props.minutes,
                second: this.props.seconds
            },
            minutesInUnit: this.props.minutesInUnit,
            shouldShowTabs: shouldShowTabs,
            noChange: true
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: DialogProps, nextContext: DialogStates): void {
        if (this.props.settings) {
            var shouldShowTabs = this.props.settings.tenantTimeCaptureMode === 'Both';
            var currentTab = this.props.settings.timeCaptureMode === 'Duration' ? 0 : 1;
            currentTab = this.props.settings.tenantTimeCaptureMode === 'Duration' ? 0 : currentTab;
            currentTab = this.props.settings.tenantTimeCaptureMode === 'Units' ? 1 : currentTab;
    
            this.setState( {
                tab: currentTab,
                unit: Math.floor((this.props.hours * 60 + this.props.minutes) / this.props.minutesInUnit),
                duration: {
                    hour: this.props.hours,
                    minute: this.props.minutes,
                    second: this.props.seconds
                },
                minutesInUnit: this.props.minutesInUnit,
                shouldShowTabs: shouldShowTabs
            });
        }

    }

    buttonTimeComponent(minutesInUnit: number, duration: {hour: number, minute: number}) {
        const { classes } = this.props;
        let buttons = [];
        let amountOfButtons = 60 / minutesInUnit;
        for (var i = 1; i <= amountOfButtons; i++) {
            if (this.state.minutesInUnit * i >= 60) {
                buttons.push(
                    <React.Fragment key={i}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.timeButton}
                            onClick={this.onClickButtonTime(this.state.duration, i)}
                        >
                            {`${this.formatNumberBy0(duration.hour + 1)}:${this.formatNumberBy0(this.state.minutesInUnit * i - 60)}`}
                        </Button>
                    </React.Fragment>
                );
            } else {
                buttons.push(
                    <React.Fragment key={i}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            className={classes.timeButton}
                            onClick={this.onClickButtonTime(this.state.duration, i)}
                        >
                            {`${this.formatNumberBy0(duration.hour)}:${this.formatNumberBy0(this.state.minutesInUnit * i)}`}
                        </Button>
                    </React.Fragment>
                );  
            }

        }
        return buttons;
    }

    durationComponent() {
        const { classes } = this.props;
        
        return (
            <div className={classes.durationContainer}>
                <div className="duration">
                    {/* <TimePicker
                        autoOk={true}
                        variant="static"
                        openTo="hours"
                        value={this.state.selectedDate}
                        onChange={this.changeDate}
                    /> */}
                    <div className="inputContainer">
                        <Input
                            value={this.formatNumberBy0(this.state.duration.hour)}
                            onChange={this.handleTextChange('hour')}
                            className="input"
                        // tslint:disable-next-line: no-any
                        // inputComponent={TextMaskCustom as any}
                        // type="time"
                        />
                        <div>:</div>
                        <Input
                            value={this.formatNumberBy0(this.state.duration.minute)}
                            onChange={this.handleTextChange('minute')}
                            className="input"
                        // tslint:disable-next-line: no-any
                        // inputComponent={TextMaskCustom as any}
                        // type="time"
                        />
                    </div>
                </div>
                <div className="buttonContainer">
                    {this.buttonTimeComponent(this.state.minutesInUnit, this.state.duration)}
                </div>
            </div>
        );
    }

    unitComponent() {
        const { classes } = this.props;
        return (
            <div className={classes.unitContainer}>
                <div className="fieldcontainer">
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="component-simple">Unit</InputLabel>
                        <Input
                            value={this.state.unit}
                            onChange={this.handleTextChange('unit')}
                            type="number"
                        />
                    </FormControl>
                </div>
            </div>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <div className={classes.appBar} color="default">
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography className={classes.title}>
                            Select Time
                    </Typography>
                        <IconButton
                            color="inherit"
                            onClick={this.handleSave}
                            aria-label="Save"
                            disabled={this.state.noChange}
                        >
                            <CheckIcon />
                        </IconButton>
                    </Toolbar>
                </div>
                <div className={classes.content}>
                    <div className={classes.timeContainer}>
                        {/* <div>
                            <TimeKeeper 
                                hour24Mode={true}
                            />
                        </div> */}
                        <div className="minutes">
                            {this.timeConvertToWords(this.state.duration)}
                        </div>
                        {this.state.tab === TabName.unit && (
                            <div className="units">
                                {`${this.state.unit} ${this.state.unit !== 1 ? 'units' : 'unit'}`}
                        </div>
                        )}
                    </div>
                    {this.state.shouldShowTabs ?
                    <Paper square={true} className={classes.tabContainer}>
                        <Tabs
                            value={this.state.tab}
                            indicatorColor="secondary"
                            textColor="secondary"
                            onChange={this.handleTabChange}
                        >
                            <Tab className={classes.tab} label="Duration" />
                            <Tab className={classes.tab} label="Units" />
                        </Tabs>
                    </Paper> :
                    <div/>}
                    <div className={classes.tabContent}>
                        {this.state.tab === 0 && this.durationComponent()}
                        {this.state.tab === 1 && this.unitComponent()}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private handleClose() {
        if (this.props.onClose) {
            return this.props.onClose();
        }
    }

    private handleSave() {
        if (this.props.onData) {
            this.props.onData({
                duration: { hour: this.state.duration.hour, minute: this.state.duration.minute},
                // unit: this.state.unit,
            });
        }
    }

    private handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ tab: newValue });
    }

    private handleTextChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        // tslint:disable-next-line: radix
        const value = event.target.value;
        // tslint:disable-next-line: no-console
        console.log(value);
        const { duration } = this.state;
        switch (field) {
            case 'unit': {
                // tslint:disable-next-line: radix
                var units = value ? parseInt(value) : 0;
                duration.minute = (this.props.minutesInUnit * units) % 60;
                duration.hour = Math.floor(this.props.minutesInUnit * units / 60);
                duration.second = 0;
                this.setState({ unit:  units, duration: duration, noChange: false});
                break;
            }
            case 'hour': {
                // tslint:disable-next-line: radix
                duration.hour = parseInt(value.replace(/\D/g, ''));
                this.setState({ duration: duration, unit: this.getUnits(duration.hour, duration.minute, duration.second), noChange: false });
                break;
            }
            case 'minute': {
                // tslint:disable-next-line: radix
                duration.minute = parseInt(value.replace(/\D/g, ''));
                this.setState({ duration: duration, unit: this.getUnits(duration.hour, duration.minute, duration.second), noChange: false});
                break;
            }
            default: {
                break;
            }
        }
    }

    private changeDate() {
        // code here ...
    }

    // private handleDateChange = (date: Date | null) => {
    //     // code here...
    //     console.log(date);
    // }

    private onClickButtonTime = (value: { hour: number, minute: number}, multiplier: number ) => () => {
        const { duration } = this.state;
        duration.hour = (value.hour);
        duration.minute = this.state.minutesInUnit * multiplier;
        duration.second = 0;

        if (duration.minute >= 60) {
            duration.hour++;
            duration.minute -= 60;
        }
        this.setState({ duration: duration, unit: this.getUnits(duration.hour, duration.minute, duration.second), noChange: false });
    }

    private formatNumberBy0(value: number) {
        if (value < 10) {
            return ('0' + value).slice(-2);
        }
        return value;
    }

    private getUnits(hour: number, minute: number, second: number): number {
        return Math.ceil((hour * 60 + minute + second / 60) / this.state.minutesInUnit);
    }

    private timeConvertToWords(value: { hour: number, minute: number }) {
        const { hour, minute } = value;

        if (hour === 0) {
            return minute 
                ? Math.floor(minute) + (minute > 1 ? ' minutes ' : ' minute ')
                : '0 minute';
        } else if (minute === 0) {
            return hour + (hour > 1 ? ' hours ' : ' hour ');
        } else {
            return hour + (hour > 1 ? ' hours ' : ' hour ') + minute + (minute > 1 ? ' minutes ' : ' minute ');
        }
    }
}

// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(TimeslipTimePage as any) as any;