import * as React from 'react';
import { 
    withStyles, 
    WithStyles, 
    createStyles, 
    Theme,
    Paper,
    Tabs,
    Tab,
    CircularProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Divider
} from '@material-ui/core';
// import Button from '@material-ui/core/Button';
// import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import RecentList from './RecentList';
import gql from 'graphql-tag';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { client } from '../..';
// import SaveIcon from '@material-ui/icons/Save';
import Search from '../matter/Search';

export const styles = (theme: Theme) => createStyles({
    appBar: {
        position: 'relative',
        '& > div': {
            padding: 0,
        }
    },
    title: {
        flex: 1,
        textAlign: 'center',
        flexGrow: 1,
        fontSize: '21px',
        fontWeight: 100,
        color: '#333333',
        lineHeight: '32px',
        margin: 0,
        fontFamily: 'Segoe UI'
    },
    dialog: {
        '& > div': {
            boxShadow: 'none',
        }
    },
    content: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column wrap'
    },
    tabContent: {
        flex: 1,
        overflowY: 'auto'
    },
    tabContainer: {
        margin: '15px 0px',
    },
    tab: {
        flexGrow: 1,
        maxWidth: 'none',
        flexBasis: 0,
        flexShrink: 1,
    },
    spaceCreator: {
        width: '48px',
        height: '48px',
    },
    progress: {
        margin: '16px',
        textAlign: 'center'
    },
    starIcon: {
        color: '#cc1212',
    },
    searchContainer: {
        margin: '15px 0',
    },
});

interface DialogProps extends WithStyles<typeof styles> {
    onClose: () => void;
    // tslint:disable-next-line: no-any
    onData: (data: any) => void;
    favActivityFirst?: boolean;
    feeEarnerId?: string;
}

interface DialogStates {
    tab: number;
    // tslint:disable-next-line:no-any
    activityLookupList: any;
    listHeight?: number;
    search: string;
}

class TimeslipActivityPage extends React.Component<DialogProps, DialogStates> {
    constructor(props: DialogProps) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onSelectItem = this.onSelectItem.bind(this);
        this.toggleVIP = this.toggleVIP.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.state = {
            tab: this.props.favActivityFirst ? 0 : 1,
            activityLookupList: [],
            listHeight: 0,
            search: ''
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: DialogProps, nextContext: DialogStates): void {
        // code here...
    }

    componentDidMount() {
        const listElement = jQuery<HTMLElement>('.tabContent');

        // tslint:disable-next-line: no-console
        console.log('height', listElement.height());

        let height = listElement.height() !== undefined ? listElement.height() : 200;

        this.setState({
            listHeight: height
        });

        this.fetchActivities();
    }

    timesheetActivityData(clientOnly: boolean) {
        const { classes } = this.props;
        const activities = this.state.activityLookupList ? this.state.activityLookupList.filter((value: { isVIP: boolean; }) => { 
            return (value.isVIP || this.state.tab === 1); }) : null;
          
        const item = this.getPrivate(activities, classes);

        return (
            <React.Fragment>
                {item}           
            </React.Fragment>
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <div className={classes.appBar} color="default">
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography className={classes.title}>
                            Activity
                        </Typography>
                        <div className={classes.spaceCreator}/>
                    {/* <IconButton color="inherit" onClick={this.handleSave} aria-label="Save">
                            <SaveIcon />
                        </IconButton> */}
                    </Toolbar>
                </div>
                <div className={classes.content}>
                    <div className={classes.searchContainer}>
                        <Search 
                            onSearch={this.onSearch} 
                            placeholder="Search...."
                        />
                    </div>
                    <Paper square={true} className={classes.tabContainer}>
                        <Tabs 
                            value={this.state.tab} 
                            indicatorColor="secondary" 
                            textColor="secondary" 
                            onChange={this.handleTabChange}
                        >
                            <Tab className={classes.tab} label="Favorites" />
                            <Tab className={classes.tab} label="All" />
                        </Tabs>
                    </Paper>
                    <div className={`${classes.tabContent} tabContent`}>
                        {this.state.tab === 0 && this.timesheetActivityData(false)}
                        {this.state.tab === 1 && this.timesheetActivityData(false)}
                    </div>
                </div>
            </React.Fragment>
        ); 
    }

    // tslint:disable-next-line:no-any
    private getPrivate(activities: any, classes: any) {

        // tslint:disable-next-line: no-any
        const searchActivities = activities
            // tslint:disable-next-line: no-any
            .filter((activity: any) => activity.stringValue.toLowerCase().includes(this.state.search.toLowerCase()));

        if (activities) {
            return (
                <List className="list" style={{ height: this.state.listHeight }}>
                    {
                        // tslint:disable-next-line: no-any
                        searchActivities.map( (activity: any, idx: number) => 
                            (
                                <React.Fragment key={activity.id}>
                                    <ListItem
                                        onClick={this.onSelectItem(activity)}
                                    >
                                        <ListItemText
                                            primary={activity.stringValue}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                aria-label="activityIsVIP"
                                                onClick={this.toggleVIP(activity, idx)}
                                            >
                                                {
                                                    activity.isVIP ?
                                                        <StarIcon
                                                            className={classes.starIcon}
                                                        /> :
                                                        <StarBorderIcon
                                                            className={classes.starIcon}
                                                        />
                                                }
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Divider component="li" />
                                </React.Fragment>
                            )
                        )
                    }
                </List>
            );
        } else {
            return (
                <div className={classes.progress}>
                    <CircularProgress />
                </div>
            );
        }

    }

    private handleClose() {
        if (this.props.onClose) {
          return this.props.onClose();
        }
    }

    private handleSave() {
        if (this.props.onData) {
            this.props.onData('data');
        }
        if (this.props.onClose) {
          return this.props.onClose();
        }
    }

    // tslint:disable-next-line: no-any
    private onSelectItem = (activity: any) => () => {
        if (this.props.onData) {
            this.props.onData(activity);
        }
    }

    private handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ tab: newValue });
    }

    // tslint:disable-next-line: no-any
    private toggleVIP = (activity: any, idx: number) => (event: React.MouseEvent<HTMLElement>) => {

        // tslint:disable-next-line: no-any
        const tempActivity: any = JSON.parse(JSON.stringify(activity));
        
        const activityLookupList = JSON.parse(JSON.stringify(this.state.activityLookupList));

        tempActivity.isVIP = !tempActivity.isVIP;

        activityLookupList[idx] = tempActivity;

        client.mutate({
            mutation: updateActivityAsFavourite,
            variables: {
                activityId: tempActivity.id,
                isVIP: tempActivity.isVIP
            }
        }).then((results: {
            data: {
                updateActivityAsFavourite: boolean
            }
        }) => {
            var response = results.data.updateActivityAsFavourite;
            if (response) {

                this.setState({
                    activityLookupList: activityLookupList
                });
            }
            // tslint:disable-next-line:no-any
        }).catch((reason: any) => {
            // tslint:disable-next-line:no-console
            console.log(reason);
        });
    }

    private fetchActivities() {
        client.query({
            query: TimesheetActivitiesQuery,
            variables: {
                feeEarnerId: this.props.feeEarnerId
            },
            fetchPolicy: 'cache-first'  
        }).then((results: {
            data: {
                timesheetActivities: {
                    favouriteActivitiesCount: number
                    // tslint:disable-next-line:no-any
                    activities: any
                }
            }
        }) => {
            if (results.data.timesheetActivities) {
                this.setState({activityLookupList: results.data.timesheetActivities.activities, tab: results.data.timesheetActivities.favouriteActivitiesCount === 0 ? 1 : 0});
            }

        // tslint:disable-next-line:no-any
        }).catch((reason: any) => {
            // tslint:disable-next-line:no-console
            console.log(reason);
        });
    }

    private onSearch(_value: string) {
        this.setState({
            search: _value
        });
    }
}

const TimesheetActivitiesQuery = gql
`query timesheetActivities ($feeEarnerId: String) {
    timesheetActivities(clientOnly: false, feeEarnerId: $feeEarnerId) {
      favouriteActivitiesCount
      activities {
        id
        stringValue
        isVIP
        isFirmActivity
      }
    }
  }
  `;

const updateActivityAsFavourite = gql`mutation updateActivityAsFavourite($activityId: Int!, $isVIP: Boolean!, $feeEarnerId: String) {
    updateActivityAsFavourite(activityId: $activityId, shouldFavourite: $isVIP, feeEarnerId: $feeEarnerId)
}`;
  
// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(TimeslipActivityPage as any) as any;