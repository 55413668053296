import * as React from 'react';
import { 
    withStyles, 
    WithStyles, 
    createStyles, 
    Theme,
    TextField,
} from '@material-ui/core';
// import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment';

export const styles = (theme: Theme) => createStyles({
    appBar: {
        position: 'relative',
        '& > div': {
            padding: 0,
        }
    },
    title: {
        flex: 1,
        textAlign: 'center',
        flexGrow: 1,
        fontSize: '21px',
        fontWeight: 100,
        color: '#333333',
        lineHeight: '32px',
        margin: 0,
        fontFamily: 'Segoe UI'
    },
    dialog: {
        '& > div': {
            boxShadow: 'none',
        }
    },
    content: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column wrap',
        // padding: '0 15px',
    },
    textField: {
        margin: '0 15px',
        flex: 1,
    },
    dateContainer: {
        padding: '20px 0',
    },
    fieldContainer: {
        display: 'flex',
        margin: '0 -15px',
    }
});

interface DialogProps extends WithStyles<typeof styles> {
    onClose: () => void;
    // tslint:disable-next-line: no-any
    onData: (data: any) => void; 
    date?: Date;
}

interface DialogStates {
    date: {
        time: string;
        date: string;
    };
}

class TimeslipStartPage extends React.Component<DialogProps, DialogStates> {
    constructor(props: DialogProps) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.state = {
            date: {
                time: moment().format('HH:mm'),
                date: moment().format('YYYY-MM-DD'),
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: DialogProps, nextContext: DialogStates): void {
        // code here...
        if (this.props.date !== nextProps.date && nextProps.date) {
            var currDate = moment(nextProps.date);
            this.setState({ date: { time: currDate.format('HH:mm'), date: currDate.format('YYYY-MM-DD')}});
        }
    }

    componentDidMount()  {
        var currDate = moment(this.props.date);
        this.setState({ date: { time: currDate.format('HH:mm'), date: currDate.format('YYYY-MM-DD')}});
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <div className={classes.appBar} color="default">
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography className={classes.title}>
                            Starts
                        </Typography>
                        <IconButton color="inherit" onClick={this.handleSave} aria-label="Save">
                            <CheckIcon />
                        </IconButton>
                    </Toolbar>
                </div>
                <div className={classes.content}>
                    <form autoComplete="off">
                        <div className={classes.dateContainer}>
                            <h5>Start Date</h5>
                            <div className={classes.fieldContainer}>
                                <TextField
                                    className={classes.textField}
                                    value={this.state.date.date}
                                    onChange={this.handleDateChange('date')}
                                    margin="normal"
                                    type="date"
                                />
                                <TextField
                                    className={classes.textField}
                                    value={this.state.date.time}
                                    onChange={this.handleDateChange('time')}
                                    margin="normal"
                                    type="time"
                                />
                            </div>
                        </div>                          
                    </form>
                </div>
            </React.Fragment>
        ); 
    }

    private handleClose() {
        if (this.props.onClose) {
          return this.props.onClose();
        }
    }

    private handleSave() {
        if (this.props.onData) {
            const date = this.state.date.date + 'T' + this.state.date.time;
            const display = new Date(date);
            this.props.onData(display);
        }
    }

    private handleDateChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const date = this.state.date;
        date[field] = event.target.value;
        this.setState({ date: date });
    }

}

// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(TimeslipStartPage as any) as any;