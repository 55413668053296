import * as React from 'react';
import { 
    withStyles, 
    WithStyles, 
    createStyles, 
    Theme,
    Input,
} from '@material-ui/core';
// import Paper from '@material-ui/core/Paper';
// import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

export const styles = (theme: Theme) => createStyles({
    root: {
        // padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        margin: '0 8px',
        flex: 1,
    },
    iconButton: {
        // padding: '10px 0px 10px 10px',
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
});

interface SearchProps extends WithStyles<typeof styles> {
    cancelOrBackOnchange?: () => void;
    actionType?: 'back' | 'cancel' | undefined;
    onSearch?: (value: string) => void;
    placeholder: string;
}

interface SearchStates {
    search?: string;
    placeholder: string;
}

class Search extends React.Component<SearchProps, SearchStates> {
    constructor(props: SearchProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            placeholder: this.props.placeholder,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: SearchProps, context: SearchStates) {
        if (nextProps.placeholder !== this.props.placeholder) {
            this.setState({
                placeholder: nextProps.placeholder
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <IconButton className={classes.iconButton} aria-label="Search">
                    <SearchIcon />
                </IconButton>
                {/* <Divider className={classes.divider} /> */}
                <Input
                    placeholder={this.state.placeholder}
                    className={classes.input}
                    onChange={this.handleChange}
                    inputProps={{
                    'aria-label': 'Description',
                    }}
                />
            </div>
        ); 
    }

    private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (this.props.onSearch) {
            this.props.onSearch(event.target.value);
        }
        this.setState({search: event.target.value});
    }

}

// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(Search as any) as any;