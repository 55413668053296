import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { client } from '..';

export interface TimerSummaryData {
    timerSummary: TimerSummary;
}

export interface TimerSummary {
    recordCount: number;
    timerSummaryCounts: TimerSummaryCounts;
    timerSummaries: TimerSummaryElement[];
}

export interface TimerSummaryElement {
    timerGuid: string | null;
    activityDescription: string | null;
    activityId: number | null;
    chargeRate: number | null;
    chargeRateId: number | null;
    billingNote: string | null;
    client: string | null;
    fileNumber: string | null;
    isRunning: boolean;
    matterCategory: string | null;
    matterId: number | null;
    matterType: string | null;
    timesheetNote: string | null;
    timeWorkedSeconds: number | null;
    timeWorkedSecondsTemp: number | null;
    title: string | null;
    lastStarted: Date | null;
    inError: boolean | null;
    errorMessage: string | null;
    units: number | null;
}

export interface TimerSummaryCounts {
    actualRowCountForSearch: number;
    id: number;
    totalCount: number;
}

export interface TimerSummaryParams {
    offset: number | null;
    first: number | null;
    filter: string | null;
}

export const TimerSummaryCountQuery = gql`
    query TimerSummary($offset: Int, $first: Int, $filter: String) {
        timerSummary(offset: $offset, first: $first, filter: $filter) {
            recordCount
            timerSummaryCounts {
                actualRowCountForSearch
                id
                totalCount
            }           
        }
    }
`;

export function fetchTimerSummary(
    query: TimerSummaryParams,
    refreshData: boolean,
    onSuccess: (data?: TimerSummaryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    // var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client
        .query({
            query: TimerSummaryQuery,
            variables: {
                offset: query.offset,
                first: query.first,
                filter: query.filter
            },
            fetchPolicy: 'no-cache',
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export const TimerSummaryQuery = gql`
    query TimerSummary($offset: Int, $first: Int, $filter: String) {
        timerSummary(offset: $offset, first: $first, filter: $filter) {
            recordCount
            timerSummaryCounts {
                actualRowCountForSearch
                id
                totalCount
            }
            timerSummaries {
                id,
                timerGuid
                activityDescription
                activityId
                chargeRate
                chargeRateId
                billingNote
                client
                fileNumber
                isRunning
                matterCategory
                matterId
                matterType
                timesheetNote
                timeWorkedSeconds
                title
                lastStarted
            }
        }
    }
`;

export function retrieveTimerSummaryCountData(
    params: TimerSummaryParams,
    refreshData: boolean,
    onSuccess: (data: TimerSummaryData) => void,
    // tslint:disable-next-line:no-any
    onError: (reason: any) => void,
): void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client
        .query({
            query: TimerSummaryCountQuery,
            variables: {},
            fetchPolicy: fetchPolicy,
        })
        // tslint:disable-next-line:no-any
        .then((results: { data: any }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => {
            onError(reason);
        });
}

export interface CreateTimerMutationParams {
    createdDate: Date | null;
    timeWorkedSeconds: number | null;
    matterId: number | null;
    activityId: number | null;
    chargeRateId: number | null;
    chargeRate: number | null;
    timesheetNote: string | null;
    billingNote: string | null;
    timeUnits: number | null;
    isRunning: Boolean | null;
    timerGuid: string | null;
}

export const CreateTimerMutation = gql`
    mutation CreateTimer(
        $createdDate: DateTime
        $timeWorkedSeconds: Int
        $matterId: Int
        $activityId: Int
        $chargeRateId: Int
        $chargeRate: Int
        $timesheetNote: String
        $billingNote: String
        $timeUnits: Int
        $isRunning: Boolean
        $timerGuid: String
    ) {
        createTimer(
            createdDate: $createdDate
            timeWorkedSeconds: $timeWorkedSeconds
            matterId: $matterId
            activityId: $activityId
            chargeRateId: $chargeRateId
            chargeRate: $chargeRate
            timesheetNote: $timesheetNote
            billingNote: $billingNote
            timeUnits: $timeUnits
            isRunning: $isRunning
            timerGuid: $timerGuid
        )
    }
`;

export interface UpdateTimerMutationParams {
    timerGuid: string;
    endDate: Date | null;
    timeWorkedSeconds: number | null;
    matterId: number | null;
    activityId: number | null;
    chargeRateId: number | null;
    chargeRate: number | null;
    timesheetNote: string | null;
    billingNote: string | null;
    timeUnits: number | null;
}

export const UpdateTimerMutation = gql`
    mutation UpdateTimer(
        $timerGuid: String
        $endDate: DateTime
        $timeWorkedSeconds: Int
        $matterId: Int
        $activityId: Int
        $chargeRateId: Int
        $chargeRate: Int
        $timesheetNote: String
        $billingNote: String
        $timeUnits: Int
    ) {
        updateTimer(
            timerGuid: $timerGuid
            endDate: $endDate
            timeWorkedSeconds: $timeWorkedSeconds
            matterId: $matterId
            activityId: $activityId
            chargeRateId: $chargeRateId
            chargeRate: $chargeRate
            timesheetNote: $timesheetNote
            billingNote: $billingNote
            timeUnits: $timeUnits
        )
    }
`;

export interface TimerNotificationSubscriptionData {
    timerNotification: TimerNotification;
}

export interface TimerNotification {
    message: string;
    description: null;
    timerGuid: string;
    status: boolean;
    id: number;
}

export const TimerSubscriptionNotification = gql`
    subscription TimerSubsciption {
        timerNotification {
            message
            description
            timerGuid
            status
        }
    }
`;

export interface DeleteTimerMutationParams {
    timerGuid: string;
}

export const DeleteTimerMutation = gql`
    mutation DeleteTimer($timerGuid: String) {
        deleteTimer(timerGuid: $timerGuid)
    }
`;

export interface StartTimerMutationParams {
    timerGuid: string | null;
    startDateTime: Date;
}

export const StartTimerMutation = gql`
    mutation StartTimer($timerGuid: String, $startDateTime: DateTime) {
        startTimer(timerGuid: $timerGuid, startDateTime: $startDateTime)
    }
`;

export interface PauseTimerMutationParams {
    timerGuid: string | null;
    pauseDateTime: Date;
}

export const PauseTimerMutation = gql`
    mutation PauseTimer($timerGuid: String, $pauseDateTime: DateTime) {
        pauseTimer(timerGuid: $timerGuid, pauseDateTime: $pauseDateTime)
    }
`;

export interface SubmitTimerMutationParams {
    timerGuid: string | null;
    isSubmitAndSync: boolean;
    timeWorkedSeconds: number | null;
    matterId: number | null;
    activityId: number | null;
    chargeRateId: number | null;
    chargeRate: number | null;
    timesheetNote: string | null;
    billingNote: string | null;
    timeUnits: number | null;
}

export const SubmitTimerMutation = gql`
    mutation SubmitTimerMutaiton($timerGuid: String, $isSubmitAndSync: Boolean
        $timeWorkedSeconds: Int
        $matterId: Int
        $activityId: Int
        $chargeRateId: Int
        $chargeRate: Int
        $timesheetNote: String
        $billingNote: String
        $timeUnits: Int ){
        submitTimer(timerGuid: $timerGuid, isSubmitAndSync: $isSubmitAndSync,             timeWorkedSeconds: $timeWorkedSeconds
            matterId: $matterId
            activityId: $activityId
            chargeRateId: $chargeRateId
            chargeRate: $chargeRate
            timesheetNote: $timesheetNote
            billingNote: $billingNote
            timeUnits: $timeUnits)
    }
`;