export function generateColor(str: string) { 
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        // tslint:disable-next-line: no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    // tslint:disable-next-line: no-duplicate-variable

    return colourList[Math.abs(hash % colourList.length)];
} 

const colourList: Array<string> = new Array ( '#b9def0', '#e8e28b', '#bce0af', '#e8aa8b', '#d9e0af', '#e88b8b');