import * as React from 'react';
import { 
    withStyles, 
    WithStyles, 
    createStyles, 
    Theme,
    // Paper,
    // Tabs,
    // Tab,
    Button,
    List,
    ListItem,
    ListItemText,
    Divider,
    TextField,
} from '@material-ui/core';
// import Button from '@material-ui/core/Button';
// import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
// import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import Search from '../matter/Search';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

export const styles = (theme: Theme) => createStyles({
    appBar: {
        position: 'relative',
        '& > div': {
            padding: 0,
        }
    },
    title: {
        flex: 1,
        textAlign: 'center',
        flexGrow: 1,
        fontSize: '21px',
        fontWeight: 100,
        color: '#333333',
        lineHeight: '32px',
        margin: 0,
        fontFamily: 'Segoe UI'
    },
    dialog: {
        '& > div': {
            boxShadow: 'none',
        }
    },
    content: {
        flex: 1,
        display: 'flex',
        flexFlow: 'column wrap'
    },
    tabContainer: {
        margin: '15px 0px',
    },
    tab: {
        flexGrow: 1,
        maxWidth: 'none',
        flexBasis: 0,
        flexShrink: 1,
    },
    button: {
        
    },
    contentCol: {
        flex: 1,
        padding: '15px 0'
    },
    buttonWrapper: {
        textAlign: 'center',
    },
    listContainer: {

    },
    list: {

    },
    selectedContainer: {
        padding: '15px 15px',
        minHeight: '100px',
        fontSize: '18px',
        fontWeight: 600
    },
    searchWrapper: {
        // backgroundColor: '#f5f5f5',
        // margin: '0 -15px',
        padding: '15px 0',
    },
    textArea: {
        paddingBottom: '10px',
        width: '100%',
        '& textarea': {
            fontSize: '16px',
        },
        '& > div::before': {
            // content: 'none',
        }
    }
});

interface DialogProps extends WithStyles<typeof styles> {
    onClose: () => void;
    // tslint:disable-next-line: no-any
    onData: (data: any, isInprotechNote: boolean) => void;
    note: string;
    isInprotechNote: boolean;
    title: string;
}

interface DialogStates {
    tab: number;
    note: string;
    filter: string;
}

class TimeslipNotesPage extends React.Component<DialogProps, DialogStates> {
    constructor(props: DialogProps) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.onSelectItem = this.onSelectItem.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.state = {
            tab: 0,
            note: this.props.note,
            filter: ''
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: DialogProps, nextContext: DialogStates): void {
        // code here...
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {/* tslint:disable-next-line: no-any */}
                <Query<any> 
                    key="timesheet-notes"
                    query={standardNotesList}
                >
                    {({
                        // tslint:disable-next-line: no-shadowed-variable
                        loading, error, data 
                    }) => {
                        // tslint:disable-next-line:no-any
                        var subjectList: any[] = [];
                        // tslint:disable-next-line:no-any
                        if (data && data.defaultNarrative) {
                            data.defaultNarrative.narratives.forEach((s: { stringValue: string; }) => {
                            // tslint:disable-next-line:no-console
                            if (s.stringValue.toLowerCase().includes(this.state.filter.toLowerCase())) {
                                subjectList.push(s.stringValue);

                            }
                        });
                            return (
                            <div>
                                <div className={classes.appBar} color="default">
                                    <Toolbar>
                                        <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <Typography className={classes.title}>
                                            {this.props.title}
                                        </Typography>
                                        <IconButton color="inherit" onClick={this.handleSave} aria-label="Save">
                                            <CheckIcon />
                                        </IconButton>
                                    </Toolbar>
                                </div>
                            <div className={classes.content}>
                                <div className={classes.contentCol}>
                                    <TextField
                                        className={classes.textArea}
                                        id="outlined-multiline-static"
                                        // label="Multiline"
                                        multiline={true}
                                        rows="10"
                                        value={this.state.note}
                                        onChange={this.handleTextChange}
                                        // variant="outlined"
                                    />
                                    <div className={classes.buttonWrapper}>
                                        <Button variant="outlined" color="secondary" className={classes.button} onClick={this.handleClear} >
                                            {'Clear ' + this.props.title}
                                        </Button>
                                    </div>
                                    {/* <Paper square={true} className={classes.tabContainer}>
                                        <Tabs 
                                            value={this.state.tab} 
                                            indicatorColor="secondary" 
                                            textColor="secondary" 
                                            onChange={this.handleTabChange}
                                        >
                                            <Tab className={classes.tab} label="Recent" />
                                            <Tab className={classes.tab} label="Standard" />
                                        </Tabs>
                                    </Paper> */}
                                    {this.props.isInprotechNote ? <div/> : <div className={classes.listContainer}>
                                        <div className={classes.searchWrapper}>
                                            <Search
                                                placeholder={'Search ' + this.props.title}
                                                onSearch={this.handleSearch}
                                            />
                                        </div>
                                        <List className={classes.list}>
                                            {subjectList.map( (activity: string, index: number) => (
                                                <React.Fragment key={index}>
                                                    <ListItem
                                                        onClick={this.onSelectItem(activity)}
                                                    >
                                                        <ListItemText
                                                            primary={activity}
                                                        />
                                                    </ListItem>
                                                    <Divider component="li" />
                                                </React.Fragment>

                                            ))}
                                          
                                        </List>
                                    </div>}
                                    
                                </div>
                            </div>
                            </div>);
                        }
                        return (<div/>);
                        
                    }}
                </Query>
            </React.Fragment>
        ); 
    }
    // tslint:disable-next-line:no-any
    private handleTextChange (event: any) {
        // tslint:disable-next-line:no-console
        this.setState({ note: event.target.value });
    }

    // tslint:disable-next-line:no-any
    private handleSearch (event: any) {
        // tslint:disable-next-line:no-console
        this.setState({ filter: event });
    }

    // tslint:disable-next-line: no-any
    private onSelectItem = (note: any) => () => {
        // tslint:disable-next-line:no-console
        console.log('note', note);
        if (this.props.onData) {
            this.props.onData(note, this.props.isInprotechNote);
        }
    }

    private handleClear() {
        this.setState({ note: '' });
    }

    private handleClose() {
        if (this.props.onClose) {
          return this.props.onClose();
        }
    }

    private handleSave() {
        if (this.props.onData) {
            this.props.onData(this.state.note, this.props.isInprotechNote);
        }
        if (this.props.onClose) {
          return this.props.onClose();
        }
    }

    private handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        this.setState({ tab: newValue });
    }
}
const standardNotesList = gql`query DefaultNotes {
    defaultNarrative {
        recordCount
        narratives {
          stringValue
        }
      }
}`;
// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(TimeslipNotesPage as any) as any;