import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import { TextField, CircularProgress, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
// import match from 'autosuggest-highlight/match';
// import parse from 'autosuggest-highlight/parse';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        // minWidth: '200px',
    },
    starIcon: {
        color: '#cc1212',
    },
    listBox: {
        '& .MuiListItem-container': {
            width: '100%'
        }
    },
    listItem: {
        width: '100%',
    },
  })
);

export interface ActivityItem extends IAutoCompleteItem, Activity {

}

interface ActivityAutoCompleteSelectorProps {
    disabled?: boolean;
    required?: boolean;
    multiple?: boolean;
    // tslint:disable-next-line: no-any
    value?: ActivityItem | any; // value only available in single select
    error?: boolean;
    onSelection?: (value: ActivityItem | ActivityItem[], name: string) => void;
    className?: string;
    helperText?: string;
    name?: string;
    label?: string;
    disablePortal?: boolean;    
    tabIndex?: number;
    autoFocus?: boolean;
    feeEarnerId?: string;
}

interface ActivityAutoCompleteSelectorState {
    isLoading: boolean;
    // tslint:disable-next-line: no-any
    options: ActivityItem[];
    // tslint:disable-next-line: no-any
}

export const ActivityAutoCompleteSelector: React.FC<ActivityAutoCompleteSelectorProps> = props => {

    const classes = useStyles();

    const [state, setState] = useState<ActivityAutoCompleteSelectorState>({
        isLoading: true,
        options: [],
    });

    const timesheetAcitivtiesQuery = useQuery<TimesheetActivityData>(TimesheetActivitiesQuery, {
        fetchPolicy: 'cache-first',
        variables: {
            feeEarnerId: props.feeEarnerId
        }
    });

    useEffect(() => {
        if (timesheetAcitivtiesQuery.data && !timesheetAcitivtiesQuery.loading && !timesheetAcitivtiesQuery.error) {

            const activities = timesheetAcitivtiesQuery.data ? timesheetAcitivtiesQuery.data.timesheetActivities.activities : [];
            setState((prevState) => {
                return {
                    ...prevState,
                    options: activities.map((source: Activity) => ({...source, value: source.stringValue, label: source.stringValue })),
                    // tslint:disable-next-line: no-any
                    // value: financialYear.map((source: FinancialYear) => ({...source, value: source.description, label: source.description })).find((v: any) => v.isCurrent),
                    isLoading: false
                };
            });
        }
    // tslint:disable-next-line: align
    }, [timesheetAcitivtiesQuery.data, timesheetAcitivtiesQuery.loading, timesheetAcitivtiesQuery.error]);

    // tslint:disable-next-line: no-any
    const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
        if (props.onSelection) {
            props.onSelection(value, props.name ? props.name : 'financialYear');
        }
    };

    const getInputProps = (params: RenderInputParams, isLoading?: boolean) => {
        return  ({   
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                  {isLoading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),     
         });
    };

    return (
        <div className={`${classes.root} ${props.className}`}>
            { props.multiple ? 
                (
                    <Autocomplete
                        value={props.value}
                        multiple={true}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}  
                        getOptionSelected={(option, value) => value.value === option.value}  
                        classes={{
                            listbox: classes.listBox
                        }}                
                        tabIndex={props.tabIndex}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Financial Year'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}      
                                helperText={props.helperText}                      
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            // const matches = match(option.label, inputValue);
                            // const parts = parse(option.label, matches);
                            return (
                                <>
                                    <ListItem
                                        // onClick={this.onSelectItem(activity)}
                                        className={classes.listItem}
                                    >
                                        <ListItemText
                                            primary={option.stringValue}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                aria-label="activityIsVIP"
                                                // onClick={this.toggleVIP(activity, idx)}
                                            >
                                                {
                                                    option.isVIP ?
                                                        <StarIcon
                                                            className={classes.starIcon}
                                                        /> :
                                                        <StarBorderIcon
                                                            className={classes.starIcon}
                                                        />
                                                }
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {/* <Divider component="li" /> */}
                                </>
                            );
                        }}
                    />
                )
                :
                (
                    <Autocomplete
                        value={props.value}
                        loading={state.isLoading}
                        disabled={props.disabled}
                        onChange={handleChange}
                        options={state.options}
                        disablePortal={props.disablePortal} // to Stop the dropdown select from closing the form
                        // tslint:disable-next-line: jsx-no-lambda
                        getOptionLabel={(option: IAutoCompleteItem) => option.label}     
                        getOptionSelected={(option, value) => value.value === option.value}    
                        classes={{
                            listbox: classes.listBox
                        }}    
                        // tslint:disable-next-line: jsx-no-lambda
                        renderInput={params => (
                            <TextField 
                                {...params} 
                                label={props.label ? props.label : 'Financial Year'}
                                variant="standard" 
                                fullWidth={true} 
                                margin="none" 
                                required={props.required}
                                InputProps={getInputProps(params, state.isLoading)}
                                error={props.error}       
                                helperText={props.helperText}   
                                autoFocus={props.autoFocus}                       
                            />
                        )}
                        // tslint:disable-next-line: jsx-no-lambda
                        renderOption={(option, { inputValue }) => {
                            // const matches = match(option.label, inputValue);
                            // const parts = parse(option.label, matches);
                    
                            return (
                                <>
                                    <ListItem
                                        // onClick={this.onSelectItem(activity)}
                                        className={classes.listItem}
                                    >
                                        <ListItemText
                                            primary={option.stringValue}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                aria-label="activityIsVIP"
                                                // onClick={this.toggleVIP(activity, idx)}
                                            >
                                                {
                                                    option.isVIP ?
                                                        <StarIcon
                                                            className={classes.starIcon}
                                                        /> :
                                                        <StarBorderIcon
                                                            className={classes.starIcon}
                                                        />
                                                }
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    {/* <Divider component="li" /> */}
                                </>
                            );
                        }}
                    />
                )
            }
        </div>
    );
};

export interface TimesheetActivityData {
    timesheetActivities: TimesheetActivities;
}

export interface TimesheetActivities {
    favouriteActivitiesCount: number;
    activities:               Activity[];
}

export interface Activity {
    id:             number;
    stringValue:    string;
    isVIP:          boolean;
    isFirmActivity: boolean;
}

const TimesheetActivitiesQuery = gql`
    query timesheetActivities($feeEarnerId: String) {
        timesheetActivities(clientOnly: false, feeEarnerId: $feeEarnerId) {
            favouriteActivitiesCount
            activities {
                id
                stringValue
                isVIP
                isFirmActivity
            }
        }
    }
`;