import * as React from 'react';
import { 
    withStyles, 
    WithStyles, 
    createStyles, 
    Theme,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    // CircularProgress,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
// import RecentList from '../RecentList';
// import { Query } from 'react-apollo';
import StarIcon from '@material-ui/icons/Star';
import gql from 'graphql-tag';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { MatterLookUpQueryParams, retrieveMatterLookUpData, Matters, MatterLookUpData } from './MatterLookUpRepository';
import { showNotification } from '../../App';
import _, { debounce } from 'lodash';
import { client } from '../..';
import { generateColor } from './ColorGenerator';
import { InfiniteScrollWrapper } from './InfiniteScrollWrapper';
import { getMatterTitlePrefix } from '../page/TimeslipMatterPage';

export const styles = (theme: Theme) => createStyles({
    tabContainer: {
        margin: '15px 30px',
    },
    tab: {
        flexGrow: 1,
        maxWidth: 'none',
        flexBasis: 0,
        flexShrink: 1,
        minWidth: 0,
    },
    progress: {
        margin: '16px',
        textAlign: 'center'
    },
    listContainer: {
        flex: 1,
        '& .gridview > div': {
            flexFlow: 'column!important',
        },
        '& .divider': {
            display: 'none',
        },
        '& #table': {
            // padding: 0,
        }
    },
    customList: {
        display: 'flex',
        flexFlow: 'column',
        fontSize: '16px',
        '& .id': {
            color: '#cc1212',
            marginBottom: '5px',
        },
        '& .name': {
            marginBottom: '5px',
        },
        '& .category': {
            color: 'rgba(0, 0, 0, 0.54)',
            fontSize: '12px',
        }
    },
    starIcon: {
        color: '#cc1212',
    }
});

const loadBlockLimit = 12;

interface AllMatterTabProps extends WithStyles<typeof styles> {
    cancelOrBackOnchange?: () => void;
    actionType?: 'back' | 'cancel' | undefined;
    search?: string;
    // tslint:disable-next-line: no-any
    onSelectedItem?: (matter: any) => void;
    onTabChange?: (placeholder: string) => void;
    sourceSystem: string;
    feeEarnerId?: string;

}

interface AllMatterTabStates {
    tab: number;
    search: string;
    // tslint:disable-next-line: no-any
    matterLookupList: any;
    listHeight?: number;
}

class AllMatterTab extends React.Component<AllMatterTabProps, AllMatterTabStates> {

    private offset: number;
    private currentRowCount: number;
    // private totalCount: number;
    private typeFilter: 'ALL' | 'RECENT' | 'FAVOURITE' | 'All' | 'Recent' | 'Favourite' | null;
    private statusFilter?: 'ALL' | 'CURRENT' | 'COMPLETE' | 'All' | 'Current' | 'Complete' | null;
    
    constructor(props: AllMatterTabProps) {
        super(props);
        this.handleTabChange = this.handleTabChange.bind(this);
        this.onSelectItem = this.onSelectItem.bind(this);
        this.toggleVIP = this.toggleVIP.bind(this);
        this.fetchMatterData = debounce(this.fetchMatterData.bind(this), 500);
        this.getInfiniteLoaderAll = this.getInfiniteLoaderAll.bind(this);
        this.getInfiniteLoaderCurrent = this.getInfiniteLoaderCurrent.bind(this);
        this.getInfiniteLoaderComplete = this.getInfiniteLoaderComplete.bind(this);
        this.createAbbreviation = this.createAbbreviation.bind(this);
        var search = '';
        if  (props.search) {
            search = props.search;
        }
        // this.matterData = debounce(this.matterData.bind(this), 500);
        this.state = {
            tab: 1,
            search: search,
            matterLookupList: [],
            listHeight: 0,
        };

        this.offset = 0;
        this.currentRowCount = 0;
        // this.totalCount = 0;
        this.typeFilter = 'All';
        this.statusFilter = null;
    }

    UNSAFE_componentWillReceiveProps(nextProps: AllMatterTabProps, nextContext: AllMatterTabStates): void {
        if (nextProps.search !== this.props.search) {
            this.resetSearchProperties(); 

            this.setState({ 
                matterLookupList: [], // Clear the data is the search input is changed
                search: nextProps.search ? nextProps.search : ''                
            });

            // search again with the filter
            this.fetchMatterData();
        }
    }

    componentDidMount() {
        this.fetchMatterData();

        const listElement = jQuery<HTMLElement>('#allMatterList');

        this.setState({
            listHeight: listElement.height(),
        });
        
    }   
    
    render() {
        const { classes } = this.props;

        let infiniteScrollTable: JSX.Element;
        
        if (this.state.tab === 0) {
            infiniteScrollTable = this.getInfiniteLoaderAll();
        } else if (this.state.tab === 1) {
            infiniteScrollTable = this.getInfiniteLoaderCurrent();
        } else {
            infiniteScrollTable = this.getInfiniteLoaderComplete();
        } 

        return (
            <React.Fragment>
                <Paper square={true} className={classes.tabContainer}>
                    <Tabs 
                        value={this.state.tab} 
                        indicatorColor="secondary" 
                        textColor="secondary" 
                        onChange={this.handleTabChange}
                    >
                        <Tab key={'All'} className={classes.tab} label="All" />
                        <Tab key={'Current'} className={classes.tab} label="Current" />
                        <Tab key={'Complete'} className={classes.tab} label="Complete" />
                    </Tabs>
                </Paper>
                <div className={classes.listContainer} id="allMatterList">
                    <List>
                        {infiniteScrollTable}
                    </List>
                </div>
            </React.Fragment>
        ); 
    }

    private getInfiniteLoaderAll() {
        const { classes } = this.props;
        
        return (
            <InfiniteScrollWrapper
                key="infiniteScrollTableAll"
                height={this.state.listHeight ? this.state.listHeight : 0}
                // tslint:disable
                mapFunc={(matter: Matters, idx: number) => {
                    // if (this.doesMatterFilterPass(matter)) {
                    const matterTitlePrefix = getMatterTitlePrefix(this.props.sourceSystem, matter);
                    return (
                        <React.Fragment key={idx}>
                            <ListItem
                                onClick={this.onSelectItem(matter)}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        style={{
                                            backgroundColor: generateColor(matterTitlePrefix),
                                        }}
                                    >
                                        {this.createAbbreviation(matterTitlePrefix)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={this.listComponent(matter, matterTitlePrefix)}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        aria-label="isVIP"
                                        onClick={this.toggleVIP(matter, idx)}
                                    >
                                        {
                                            matter.isVIP ?
                                                <StarIcon
                                                    className={classes.starIcon}
                                                /> :
                                                <StarBorderIcon
                                                    className={classes.starIcon}
                                                />
                                        }
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    );

                }
                    // return null;
                }
                childData={this.state.matterLookupList}
                blockSize={12}
                loadData={this.fetchMatterData}
            />
        );
    }

    private listComponent(matter: Matters, matterTitlePrefix: string): React.ReactNode {
        const { classes } = this.props;
        
        return (
            <div className={classes.customList}>
                <span className="id">{matter.fileNumber}</span>
                <span className="name">{matter.client}</span>
                <span className="category">{`${matterTitlePrefix} | ${matter.title}`}</span>
            </div>
        );
    }

    private getInfiniteLoaderCurrent() {
        const { classes } = this.props;
        return (
            <InfiniteScrollWrapper
                key="infiniteScrollTableCurrent"
                height={this.state.listHeight ? this.state.listHeight : 0}
                // tslint:disable
                mapFunc={(matter: any, idx: number) => {
                    // if (this.doesMatterFilterPass(matter)) {
                        const matterTitlePrefix = getMatterTitlePrefix(this.props.sourceSystem, matter);
                    return (
                        <React.Fragment key={idx}>
                            <ListItem
                                onClick={this.onSelectItem(matter)}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        style={{
                                            backgroundColor: generateColor(matterTitlePrefix),
                                        }}
                                    >
                                        {this.createAbbreviation(matterTitlePrefix)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={this.listComponent(matter, matterTitlePrefix)}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        aria-label="isVIP"
                                        onClick={this.toggleVIP(matter, idx)}
                                    >
                                        {
                                            matter.isVIP ?
                                                <StarIcon
                                                    className={classes.starIcon}
                                                /> :
                                                <StarBorderIcon
                                                    className={classes.starIcon}
                                                />
                                        }
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    );

                }
                    // return null;
                }
                childData={this.state.matterLookupList}
                blockSize={12}
                loadData={this.fetchMatterData}
            />
        );
    }

    private getInfiniteLoaderComplete() {
        const { classes } = this.props;
        return (
            <InfiniteScrollWrapper
                key="infiniteScrollTableComplete"
                height={this.state.listHeight ? this.state.listHeight : 0}
                // tslint:disable
                mapFunc={(matter: any, idx: number) => {
                    // if (this.doesMatterFilterPass(matter)) {
                        const matterTitlePrefix = getMatterTitlePrefix(this.props.sourceSystem, matter);
                    return (
                        <React.Fragment key={idx}>
                            <ListItem
                                onClick={this.onSelectItem(matter)}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        style={{
                                            backgroundColor: generateColor(matterTitlePrefix),
                                        }}
                                    >
                                        {this.createAbbreviation(matterTitlePrefix)}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={this.listComponent(matter, matterTitlePrefix)}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        aria-label="isVIP"
                                        onClick={this.toggleVIP(matter, idx)}
                                    >
                                        {
                                            matter.isVIP ?
                                                <StarIcon
                                                    className={classes.starIcon}
                                                /> :
                                                <StarBorderIcon
                                                    className={classes.starIcon}
                                                />
                                        }
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Divider component="li" />
                        </React.Fragment>
                    );

                }
                    // return null;
                }
                childData={this.state.matterLookupList}
                blockSize={12}
                loadData={this.fetchMatterData}
            />
        );
    }

    private fetchMatterData(isRefresh?: boolean) {
        var matterLookUpQueryParams: MatterLookUpQueryParams = {
            offset: this.offset,
            first: loadBlockLimit,
            filter: this.state.search,
            typeFilter: this.typeFilter,
            statusFilter: this.statusFilter,
            isExcludeCompleted: false,
            feeEarnerId: this.props.feeEarnerId
        };

        retrieveMatterLookUpData(matterLookUpQueryParams,
                                 true, // refresh data
                                 (data) => this.onDataRetrieved(data),
                                    // tslint:disable-next-line:no-any
                                 function (reason: any): void {
                showNotification(null, reason, 'error');
            });  
    }

    // tslint:disable-next-line: no-any
    private onDataRetrieved(data: MatterLookUpData) {
        // append the data if we already have some from previous loading
        const matterLookupListData = data.matterLookupList.matters;

        // Set the Total Record count for the search
        // const totalRecords = data.matterSummaryList.recordCount;

        // Current record count
        const currentRecordCount = matterLookupListData.length;

        this.offset = (this.offset + currentRecordCount);

        this.currentRowCount = (this.currentRowCount + currentRecordCount);

        // this.totalCount = totalRecords;

        let matterLookupList = _.union(this.state.matterLookupList, matterLookupListData);

        this.setState({
            matterLookupList: matterLookupList
        });
    }

    private handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
        switch (newValue) {
            case 0: {
                this.statusFilter = 'All';
                if (this.props.onTabChange) {
                    this.props.onTabChange('Search all matters');
                }
                break;
            }
            case 1: {
                this.statusFilter = 'Current';
                if (this.props.onTabChange) {
                    this.props.onTabChange('Search current matters');
                }
                break;
            }
            case 2: {
                this.statusFilter = 'Complete';
                if (this.props.onTabChange) {
                    this.props.onTabChange('Search complete matters');
                }
                break;
            }
        }

        this.resetSearchProperties(); 

        this.setState({
            tab: newValue,
            matterLookupList: []
        });

        this.fetchMatterData();
    }

    private resetSearchProperties() {
        this.offset = 0;
        this.currentRowCount = 0;
    }

    private createAbbreviation(value: string | null) {
                
        if(value) {
            let values = value.split(' ');

            if(values.length === 1) {
                return value.substr(0, 2);
            } else {
                
                let abbr = '';

                for (let index = 0; index < 2; index++) {
                    abbr += values[index].substr(0, 1);
                }

                return abbr;
            }

        }
        return;
    }

    // tslint:disable-next-line: no-any
    private onSelectItem = (matter: any) => () => {
        if (this.props.onSelectedItem) {
            this.props.onSelectedItem(matter);
        }
    }

    // tslint:disable-next-line: no-any
    private toggleVIP = (matter: Matters, idx: number) => (event: React.MouseEvent<HTMLElement>) => {

        const tempMatter: Matters = JSON.parse(JSON.stringify(matter));
        
        const matterLookupList = JSON.parse(JSON.stringify(this.state.matterLookupList));

        tempMatter.isVIP = !matter.isVIP;

        matterLookupList[idx] = tempMatter;

        client.mutate({
            mutation: updateMatterFavourite,
            variables: {
                matterId: matter.id,
                isVIP: tempMatter.isVIP,
                feeEarnerId: this.props.feeEarnerId
            }
        }).then((results: {
            data: {
                updateMatterAsFavourite: boolean
            }
        }) => {
            var response = results.data.updateMatterAsFavourite;
            if (response) {

                this.setState({
                    matterLookupList: matterLookupList
                });
            }
            // tslint:disable-next-line:no-any
        }).catch((reason: any) => {
            // tslint:disable-next-line:no-console
            console.log(reason);
        });
    }

}

const updateMatterFavourite = gql`mutation UpdateMatterAsFavourite($matterId: Int!, $isVIP: Boolean, $feeEarnerId: String) {
    updateMatterAsFavourite(matterId: $matterId, shouldFavourite: $isVIP, feeEarnerId: $feeEarnerId)
}`;
// tslint:disable-next-line:no-any
export default withStyles(styles as any, { withTheme: true })(AllMatterTab as any) as any;