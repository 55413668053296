import gql from 'graphql-tag';
import { FetchPolicy } from 'apollo-client';
import { client } from '..';
import { MatterData, FinancialsInfoData } from '../matterDetails/financials/FinancialsInfo';

export interface TimesheetQueryParams {
    startDate: String | Date | null;
    endDate: String | Date | null;
    timeslipStatus: number; // 0 = All, 1 = Incomplete, 2 = Not Synced, 3 = All Drafts
    filter: String | null;
    offset: number | null;
    first: number | null;
    feeEarnerId?: string;
    includeNoMatter: boolean | null;
}

export interface TimeStatQueryParams {
    startDate: Date | String | null;
    endDate: Date | String | null;
    feeEarnerId?: string;
}

export interface TimesheetData {
    loading?: boolean;
    networkStatus?: number;
    timeslipSummary: TimeslipSummary;
}

export interface TimeStatData {
    loading?: boolean;
    networkStatus?: number;
    timesheetStatistics: TimesheetStatistics;
}

export interface DraftData {
    loading?: boolean;
    networkStatus?: number;
    draftTimeslips: TimeslipSummary;
}

export interface TimesheetSummaryData {
    loading?: boolean;
    networkStatus?: number;
    timesheetSummary: TimesheetSummary;
}

export interface TimesheetSummary {
    summaries: DaySummary[];
}
export interface DaySummary {
    date: string;
    totalForDaySeconds: number;
    totalForDayUnits: number;
}
export interface TimesheetStatistics {
    timesheetStatistics: TimesheetStatistic[];
}

export interface TimesheetStatistic {
    timesheetDate: Date;
    timeslipCount: number;
    chargeableTimeMins: number;
    nonChargeableTimeMins: number;
    notSubmittedCount: number;
    incompleteCount: number;
    feeValue: number;
    pendingCount: number;
    draftExcludingPendingCount: number;
}

export interface TimeslipSummary {
    recordCount: number;
    timeSlipSummaryCounts: TimeSlipSummaryCounts;
    timeslipSummaries: TimeslipSummaries[];
}

export interface TimeSlipSummaryCounts {
    actualRowCountForSearch: number;
    draftCount: number;
    submittedCount: number;
    totalCount: number;
  }

export interface TimeslipSummaries {
    id: number | undefined;
    matterID: number | null;
    fileNumber: string | null;
    matterCategory: string | null;
    matterType: string | null;
    title: string | null;
    activityID: number | null;
    activityDescription: string | null;
    chargeLevelID: number | null;
    chargeLevelDescription: string | null;
    client: string | null;
    itemDate: Date | null;
    startTimeSeconds: number | null;
    endTimeSeconds: number | null;
    timeWorkedSeconds: number | null;
    amount: number | null;
    chargeRate: number | null;
    units: number | null;
    note: string | null;
    isSynced: boolean | null;
    syncErrorID: number | null;
    syncErrorDescription: string | null;
    isIncomplete: boolean | null;
    status: string | null;
    duration: string | null;
    startTime: string | null;
    endTime: string | null;
    timesheetNote: string | null;

}

export function retrieveTimesheetData(query: TimesheetQueryParams,
                                      refreshData: boolean,
                                      onSuccess: (data: TimesheetData) => void,
                                      // tslint:disable-next-line:no-any
                                      onError: (reason: any) => void)
    : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: TimesheetData,
        variables: {
            offset: query.offset,
            first: query.first,
            filter: query.filter,
            startDate: query.startDate,
            endDate: query.endDate,
            timeslipStatus: query.timeslipStatus, 
            feeEarnerId: query.feeEarnerId,
            includeNoMatter: query.includeNoMatter
        },
        fetchPolicy: fetchPolicy
    })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => { onError(reason); });
}

const TimesheetData = gql`
    query timeslipSummary(
        $startDate: DateTime, 
        $endDate: DateTime, 
        $timeslipStatus: Int, 
        $filter: String, 
        $offset: Int, 
        $first: Int,
        $feeEarnerId: String,
        $includeNoMatter: Boolean
    ){
        timeslipSummary(
            startDate: $startDate, 
            endDate: $endDate, 
            timeslipStatus: $timeslipStatus, 
            filter: $filter,
            offset: $offset, 
            first: $first, 
            source: "ConnectWeb", 
            feeEarnerId: $feeEarnerId, 
            includeNoMatter: $includeNoMatter
        ) {
            recordCount,
            timeSlipSummaryCounts {
                actualRowCountForSearch
                draftCount
                submittedCount
                totalCount
            },
            timeslipSummaries {
            id
            matterID
            fileNumber
            matterCategory
            matterType
            title
            activityID
            activityDescription
            chargeLevelID
            chargeLevelDescription
            client
            itemDate
            startTimeSeconds
            endTimeSeconds
            timeWorkedSeconds
            amount
            chargeRate
            units
            note
            isSynced
            syncErrorID
            syncErrorDescription
            isIncomplete
            timesheetNote
            }
        }
    }
`;

export function retrieveTimeStatsData(query: TimeStatQueryParams,
                                      refreshData: boolean,
                                      onSuccess: (data: TimeStatData) => void,
                                      // tslint:disable-next-line:no-any
                                      onError: (reason: any) => void)
    : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: TimsheetStats,
        variables: {
            startDate: query.startDate,
            endDate: query.endDate ,
            feeEarnerId: query.feeEarnerId         
        },
        fetchPolicy: fetchPolicy
    })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {

            // var data =  {
            //     timesheetStatistics: {
            //         timesheetStatistics : [
            //             {
            //                 timesheetDate: new Date(),
            //                 timeslipCount: 5,
            //                 chargeableTimeMins: 30,
            //                 nonChargeableTimeMins: 30,
            //                 notSubmittedCount: 5,
            //                 feeValue: 100
            //             }
            //         ]
            //     }
            // };
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => { onError(reason); });
}
const TimsheetStats = gql`
query timsheetStats($startDate: DateTime, $endDate: DateTime, $feeEarnerId: String) {
    timesheetStatistics(startDate: $startDate, endDate: $endDate, feeEarnerId: $feeEarnerId) {
      timesheetStatistics {
        timesheetDate
        timeslipCount
        chargeableTimeMins
        nonChargeableTimeMins
        notSubmittedCount
        incompleteCount
        feeValue
        pendingCount
        draftExcludingPendingCount
      }
    }
  }
  `;

export function retrieveDrafts(refreshData: boolean,
                               onSuccess: (data: DraftData) => void,
                                      // tslint:disable-next-line:no-any
                               onError: (reason: any) => void,
                               includeDraft: boolean,
                               includePending: boolean,
                               feeEarnerId?: string
                               )
    : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: Drafts,
        variables: {
            feeEarnerId: feeEarnerId,
            includeDraft: includeDraft,
            includePending: includePending         
        },
        fetchPolicy: fetchPolicy
    })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => { onError(reason); });
}
const Drafts = gql`
  query draftTimeslipQuery ($feeEarnerId: String, $includeDraft: Boolean, $includePending: Boolean) {
    draftTimeslips (feeEarnerId: $feeEarnerId, includePending: $includePending, includeDraft: $includeDraft){
      timeslipSummaries {
        itemDate
        timeWorkedSeconds
        matterID
      }
    }
  }`;
    
export function retrieveTimesheetSummaryData(query: TimeStatQueryParams,
                                             refreshData: boolean,
                                             onSuccess: (data: TimesheetSummaryData) => void,
                                            // tslint:disable-next-line:no-any
                                             onError: (reason: any) => void)
    : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: TimesheetSummary,
        variables: {
            startDate: query.startDate,
            endDate: query.endDate,
            feeEarnerId: query.feeEarnerId           
        },
        fetchPolicy: fetchPolicy
    })
        // tslint:disable-next-line:no-any
        .then((results: { data: any; }) => {
            onSuccess(results.data);
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => { onError(reason); });
}
const TimesheetSummary = gql`
query timesheetSummary($startDate: DateTime, $endDate: DateTime, $feeEarnerId: String) {
    timesheetSummary(startDate: $startDate, endDate: $endDate, feeEarnerId: $feeEarnerId) {
      summaries {
        date
        hasError
        totalForDaySeconds
        totalForDayUnits
      }
    }
  }`;

export interface MatterFinancialsQueryParams {
    matterId: number;
}

export function retrieveMatterFinancials(query: MatterFinancialsQueryParams,
                                         refreshData: boolean,
                                         onSuccess: (data: MatterData) => void,
                                         // tslint:disable-next-line:no-any
                                         onError: (reason: any) => void)
    : void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';
    client.query({
        query: FinancialsInfoData,
        variables: {
            id: query.matterId      
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any) => { onError(reason); });
}